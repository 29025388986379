import Brightness7SharpIcon from "@mui/icons-material/Brightness7Sharp";
import BuildIcon from "@mui/icons-material/Build";
import TimelineIcon from "@mui/icons-material/Timeline";
import { Box, Container, Grid } from "@mui/material";
import ImgArrowList from "../../assets/images/products/arrowlist.svg";
import ImgHeroMobile from "../../assets/images/products/bitacore-hero-mobile.png"; //TODO It is pending to change the images to svg @sk.karem
import ImgHero from "../../assets/images/products/bitacore-hero.png"; //TODO It is pending to change the images to svg @sk.karem
import ImgBgUniverse from "../../assets/images/products/bitacore-pattern.svg";
import ImgPattern1 from "../../assets/images/products/rectangle.svg";
import ImgPattern2 from "../../assets/images/products/rectangle2.png";
import BitaCoreIndexCard from "../../components/BitaCoreIndexCard";
import BitaCoreListItem from "../../components/BitaCoreListItem";
import PageHero from "../../components/PageHero";
import {
  root,
  Main,
  mainDivider,
  Construction,
  ConstructionImgWrapper,
  constructionContent,
  ConstructionTitle,
  List,
  Data,
  DataContent,
  Universe,
  UniverseBg,
  universeContentWrapper,
  universeContent,
  universeText,
  BoxWithUnderline,
  Underline,
  analysisContainer,
  AnalysisImgWrapper,
  MainText,
  mainHeading,
  MainSubHeading,
  universeTextAditional,
  leftContainer,
} from "./styles";

const ProductsPage = () => {
  return (
    <Box sx={{ ...root, backgroundColor: "#1b1b1b" }}>
      <PageHero
        colorScheme="gray"
        mainContentColorScheme="dark"
        title="BITACore"
        subTitle="Create, build, backtest, and analyze production-ready indexes and rule-based investment strategies in a matter of minutes."
      />

      <Main>
        <Container>
          <Box display={{ xs: "none", md: "block" }} py={3}>
            <img src={ImgHero} alt="Hero" width="100%" />
          </Box>
          <Box sx={{ ...mainDivider }} display={{ xs: "none", md: "block" }} />
          <Box sx={{ ...mainHeading }}>Direct Indexing without Boundaries</Box>
          <Box display={{ xs: "block", md: "none" }} pt={6} pb={3}>
            <img src={ImgHeroMobile} alt="HeroMobile" width="100%" />
          </Box>
          <Box sx={{ ...MainSubHeading }}>
            The world’s most powerful index customization and analytics
            platform.
          </Box>
          <Box sx={{ ...MainText }}>
            Leverage a broad variety of datasets (fundamental, ESG, Impact,
            Thematic, Factor), customize investment universes, parametrize
            rulebooks, and seamlessly construct a large variety of indexes and
            investment strategies.
          </Box>
        </Container>
        <Construction>
          <Container>
            <Grid container spacing={4} sx={{ ...constructionContent }}>
              <Grid item xs={12} md={6}>
                <ConstructionTitle>
                  <BuildIcon />
                  <BoxWithUnderline>
                    Construction
                    <Underline />
                  </BoxWithUnderline>
                </ConstructionTitle>
                <ConstructionImgWrapper>
                  <img src={ImgPattern1} alt="Pattern" width="100%" />
                </ConstructionImgWrapper>
              </Grid>
              <Grid item xs={12} md={6}>
                <List>
                  <BitaCoreListItem
                    title="Universe Builder"
                    text="Build any type of point-in-time equity universe. Tilt, filter and create accurate exposures."
                  />
                  <BitaCoreListItem
                    title="Index Builder"
                    text="Construct standard or customized indexes by parameterizing in great detail any investment or index rulebook."
                  />
                  <BitaCoreListItem
                    title="Optimized Index Builder"
                    text="Run different types of optimized strategies given a long list of available constraints."
                  />
                  <BitaCoreListItem
                    title="Structured Index Builder"
                    text="Build “Index of Index” and multi-asset strategies from combinations of existing instruments (indexes or ETFs)."
                  />
                </List>
              </Grid>
            </Grid>
          </Container>
        </Construction>

        <Box>
          <Container sx={{ ...analysisContainer }}>
            <Grid container spacing={4} flexWrap="wrap-reverse">
              <Grid item xs={12} md={6}>
                <List>
                  <BitaCoreListItem
                    title="Analyzer"
                    text="Analyze indexes. Identify sources of performance while comparing against specified benchmarks."
                  />
                  <BitaCoreListItem
                    title="Basket Calculation"
                    text="Upload a pre-constructed investment basket (constituents and weights) and quickly perform a backtest."
                  />
                  <BitaCoreListItem
                    title="Rebalancing & Reconstitution"
                    text="Rebalance your indexes in a convenient way."
                  />
                  <BitaCoreListItem
                    title="Factsheet Builder"
                    text="Upload your information and generate your factsheet in minutes."
                  />
                </List>
              </Grid>
              <Grid item xs={12} md={6}>
                <ConstructionTitle>
                  <Box>
                    <TimelineIcon />
                  </Box>
                  <BoxWithUnderline>
                    Rebalancing & Analysis
                    <Underline />
                  </BoxWithUnderline>
                </ConstructionTitle>
                <AnalysisImgWrapper>
                  <img src={ImgPattern2} alt="Pattern2" />
                </AnalysisImgWrapper>
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Data>
          <Container sx={{ ...DataContent }}>
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={6}>
                <ConstructionTitle>
                  <Brightness7SharpIcon />
                  <BoxWithUnderline>
                    Bring your own Data:
                    <Underline />
                  </BoxWithUnderline>
                </ConstructionTitle>
                <Box mt={{ xs: 4, md: 0 }}>
                  <BitaCoreListItem
                    title="Data Upload"
                    text="Upload your own data fields, universes and benchmarks to customize your indexes."
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <img src={ImgArrowList} alt="Data Patterns" />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Data>

        <Container>
          <Box sx={{ ...mainDivider }} display={{ xs: "none", md: "block" }} />
        </Container>

        <Universe>
          <UniverseBg>
            <img src={ImgBgUniverse} alt="Universe Background" width="100%" />
          </UniverseBg>
          <Container sx={{ ...universeContentWrapper }}>
            <Box
              sx={{ ...mainDivider }}
              display={{ xs: "block", md: "none" }}
            />
            <Container sx={{ ...universeContent }}>
              <Box sx={{ ...leftContainer }}>
                <Box sx={{ ...mainHeading }}>
                  Available on the cloud or via APIs
                </Box>
                <Box sx={{ ...universeText, ...universeTextAditional }}>
                  BITACore can be licensed as a web-based application or as a
                  set of API’s offering the full range of functionality.
                </Box>
              </Box>
            </Container>
            <Box
              sx={{ ...mainDivider }}
              display={{ xs: "block", md: "none" }}
            />
          </Container>
        </Universe>

        <Box py={{ xs: 0, md: 10 }}>
          <Container>
            <Box sx={{ ...mainHeading }} pb={{ xs: 0, md: 2.5 }}>
              Modular Architecture with Flexible Plans
            </Box>
            <Box
              sx={{
                ...universeText,
                ...universeTextAditional,
                paddingBottom: 6,
              }}
            >
              BITACore can be configured to meet particular client needs
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <BitaCoreIndexCard
                  title="BITACore Trial"
                  description="Discover all the capabilities of the product in a specialized license of 14-days for you and your team."
                  bgColor="#39B0F3"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <BitaCoreIndexCard
                  title="BITACore"
                  description="Discover the standard capabilities of the platform with access to the:"
                  items={[
                    "Universe Builder",
                    "Index Builder",
                    "Optimized Index Builder",
                    "Structured Index Builder",
                    "Analyzer",
                  ]}
                  bgColor="#4891FF"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <BitaCoreIndexCard
                  title="BITACore Premium"
                  description="Get access to all the modules and empower your experience"
                  items={[
                    "Universe Builder",
                    "Index Builder",
                    "Optimized Index Builder",
                    "Structured Index Builder",
                    "Analyzer",
                    "Basket Calculation",
                    "More",
                  ]}
                  bgColor="#39B0F3"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <BitaCoreIndexCard
                  title="Custom License"
                  description="Customize your experience and decide what features you want to enable in the UI."
                  bgColor="#78AEFF"
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Main>
    </Box>
  );
};

export default ProductsPage;
