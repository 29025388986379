import { FC, useEffect } from "react";
import { Box, Container } from "@mui/material";
import ClientsCaseStudies from "../../components/ClientsCaseStudies";
import ClientsList from "../../components/ClientsList";
import PageHero from "../../components/PageHero";
import { useAppDispatch, useAppSelector } from "../../hooks/useStore";
import { fetchNewsList, selectNewsList } from "../../redux/slices/news";
import theme from "../../theme";
import {
  Root,
  main,
  mainHeading,
  MainText,
  Text,
  SectionBorder,
} from "./styles";

const OurClients: FC<{}> = () => {
  const dispatch = useAppDispatch();
  const newsList = useAppSelector(selectNewsList)?.filter(
    (item) => item.categorization?.name === "Case Study"
  );

  useEffect(() => {
    dispatch(fetchNewsList());
  }, [dispatch]);

  return (
    <Root>
      <PageHero
        colorScheme="gray"
        title="Our Clients"
        subTitle="Catering to Your Unique Needs with Innovative Indexing and Data Solutions"
      />
      <Container sx={{ ...main }}>
        <Box
          sx={{
            ...mainHeading,
            fontSize: 40,
            color: theme.palette.primary.main,
          }}
        >
          Tailored Solutions for Diverse Financial Clients
        </Box>
        <MainText>
          With a blend of traditional indexing services, advanced technology
          products, and diverse thematic data, we are well-positioned to serve a
          broad spectrum of clientele. Our customer-centric solutions are
          designed to empower our clients to drive their strategic decisions,
          increase operational efficiency, and enhance their market positions.
        </MainText>
        <SectionBorder />
        <ClientsList />
        {!!newsList?.length && (
          <Box>
            <Box sx={{ ...mainHeading }} pb={2}>
              Case Studies
            </Box>
            <Text>
              View our collection of case studies to see how BITA has been
              instrumental in driving growth, innovation, and success for our
              diverse clientele. Here, we showcase real-world applications of
              our services - from traditional indexing and our advanced BITACore
              technology to our extensive thematic data.
            </Text>
            <ClientsCaseStudies data={newsList} />
          </Box>
        )}
      </Container>
    </Root>
  );
};

export default OurClients;
