import { styled } from "@mui/system";
import { pageStyles } from "../../theme";
import theme from "../../theme";

// Define individual styled components
const HeaderMask = styled("div")({
  position: "absolute",
  width: "100%",
  height: 26,
  left: 0,
  bottom: 12,
});

const HeaderMask2 = styled("div")({
  position: "absolute",
  width: "100%",
  height: 12,
  left: 0,
  bottom: 0,
});

const responsiveHeadingComponent = {
  [theme.breakpoints.down("lg")]: {
    fontSize: "5vw",
  },
};

const baseStyles = {
  ...pageStyles(theme)
};

const headerStyles = {
  ...pageStyles(theme).header
}

const headerContent =  {...pageStyles(theme).headerContent}

const heading =  {...pageStyles(theme).heading}

const subHeading =  {...pageStyles(theme).subHeading}




export { HeaderMask, HeaderMask2, responsiveHeadingComponent, baseStyles, headerStyles, headerContent, heading, subHeading };