import { styled } from "@mui/system";
import theme from "../../theme";

const Root = styled("div")(({
  padding: "1rem",
  minHeight: 200,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPositionX: "center",
  backgroundPositionY: "center",
  "&.lg": {
    backgroundColor: "#485C6F",
    backgroundImage: "none !important",
    paddingLeft: "3rem",
    paddingRight: "2.5rem",
    width: "50%",
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  "&.md": {},
}));

const Label = styled("div")(({
  fontSize: 23,
  fontWeight: 700,
  color: "#fff",
}));

const Description = styled("div")(({
  fontSize: 14,
  padding: "1rem 0 1.5rem",
}));

const Footer = styled("div")(({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

export { Root, Label, Description, Footer };