import { styled } from "@mui/system";
import { pageStyles } from "../../../theme";
import theme from "../../../theme";

const rootTheme = {...pageStyles(theme).root}

const Root = styled("div")(({
  backgroundColor: "#1b1b1b",

  "& .slick-prev": {
    color: "#BB29FF",

    "& svg": {
      fill: "#832785",
      stroke: "#832785",
    },

    "&:hover": {
      color: "#D1A3FF",
      "& svg": {
        fill: "#fff",
        stroke: "#fff",
      },
    },
  },

  "& .slick-next": {
    color: "#BB29FF",

    "& svg": {
      fill: "#832785",
      stroke: "#832785",
    },

    "&:hover": {
      color: "#D1A3FF",
      "& svg": {
        fill: "#fff",
        stroke: "#fff",
      },
    },
  },
}));

const Initial = styled("div")({});

const HeaderBg = styled("div")(({
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  height: "30vh",
  background: "linear-gradient(180deg, rgba(126, 42, 151, 0) 0%, #1B1B1B 88.02%)",
}));

const Hero = styled("div")(({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  paddingTop: "5.5rem",
  paddingBottom: "1rem",
}));

const Divider = styled("div")(({
  width: "15rem",
  borderTop: "1px solid #fff",
  margin: "2rem 0",
}));

const Text = styled("p")(({
  maxWidth: theme.spacing(90),
  fontSize: 20,
  lineHeight: 1.2,
}));

const Main = styled("div")(({
  padding: "2rem 0",
}));

const MainTopSection = styled("div")(({
  padding: "6rem 0 0",
}));

const HeroText = {
  fontSize: 25,
  lineHeight: 1.2,
};

const TopSectionHeading = styled("h2")(({
  fontSize: 35,
  fontWeight: 600,
}));

const TopSectionSubHeading = styled("h3")(({
  fontSize: 23,
  fontWeight: 400,
}));

const sectionContainer ={
  padding: "0 0 1rem",
};

const HeroSection = styled("div")(({
  width: "100%",
  margin: "6rem 0",
  color: "#fff",
  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(6),
  },
}));

const HeroInnerContainer = styled("div")(({
  paddingTop: "9.5rem",
  paddingBottom: "9.5rem",
  paddingLeft: "9.5rem",
  marginLeft: "-9.5rem",
  marginRight: "-9.5rem",
  width: "calc(100% + 20vw)",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 0,
    marginRight: 0,
    borderTop: "1px solid #fff",
    borderBottom: "1px solid #fff",
  },
}));

const heroMainHeading = {
  maxWidth: "30rem",
};

const heroMainText = {
  maxWidth: "25rem",
};

const mainHeading = {
  ...pageStyles(theme).mainHeading,
};
//color: theme.palette.primary.main,

const mainText ={
  ...pageStyles(theme).mainText
};

const HeroColorSection = {
    color: '#fff',
    height: '34rem',
    position: 'relative',
    paddingTop: '16rem',
    backgroundSize: 'cover',
    backgroundColor: '#1b1b1b',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
}

export {
  Root,
  rootTheme,
  Initial,
  HeaderBg,
  Hero,
  Divider,
  Text,
  Main,
  MainTopSection,
  HeroText,
  TopSectionHeading,
  TopSectionSubHeading,
  sectionContainer,
  HeroSection,
  HeroInnerContainer,
  heroMainHeading,
  heroMainText,
  mainHeading,
  mainText,
  HeroColorSection
};