import { styled } from "@mui/system";

const Main = styled("div")(({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "120px",
}));

export { Main };