import { styled } from "@mui/system";
import { pageStyles } from "../../theme";
import theme from "../../theme";

const root = {
  ...pageStyles(theme).root,
};

const HeroContainer = styled("div")(({
  position: "relative",
}));

const HeroImg = styled("div")(({
  position: "absolute",
  top: 0,
  left: 0,
  marginTop: theme.spacing(-5),
  marginLeft: theme.spacing(-20),
  [theme.breakpoints.down("lg")]: {
    position: "relative",
    marginLeft: 0,
    marginTop: theme.spacing(2),
    "& img": {
      margin: "auto",
    },
  },
}));

const HeroTitle = styled("h1")(({
  fontWeight: 700,
  fontSize: theme.spacing(5),
  textAlign: "left",
  color: theme.palette.primary.main,
  paddingBottom: theme.spacing(2.5),
  [theme.breakpoints.down("lg")]: {
    textAlign: "left",
  },
}));

const HeroText = styled("p")(({
  textAlign: "right",
  [theme.breakpoints.down("lg")]: {
    textAlign: "left",
  },
}));

const Section = styled("div")(({
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
  color: theme.palette.primary.main,
  [theme.breakpoints.down("md")]: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(0),
  },
}));

const SectionTitle = styled("h2")(({
  fontSize: theme.spacing(5),
  fontWeight: 700,
  paddingBottom: theme.spacing(2.5),
}));

const SectionSubTitle = styled("h3")(({
  fontSize: 30,
  fontWeight: 700,
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  color: theme.palette.primary.main,
}));

const sectionText = {
  fontSize: 20,
  color: "#1B1B1B",
};

const SectionDivider = styled("div")(({
  height: 2,
  width: "100%",
  backgroundColor: theme.palette.primary.main,
}));

const Hero = styled("div")(({
  display: "flex",
  flexDirection: "row-reverse",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

const HeroMain = styled("div")(({
  flex: 1,
  margin: "3rem 0",
  [theme.breakpoints.down("sm")]: {
    paddingBottom: theme.spacing(4),
  },
}));

const HeroBg = styled("div")(({
  position: "relative",
  flex: 1,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  [theme.breakpoints.down("lg")]: {
    minHeight: "20rem",
    marginLeft: theme.spacing(-5),
    marginRight: theme.spacing(-5),
  },
}));

const HeroBgGradient = styled("div")(({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 1,
  background: "linear-gradient(270deg, #ffffff00 72.92%, #fff 100%)",
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));

const Intro = styled("div")(({
  display: "flex",
  [theme.breakpoints.down("lg")]: {
    paddingBottom: theme.spacing(4),
  },
}));

const IntroMain = styled("div")(({
  margin: `${theme.spacing(6)} 0` ,
  flex: 1,
}));

const IntroBg = styled("div")(({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: "50%",
  right: 0,
  flex: 1,
  marginTop: theme.spacing(6),
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "left top",
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));

const StatsSection = styled("div")(({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  color: "#fff",
  backgroundColor: theme.palette.primary.main,
}));

const StatsItem = styled("div")(({
  padding: theme.spacing(2),
  textAlign: "center",
}));

const StatsLabel = styled("div")(({
  paddingTop: theme.spacing(2),
  fontSize: 20,
  fontWeight: 700,
}));

const StatsValue = styled("div")(({
  fontSize: 40,
  fontWeight: 700,
}));

const Footer = styled("div")(({
  position: "relative",
  marginBottom: theme.spacing(8),
  [theme.breakpoints.down("md")]: {
    paddingTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
}));

const FooterImg = styled("div")(({
  marginLeft: theme.spacing(-27),
  marginRight: theme.spacing(-27),
  "& img": {
    width: "100%",
  },
}));

const FooterLabel = styled("div")(({
  position: "absolute",
  top: theme.spacing(18),
  left: "63%",
  fontSize: theme.spacing(5),
  color: "#fff",
  [theme.breakpoints.down("md")]: {
    left: "50%",
    transform: "translateX(-50%)",
  },
}));

const FooterLabelBold = styled("span")(({
  fontSize: 50,
  fontWeight: 700,
}));

const TeamContainer = styled("div")(({
  paddingTop: theme.spacing(6),
  [theme.breakpoints.down("md")]: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
}));

export {
  root,
  HeroContainer,
  HeroImg,
  HeroTitle,
  HeroText,
  Section,
  SectionTitle,
  SectionSubTitle,
  sectionText,
  SectionDivider,
  Hero,
  HeroMain,
  HeroBg,
  HeroBgGradient,
  Intro,
  IntroMain,
  IntroBg,
  StatsSection,
  StatsItem,
  StatsLabel,
  StatsValue,
  Footer,
  FooterImg,
  FooterLabel,
  FooterLabelBold,
  TeamContainer,
};