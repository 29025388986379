import { FC, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DownloadIcon from "@mui/icons-material/Download";
import { Box, Menu, MenuItem } from "@mui/material";
import { useIsMobileView } from "../../hooks/useWindowSize";
import { DownloadLink } from "../../types/ui";
import { Button, ButtonLabel, menuItemComponent } from "./styles";

interface Props {
  downloadLinks: DownloadLink[];
}

const IndexLabDownloadLink: FC<Props> = ({ downloadLinks }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const inMobile = useIsMobileView();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = (url: string | null) => {
    handleClose();
    if (!url) return;
    window.open(url, "_blank");
  };

  const hasDownloadLinks = !!downloadLinks.length;

  if (!hasDownloadLinks) return null;

  return (
    <>
      {inMobile ? (
        <Box textAlign="right" onClick={handleClick}>
          <DownloadIcon htmlColor="#235CFF" />
        </Box>
      ) : (
        <Button onClick={handleClick}>
          <ButtonLabel>Learn More</ButtonLabel>
          <ArrowDropDownIcon fontSize="small" />
        </Button>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {downloadLinks.map(({ label, url }) => (
          <MenuItem
            key={label}
            sx={{ ...menuItemComponent }}
            onClick={() => handleDownload(url)}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default IndexLabDownloadLink;
