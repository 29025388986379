import { ChangeEvent, useEffect, useMemo, useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import FilePresentOutlinedIcon from "@mui/icons-material/FilePresentOutlined";
import { Box, Link, SelectChangeEvent, Container } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import PageHero from "../../components/PageHero";
import SearchInput from "../../components/SearchInput";
import Select from "../../components/Select";
import Table from "../../components/Table";
import { useQuery } from "../../hooks/useQuery";
import { useAppDispatch, useAppSelector } from "../../hooks/useStore";
import { useIsMobileView } from "../../hooks/useWindowSize";
import {
  fetchAnnouncementCategories,
  fetchAnnouncements,
  selectAnnoucementCategories,
  selectAnnoucements,
} from "../../redux/slices/announcements";
import theme from "../../theme";
import { Column } from "../../types/ui";
import { formatStringInQueryString } from "../../utils/string";
import {
  root,
  main,
  mainHeading,
  TableContainer,
  SelectorsRow,
  SelectorsContainer,
  SelectContainer,
  SearchContainer,
  ResetBtnContainer,
  AnnouncementsContainer,
  resetButton,
} from "./styles";

const TypeCellBgColor = ["#0031D9", "#25246D", "#1684FF", "#535BB8", "#88B5FF"];

export const TableColumns: Column[] = [
  {
    key: "date",
    label: "DATE",
    sortable: false,
    colSpan: 1,
    width: "15%",
  },
  {
    key: "headline",
    label: "HEADLINE",
    colSpan: 2,
    width: "35%",
  },
  {
    key: "type",
    label: "ANNOUNCEMENT TYPE",
    colSpan: 1,
    width: "18%",
    renderCell: (data) => (
      <Box
        sx={{
          bgcolor:
            TypeCellBgColor[data.id_m_category_announcement - 1] ||
            "primary.main",
          borderRadius: "5px",
          textAlign: "center",
          color: "#fff",
          fontWeight: 600,
          fontSize: 9,
          width: 150,
          height: 20,
          textTransform: "uppercase",
          paddingTop: "5px",
        }}
      >
        {data.type}
      </Box>
    ),
  },
  {
    key: "file",
    label: "DOWNLOAD",
    colSpan: 1,
    width: "27%",
    renderCell: (data) => (
      <Link
        href={data.url}
        target="_blank"
        sx={{
          display: "flex",
          alignItems: "center",
          wordBreak: "break-all",
          color: "#1684FF",
          cursor: "pointer",
          textDecoration: "none",
        }}
      >
        <Box display={{ xs: "none", md: "flex" }}>
          <FilePresentOutlinedIcon fontSize="small" htmlColor="#1684FF" />
          <Box sx={{ paddingLeft: 1, textDecoration: "none", fontWeight: 600 }}>
            {data.file}
          </Box>
        </Box>
        <Box
          display={{ xs: "block", md: "none" }}
          width="100%"
          textAlign="center"
        >
          <DownloadIcon htmlColor="#1684FF" />
        </Box>
      </Link>
    ),
  },
];

const MOBILE_COLUMNS = [{ ...TableColumns[1], width: "70%" }, TableColumns[3]];

const AnnouncementsPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const queryParams = useQuery();
  const queryType = queryParams.get("type");
  const queryYear = queryParams.get("year");

  const announcements = useAppSelector(selectAnnoucements);
  const categories = useAppSelector(selectAnnoucementCategories);

  const [announcementType, setAnnouncementType] = useState<number>(-1);
  const [year, setYear] = useState<string>("");
  const [query, setQuery] = useState<string>("");

  const inMobile = useIsMobileView();

  const columns = useMemo(
    () => (inMobile ? MOBILE_COLUMNS : TableColumns),
    [inMobile]
  );
  const visibleKeys = columns.map((c) => c.key);

  const tableData = useMemo(() => {
    return [...announcements]
      .sort((a, b) =>
        new Date(a.date).getTime() < new Date(b.date).getTime() ? 1 : -1
      )
      .map((a) => ({
        ...a,
        date: moment(a.date).utc().format("MMMM DD, yyyy"),
        originalDate: a.date,
        type: categories?.find(
          (c) => c.id_m_category_announcement === a.id_m_category_announcement
        )?.name,
      }))
      .filter((a) => {
        if (a.file_status !== "A") {
          return false;
        }

        if (
          announcementType > -1 &&
          a.id_m_category_announcement !== announcementType
        ) {
          return false;
        }

        if (year && moment(a.date).format("yyyy") !== year) {
          return false;
        }

        return (
          query.length < 3 ||
          visibleKeys.some(
            (k) =>
              (a as any)[k] &&
              ((a as any)[k] as any)
                .toString()
                .toLowerCase()
                .includes(query.toLowerCase())
          )
        );
      });
  }, [announcementType, announcements, categories, query, visibleKeys, year]);

  const categoryOptions = useMemo(
    () => [
      { label: "Announcement Type", value: -1 },
      ...(categories ?? []).map((c) => ({
        label: c.name,
        value: c.id_m_category_announcement,
      })),
    ],
    [categories]
  );

  const yearOptions = useMemo(() => {
    const years = Array.from(
      new Set(announcements.map((a) => moment(a.date).format("yyyy")))
    );
    return [
      { label: "Year", value: "" },
      ...years.map((y) => ({
        label: y,
        value: y,
      })),
    ];
  }, [announcements]);

  const handleChangeAnnouncementType = (event: SelectChangeEvent<any>) => {
    setAnnouncementType(event.target.value);
  };

  const handleChangeYear = (event: SelectChangeEvent<any>) => {
    setYear(event.target.value);
  };

  const handleChangeQuery = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const onReset = () => {
    setAnnouncementType(-1);
    setYear("");
  };

  useEffect(() => {
    dispatch(fetchAnnouncements());
    dispatch(fetchAnnouncementCategories());
  }, [dispatch]);

  useEffect(() => {
    if (queryYear) {
      setYear(queryYear);
    }
  }, [queryYear]);

  useEffect(() => {
    if (queryType) {
      const foundCategory = categories.find(
        (c) => formatStringInQueryString(c.name) === queryType
      );
      if (foundCategory) {
        setAnnouncementType(foundCategory.id_m_category_announcement);
      }
    }
  }, [categories, queryType]);

  useEffect(() => {
    const params: { [key: string]: string } = {};

    if (year) {
      params.year = year;
    }

    if (announcementType > -1) {
      const foundCategory = categories.find(
        (c) => c.id_m_category_announcement === announcementType
      );

      if (foundCategory) {
        params.type = formatStringInQueryString(foundCategory.name);
      }
    }

    navigate(`/announcements?${new URLSearchParams(params).toString()}`);
  }, [announcementType, categories, navigate, year]);

  return (
    <Box sx={{ ...root }}>
      <PageHero colorScheme="dark_blue" title="Index Announcements" />
      <Container sx={{ ...main }}>
        <AnnouncementsContainer>
          <TableContainer>
            <SelectorsRow>
              <SelectorsContainer>
                <SelectContainer>
                  <Select
                    value={announcementType?.toString() ?? ""}
                    onChange={handleChangeAnnouncementType}
                    options={categoryOptions}
                    width={inMobile ? "100%" : 280}
                    displayEmpty
                    fullWidth={inMobile}
                  />
                </SelectContainer>
                <SelectContainer>
                  <Select
                    value={year}
                    onChange={handleChangeYear}
                    options={yearOptions}
                    width={inMobile ? "100%" : 144}
                    displayEmpty
                    fullWidth={inMobile}
                  />
                </SelectContainer>
                <ResetBtnContainer>
                  <Button
                    label="Reset"
                    onClick={onReset}
                    sx={{ ...resetButton }}
                    color="primary"
                    fullWidth={inMobile}
                  />
                </ResetBtnContainer>
              </SelectorsContainer>

              <SearchContainer>
                <SearchInput
                  label=""
                  placeholder="Search by..."
                  onChange={handleChangeQuery}
                  fullWidth={inMobile}
                />
              </SearchContainer>
            </SelectorsRow>
            <Table columns={columns} data={tableData} />
          </TableContainer>
        </AnnouncementsContainer>
      </Container>
    </Box>
  );
};

export default AnnouncementsPage;
