import { FC, useState } from "react";
import {
  Root,
  PhotoWrapper,
  PhotoMask,
  PhotoImg,
  Main,
  Name,
  Title,
  Description,
} from "./styles";

interface Props {
  id: number;
  name: string;
  position: string;
  shortPosition: string;
  image: string;
  description: string;
  inMobile: boolean;
}

const TeamMember: FC<Props> = ({
  name,
  position,
  shortPosition,
  image,
  description,
  inMobile,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Root>
      <PhotoWrapper
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <PhotoMask />
        <PhotoImg>
          <img src={image} alt="Avatar" />
        </PhotoImg>
        {isHovered && !inMobile && <Description>{description}</Description>}
      </PhotoWrapper>

      <Main>
        <Name>{name}</Name>
        <Title>{inMobile ? shortPosition : position}</Title>
        {inMobile && <Description>{description}</Description>}
      </Main>
    </Root>
  );
};

export default TeamMember;
