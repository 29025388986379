import { FC } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Link } from "@mui/material";
import {
  Section,
  SectionTitle,
  SectionTitleBottomLine,
  SectionText,
  LinkWrapper,
  linkComponent,
  LinkIcon,
  ComingSoon,
} from "./styles";

interface Props {
  title: string;
  text: string;
  link?: string;
}

const PortfolioApiCard: FC<Props> = ({ title, text, link }) => {
  return (
    <Section>
      <SectionTitle>{title}</SectionTitle>
      <SectionTitleBottomLine></SectionTitleBottomLine>
      <SectionText>{text}</SectionText>
      <LinkWrapper>
        {link ? (
          <Link href={link} sx={{ ...linkComponent }} target="_blank">
            Documentation
            <LinkIcon>
              <KeyboardArrowRightIcon htmlColor="#fff" />
            </LinkIcon>
          </Link>
        ) : (
          <ComingSoon>Coming Soon</ComingSoon>
        )}
      </LinkWrapper>
    </Section>
  );
};

export default PortfolioApiCard;
