import { FC, memo, useEffect, useMemo, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Box, CircularProgress, Grid } from "@mui/material";
import ImgBgPlaceholder from "../../assets/images/products/crypto-offering/Crypto-Bg-Placeholder.svg";
import { MultiCalc } from "../../types/analyzer";
import { formatPercentage, isNumeric } from "../../utils/numeric";
import Chart from "../Chart";
import {
  Main,
  MainTitle,
  MainTitleBorder,
  MainDescription,
  ChartComponent,
  ChartMain,
  ChartTitle,
  ChartContainer,
  ChartBottomSection,
  RealTimeContainer,
  RealTimeLabel,
  RealTimeValue,
  SmallBoxes,
  SmallBox,
  SmallBoxLabel,
} from "./styles";

interface Props {
  id: number;
  title: string;
  description: string;
  bgImg: string;
  chartData: number[][];
  multiCalcData: MultiCalc[];
  realTimeValue?: number;
  isLoading: boolean;
}

const EMPTY_RTV = "-";

const CryptoOfferingCoin: FC<Props> = ({
  id,
  title,
  description,
  bgImg,
  chartData,
  multiCalcData,
  realTimeValue,
  isLoading,
}) => {
  const [rtv, setRtv] = useState<string>(EMPTY_RTV);

  const chartValues = useMemo(
    () => chartData.map((item) => item[1]),
    [chartData]
  );
  const specificReturnValue =
    formatPercentage(multiCalcData[0]?.["1Y"]) ?? EMPTY_RTV;
  const specificVolatilityValue =
    formatPercentage(multiCalcData?.[3]?.["1Y"]) ?? EMPTY_RTV;
  const isNumericRealtimeValue = isNumeric(realTimeValue);

  useEffect(() => {
    if (realTimeValue !== undefined) {
      setRtv(
        isNumericRealtimeValue
          ? (realTimeValue as number).toFixed(8)
          : EMPTY_RTV
      );
    }
  }, [isNumericRealtimeValue, realTimeValue]);

  useEffect(() => {
    setRtv(EMPTY_RTV);
  }, [id]);

  return (
    <Box pt={4}>
      <Grid container spacing={8}>
        <Grid item xs={12} md={6}>
          <Main>
            <MainTitle>{title}</MainTitle>
            <MainTitleBorder />
            <MainDescription>{description}</MainDescription>
          </Main>
        </Grid>
        <Grid item xs={12} md={6} display={{ xs: "none", md: "block" }}>
          <ChartComponent
            sx={{
              backgroundImage: `url(${bgImg || ImgBgPlaceholder})`,
            }}
          >
            <ChartMain>
              <ChartTitle>BITA {title} Price Index</ChartTitle>
              <ChartContainer>
                <Chart
                  dataSeries={[
                    {
                      type: "line",
                      name: `BITA ${title} Price Index`,
                      data: chartData,
                      color: "#fff",
                      lineColor: "#fff",
                    },
                  ]}
                  chartOptions={{
                    chart: { backgroundColor: "transparent", height: 140 },
                    xAxis: { visible: false },
                    yAxis: {
                      visible: false,
                      min: Math.min(...chartValues),
                      max: Math.max(...chartValues),
                    },
                  }}
                  size="sm"
                  loading={isLoading}
                />
              </ChartContainer>
              <ChartBottomSection>
                <RealTimeContainer>
                  <RealTimeLabel>Real-time</RealTimeLabel>
                  <RealTimeValue>
                    {rtv}
                    {rtv !== EMPTY_RTV &&
                      (parseFloat(rtv) > 0 ? (
                        <ArrowDropUpIcon htmlColor="#05FF00" />
                      ) : (
                        <ArrowDropDownIcon htmlColor="#EA4D4D" />
                      ))}
                  </RealTimeValue>
                </RealTimeContainer>
                <SmallBoxes>
                  <SmallBox>
                    <SmallBoxLabel>1-Yr Return</SmallBoxLabel>
                    <Box>
                      {isLoading ? (
                        <CircularProgress size={10} color="inherit" />
                      ) : (
                        specificReturnValue
                      )}
                    </Box>
                  </SmallBox>
                  <SmallBox>
                    <SmallBoxLabel>1-Yr Volatility</SmallBoxLabel>
                    <Box>
                      {isLoading ? (
                        <CircularProgress size={10} color="inherit" />
                      ) : (
                        specificVolatilityValue
                      )}
                    </Box>
                  </SmallBox>
                </SmallBoxes>
              </ChartBottomSection>
            </ChartMain>
          </ChartComponent>
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(CryptoOfferingCoin);
