import { styled } from "@mui/system";
import theme from "../../theme";

const section ={
  padding: "2.5rem 0",
  alignItems: "center",
  "&.reverse": {
    flexDirection: "row-reverse",
  },
};

const sectionContent = {
  [theme.breakpoints.down("md")]: {
    paddingTop: "2rem",
  },
  "&.standard": {
    paddingRight: "3rem",
    [theme.breakpoints.down("md")]: {
      paddingRight: 0,
    },
  },
};

const SectionSubTitle = styled("h2")({
  color: "#698BEC",
  fontSize: 15,
  fontWeight: 700,
});

const SectionSubTitleBottom = styled("div")({
  width: "4rem",
  height: 1,
  background: "#698BEC",
  margin: "0.25rem 0 0.5rem",
});

const SectionTitle = styled("h1")({
  fontSize: 30,
  fontWeight: 600,
  paddingBottom: "0.5rem",
});

const SectionText = styled("p")({
  padding: "0.5rem 0",
});

const sectionImage = {
  "& img": {
    margin: "0 auto",
  },
};

const Divider = styled("div")(({
  display: "none",
  width: "100%",
  height: 2,
  marginBottom: "3.75rem",
  background: "linear-gradient(360deg, #4995FF 0%, #235CFF 100%)",
  [theme.breakpoints.down("md")]: {
    display: "block",
  },
}));

export {
  section,
  sectionContent,
  SectionSubTitle,
  SectionSubTitleBottom,
  SectionTitle,
  SectionText,
  sectionImage,
  Divider,
};