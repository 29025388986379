import { Container, Box } from "@mui/material";
import PageHero from "../../components/PageHero";
import theme from "../../theme";
import {
  Root,
  Main,
  mainHeading,
  Text,
  Section,
  SectionHeading,
} from "../Legal/styles";

const Disclaimer = () => {
  return (
    <Root>
      <PageHero colorScheme="light_blue" title="DISCLAIMER" />
      <Container sx={{ ...Main }}>
        <Box
          sx={{
            ...mainHeading,
            fontSize: 50,
            color: theme.palette.primary.main,
          }}
        >
          DISCLAIMER
        </Box>

        <Section>
          <SectionHeading>
            This Website Is Informational, Impersonal and Not a Solicitation
          </SectionHeading>
          <Text>
            No information on this website constitutes a promotion,
            recommendation, inducement, offer, or solicitation to (i) purchase
            or sell any securities or assets, (ii) transact any other business,
            or (iii) enter into any other legal transactions. All information
            provided by BITA on this website is informational, impersonal and
            not customized to the specific needs of any entity, person, or group
            of persons. BITA does not endorse, manage, promote, sell, or sponsor
            any investment fund or other vehicle that is offered by third
            parties and that seeks to provide an investment return linked to or
            based on the returns of any BITA index.
          </Text>
        </Section>

        <Section>
          <SectionHeading>No Advisory Relationship</SectionHeading>
          <Text>
            BITA is not an investment advisor, and BITA and its affiliates make
            no representation regarding the advisability of investing in any
            investment fund or other vehicle. This website should not be
            construed to provide advice of any kind, including, but not limited
            to, tax and legal.
          </Text>
        </Section>

        <Section>
          <Text>
            It is not possible to invest directly in an index. Index performance
            does not reflect the deduction of any fees or expenses. Past
            performance is not a guarantee of future returns. You should not
            make a decision to invest in any investment fund or other vehicle
            based on the statements set forth in this website, and are advised
            to make an investment in any investment fund or other vehicle only
            after carefully evaluating the risks associated with investment in
            the investment fund or other vehicle, as detailed in the offering
            memorandum or similar document prepared by or on behalf of the
            issuer. This website does not contain and does not purport to
            contain, the level of detail necessary to give sufficient basis to
            an investment decision. The addition, removal, or inclusion of a
            security in any BITA index is not a recommendation to buy, sell, or
            hold that security, nor is it investment advice.
          </Text>
        </Section>

        <Section>
          <SectionHeading>No Warranties</SectionHeading>
          <Text>
            The accuracy and/or completeness of any BITA index, any data
            included therein, or any data from which it is based is not
            guaranteed by BITA, and it shall have no liability for any errors,
            omissions, or interruptions therein. BITA makes no warranties,
            express or implied, as to results to be obtained from the use of
            information provided by BITA and used in this service, and BITA
            expressly disclaims all warranties of suitability with respect
            thereto.
          </Text>
        </Section>

        <Section>
          <SectionHeading>Limitation of Liability</SectionHeading>
          <Text>
            BITA shall not be liable for any claims or losses of any nature in
            connection with the use or misuse of the information in this
            website, including but not limited to, lost profits or punitive or
            consequential damages, even if BITA has been advised of the
            possibility of same.
          </Text>
        </Section>

        <Section>
          <SectionHeading>Linked Products</SectionHeading>
          <Text>
            BITA is compensated in retribution for licensing its indexes to
            third parties for the creation of investment funds or other
            vehicles. BITA is not responsible for the information provided by
            these parties or for anything that they provide.
          </Text>
        </Section>

        <Section>
          <SectionHeading>Contact Us</SectionHeading>
          <Text>
            If you have any comments, questions, please email us at{" "}
            <a href="mailto:info@bitadata.com">info@bitadata.com</a>. We will
            respond expeditiously.
          </Text>
        </Section>
      </Container>
    </Root>
  );
};

export default Disclaimer;
