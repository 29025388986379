import { FC } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import FilePresentOutlinedIcon from "@mui/icons-material/FilePresentOutlined";
import { Link } from "@mui/material";
import { LabelContainer, labelComponent, LinkContainer } from "./styles";

interface Props {
  label: string;
  href: string;
}

const PdfDownloadCell: FC<Props> = ({ label, href }) => {
  return (
    <>
      <LabelContainer>
        <FilePresentOutlinedIcon fontSize="small" color="primary" />
        <Link sx={{ ...labelComponent }} target="_blank" href={href}>
          {label}
        </Link>
      </LabelContainer>
      <LinkContainer>
        <Link target="_blank" href={href}>
          <DownloadIcon htmlColor="#1684FF" />
        </Link>
      </LinkContainer>
    </>
  );
};

export default PdfDownloadCell;
