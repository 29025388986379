import { styled } from "@mui/system";
import { pageStyles } from "../../theme";
import theme from "../../theme";

const Root = styled("div")(({
  ...pageStyles(theme),
  background: "#1B1B1B",
  color: "#fff",
}));

const mainHeading = {
  ...pageStyles(theme).mainHeading
};

const mainText = {
  ...pageStyles(theme).mainText
};

const Divider = styled("div")(({
  width: "15rem",
  borderTop: "1px solid #fff",
  margin: "2rem 0",
}));

const Text = styled("p")(({
  maxWidth: theme.spacing(90),
  fontSize: 20,
  lineHeight: 1.2,
}));

const Main = styled("div")(({
  padding: "6rem 0",
}));

export { Root, Divider, Text, Main, mainHeading, mainText };