import { FC, ReactNode, useMemo } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as SelectDefault,
  SelectChangeEvent,
} from "@mui/material";
import { SelectOption } from "../../types/ui";
import { Container, formControlComponent } from "./styles";

interface Props {
  label?: string;
  value?: string | number;
  options: SelectOption[];
  width?: number | string;
  disableUnderline?: boolean;
  displayEmpty?: boolean;
  displayAll?: boolean;
  onChange: (e: SelectChangeEvent<unknown>, child: ReactNode) => void;
  disabled?: boolean;
  variant?: "standard" | "outlined" | "filled" | "default";
  fullWidth?: boolean;
}

const ALL_VALUE = "--all--";

const Select: FC<Props> = ({
  label,
  value,
  options,
  width = 120,
  variant = "default",
  disableUnderline = false,
  onChange,
  displayEmpty,
  displayAll,
  ...restProps
}) => {
  const currentValue = useMemo(
    () => (displayAll && value === "" ? ALL_VALUE : value),
    [displayAll, value]
  );

  const handleChange = (e: SelectChangeEvent<any>, child: ReactNode) => {
    if (displayAll && e.target.value === ALL_VALUE) {
      e.target.value = "";
    }
    onChange?.(e, child);
  };

  return (
    <Container
      sx={{
        width,
        backgroundColor: variant === "filled" ? "#fff" : "transparent",
      }}
    >
      <FormControl variant="standard" sx={{ ...formControlComponent }}>
        <InputLabel id={label}>{label}</InputLabel>
        <SelectDefault
          labelId={label}
          value={currentValue}
          onChange={handleChange}
          label={label}
          disableUnderline={disableUnderline}
          displayEmpty
          MenuProps={{
            sx: {
              "& .MuiMenu-list": {
                backgroundColor: "#fff",
              },
            },
          }}
          {...restProps}
        >
          {displayAll && (
            <MenuItem key="all" value={ALL_VALUE}>
              All
            </MenuItem>
          )}
          {options.map(({ label, value }, idx) => (
            <MenuItem
              key={label ?? idx}
              value={value}
              disabled={!value || value < 0}
            >
              {label}
            </MenuItem>
          ))}
        </SelectDefault>
      </FormControl>
    </Container>
  );
};

export default Select;
