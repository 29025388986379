import { styled } from "@mui/system";
import { pageStyles } from "../../theme";
import theme from "../../theme";
const root = {
  ...pageStyles(theme).root,
};

const Hero = styled("div")( ({
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
}));

const heroSubTitle = {
  ...pageStyles(theme).heroSubTitle
};

const heroSubDescription = {
  ...pageStyles(theme).heroSubDescription
};

const heroTitle = {
  ...pageStyles(theme).heroTitle
};

const heroDescription = {
  ...pageStyles(theme).heroDescription
};


const HeroMain = styled("div")( ({
  zIndex: 1,
  position: "relative",
  flex: 1,
  display: "flex",
  alignItems: "center",
  paddingLeft: "4rem",
  paddingTop: "12.5rem",
  paddingBottom: "12.5rem",
  [theme.breakpoints.down("md")]: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const VideoWrapper = styled("div")( ({
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  right: 0,
  overflow: "hidden",
  "& video": {
    maxWidth: "none",
    minWidth: "100%",
    minHeight: "100%",
    width: "auto",
    height: "auto",
  },
}));

const VideoBackgroundTop = styled("div")( ({
  position: "absolute",
  width: "100%",
  top: 0,
  left: 0,
  right: 0,
  height: "30vh",
  background: "linear-gradient(180deg, #0D0F17 20.94%, rgba(13, 15, 23, 0) 71.35%)",
}));

const VideoBackground = styled("div")( ({
  position: "absolute",
  width: "100%",
  top: "70vh",
  bottom: 0,
  left: 0,
  right: 0,
  height: "30vh",
  background: "linear-gradient(180deg, rgba(13, 15, 23, 0) 10.94%, #0D0F17 71.35%)",
}));

const HeroSub = styled("div")( ({
  position: "relative",
  padding: "4rem 0",
}));

const Sections = styled("div")( ({
  padding: "0",
  [theme.breakpoints.down("md")]: {
    paddingTop: "4rem",
  },
}));

const NewsTitle = styled("h2")( ({
  fontSize: 48,
  fontWeight: 600,
  padding: "3rem 1rem",
}));

const CarouselContainer = styled("div")({});

const CardsContainer = styled("div")({});

const TrustedBy = styled("div")( ({
  paddingBottom: "1.25rem",
  fontSize: 45,
  fontWeight: 600,
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

export {
  root,
  Hero,
  HeroMain,
  VideoWrapper,
  VideoBackgroundTop,
  VideoBackground,
  HeroSub,
  Sections,
  NewsTitle,
  CarouselContainer,
  CardsContainer,
  TrustedBy,
  heroSubTitle,
  heroSubDescription,
  heroTitle,
  heroDescription
};