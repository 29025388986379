import { styled } from "@mui/system";
import { pageStyles } from "../../theme";
import theme from "../../theme";

const root = {
  ...pageStyles(theme).root,
};

const subHeading = {...pageStyles(theme).subHeading}

const Divider = styled("div")( ({
  width: "15rem",
  borderTop: "1px solid #fff",
  margin: "2rem 0",
}));

const Text = styled("p")( ({
  maxWidth: theme.spacing(90),
  fontSize: 20,
  lineHeight: 1.2,
}));

const main = {
  padding: "3rem 6rem",
};

const mainHeading ={
  ...pageStyles(theme).mainHeading,
};

const TableContainer = styled("div")( ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const withBorderBottom = {
  borderBottomWidth: 2,
  borderStyle: "solid",
  borderColor: theme.palette.primary.main,
};

const SectionHeading = styled("h2")( ({
  fontSize: 30,
  lineHeight: 1.2,
  fontWeight: 600,
  color: theme.palette.primary.main,
}));

export { root, Divider, Text, main, mainHeading, TableContainer, withBorderBottom, SectionHeading, subHeading };