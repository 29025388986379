import { styled } from "@mui/system";
import theme from "../../theme";

const Container = styled("div")(({
  borderTop: "1px solid #fff",
  padding: "0 1.5rem 2rem",
  "&:last-of-type": {
    borderBottom: "1px solid #fff",
  },
}));

const Head = styled("div")(({
  display: "flex",
  alignItems: "center",
  paddingTop: "1.5rem",
  paddingBottom: "1rem",
}));

const Icon = styled("div")(({
  marginRight: "1.25rem",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const Title = styled("h1")(({
  fontSize: 25,
  lineHeight: 1.2,
  fontWeight: 600,
}));

const Content = styled("p")(({
  fontSize: 17,
  lineHeight: 1.2,
}));

export { Container, Head, Icon, Title, Content };