import {  maxWidth, styled } from "@mui/system";
import { pageStyles } from "../../theme";
import theme from "../../theme";

const root = {
  ...pageStyles(theme).root,
};

const Divider = styled("div")(({
  width: "15rem",
  borderTop: "1px solid #fff",
  margin: "2rem 0",
}));

const Text = styled("p")(({
  maxWidth: theme.spacing(90),
  fontSize: 20,
  lineHeight: 1.2,
}));

const Main = styled("div")(({
  padding: "3rem 0rem",
}));

const mainDivider = {
  width: "100%",
  height: 2,
  background: "linear-gradient(360deg, #4995FF 0%, #235CFF 100%)",
  margin: "3rem 0",
};

const Construction = styled("div")(({
  position: "relative",
  margin: "4rem 0",
  background: "#25246D",
}));

const ConstructionImgWrapper = styled("div")(({
  paddingTop: theme.spacing(4),
  marginLeft: theme.spacing(-8),
  marginBottom: theme.spacing(-4),
  [theme.breakpoints.down("md")]: {
    opacity: 0.4,
    position: "absolute",
    width: "calc(100% + 5rem)",
    top: "50%",
    left: theme.spacing(-10),
    right: 0,
    transform: "translateY(-50%)",
    paddingTop: 0,
    marginBottom: 0,
    marginLeft: 0,
  },
}));

const constructionContent ={
  position: "relative",
};

const ConstructionTitle = styled("h2")(({
  display: "flex",
  alignItems: "center",
  fontSize: 30,
  fontWeight: 600,
  color: "#fff",
  textAlign: "center",
  whiteSpace: "nowrap",
}));

const List = styled("ul")(({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  paddingBottom: "2rem",
  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(-1),
  },
}));

const Data = styled("div")(({
  position: "relative",
  background: "#25246D",
}));

const DataBg = styled("div")({
  position: "absolute",
  width: "100%",
  height: "100%",
  left: 0,
  top: 0,
  clipPath: `polygon(
    0 50%,10% 20%,90% 20%,100% 0,100% 100%,0 100%
   )`,
  backgroundColor: "#25246D",
});

const DataContent = {
  position: "relative",
  padding: "4rem 0",
};

const Universe = styled("div")({
  position: "relative",
});

const UniverseBg = styled("div")({
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
});

const universeContentWrapper = {
  padding: "7.5rem 0",
  [theme.breakpoints.down("md")]: {
    padding: "0",
  },
};

const universeContent = {
  marginLeft: "0",
  position: "relative",
  height: "100%",
  maxWidth: "30%",
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
  },
};

const universeText = {
  ...pageStyles(theme).mainText,
};

const universeTextAditional = {
  fontWeight: 400,
  fontSize: 25,
  paddingBottom: 2,
  lineHeight: 1.2
}

const BoxWithUnderline = styled("div")(({
  marginTop: 6,
  marginLeft: "1.5rem",
  [theme.breakpoints.down("md")]: {
    marginLeft: "1rem",
  },
}));

const Underline = styled("div")({
  width: "6rem",
  height: 1,
  backgroundColor: "#fff",
  marginTop: 6,
});

const analysisContainer = {
  position: "relative",
  paddingBottom: "2.5rem",
  overflow: "hidden",
};

const AnalysisImgWrapper = styled("div")(({
  marginLeft: theme.spacing(-2),
  [theme.breakpoints.down("md")]: {
    opacity: 0.2,
    position: "absolute",
    width: "calc(100% + 5rem)",
    top: "50%",
    left: 0,
    right: theme.spacing(-10),
    transform: "translateY(-50%)",
    paddingTop: 0,
    marginBottom: 0,
    marginLeft: 0,
  },
}));

const MainText ={
  ...pageStyles(theme).mainText,
  [theme.breakpoints.down("md")]: {
    fontSize: 20,
  },
};

const mainHeading ={
  ...pageStyles(theme).mainHeading,
};

const MainSubHeading = {
  fontSize: 25,
  fontWeight: 700,
  paddingTop: 2,
  paddingBottom: 0,
  margin: 0,
  [theme.breakpoints.down("md")]: {
    paddingLeft: 2,
    paddingRight: 2,
  },
};

const leftContainer = {
  maxWidth: "32%",
  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
  },
}


export {
  root,
  Divider,
  Text,
  Main,
  mainDivider,
  Construction,
  ConstructionImgWrapper,
  constructionContent,
  ConstructionTitle,
  List,
  Data,
  DataBg,
  DataContent,
  Universe,
  UniverseBg,
  universeContentWrapper,
  universeContent,
  universeText,
  BoxWithUnderline,
  Underline,
  analysisContainer,
  AnalysisImgWrapper,
  MainText,
  mainHeading,
  MainSubHeading,
  universeTextAditional,
  leftContainer
};