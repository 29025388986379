import { styled } from "@mui/system";
import { pageStyles } from "../../theme";
import theme from "../../theme";

const Root = styled("div")(({
  ...pageStyles(theme),
}));

const RootCenter = styled("div")(({
  margin: "0 auto",
}));

const Main = {
  paddingTop: "3rem",
  paddingBottom: "3rem",
};

const MainDivider = styled("div")(({
  width: "100%",
  height: 2,
  background: "linear-gradient(360deg, #4995FF 0%, #235CFF 100%)",
  margin: "3rem 0",
}));

const mainHeading = {
  ...pageStyles(theme).mainHeading,
};

const MainText = styled("p")(({
  fontSize: 20,
  paddingBottom: theme.spacing(6),
}));

const Text = styled("p")(({
  fontSize: 20,
  lineHeight: 1.2,
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
}));

const Section = styled("div")(({
  padding: "1.25rem 0",
  color: "#4B5158",

  "& a": {
    color: "#1684FF",
    fontWeight: 600,
    textDecoration: "underline",
  },
}));

const SectionHeading = styled("h2")(({
  fontSize: 30,
  lineHeight: 1.2,
  fontWeight: 600,
}));

const SectionSubHeading = styled("h3")(({
  fontSize: 25,
  lineHeight: 1.2,
  fontWeight: 600,
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(2),
}));

export {
  Root,
  RootCenter,
  Main,
  MainDivider,
  mainHeading,
  MainText,
  Text,
  Section,
  SectionHeading,
  SectionSubHeading,
};