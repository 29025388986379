import { styled } from "@mui/system";
import { pageStyles } from "../../theme";
import theme from "../../theme";
const Root = styled("div")(({
  ...pageStyles(theme),
}));

const Divider = styled("div")(({
  width: "15rem",
  borderTop: "1px solid #fff",
  margin: "2rem 0",
}));

const Text = styled("p")(({
  maxWidth: theme.spacing(90),
  fontSize: 20,
  lineHeight: 1.2,
}));

const Main = styled("div")(({
  padding: "3rem 6rem",
}));

// not used
const mainHeading = {
  ...pageStyles(theme).mainHeading,
  color: theme.palette.primary.main,
};

export { Root, Divider, Text, Main, mainHeading };