import { styled } from "@mui/system";
import { pageStyles } from "../../theme";
import theme from "../../theme";

const Root = styled("div")(({
  ...pageStyles(theme),
  position: "relative",
  color: "#fff",
}));

const Hero = styled("div")({
  height: "100%",
});

const HeroWrapper = styled("div")({
  height: "calc(80vh - 10rem)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

const HeroSubTitle = styled("h2")({
  fontSize: 25,
});

const HeroBottomBg = styled("div")({
  zIndex: 1,
  position: "absolute",
  bottom: 0,
  left: 0,
  width: "100%",
  height: "30vh",
  background: "linear-gradient(180deg, rgba(72, 96, 124, 0) 0%, rgba(2, 5, 9, 0.244683) 23.96%, rgba(0, 0, 0, 0.572917) 50%, rgba(3, 6, 9, 0.804253) 77.08%, #0D1018 100%)",
});

const mainContainer = {
  zIndex: 1,
  position: "relative",
};

const Main = styled("div")(({
  padding: "2rem 0 6rem",
  backgroundColor: "#0D1018",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
}));

const mainContent = {
  paddingRight: "6rem",
  [theme.breakpoints.down("md")]: {
    paddingRight: "0",
  },
};

const MainTitle = styled("h1")(({
  fontSize: 40,
  fontWeight: 600,
  paddingTop: "4rem",
  paddingBottom: "0.75rem",
  [theme.breakpoints.down("md")]: {
    fontSize: 30,
  },
}));

const MainTitleTopLine = styled("div")({
  backgroundColor: "#698BEC",
  width: "3rem",
  height: 2,
  marginBottom: "0.5rem",
});

const MainDescription = styled("p")(({
  fontSize: "1.25rem",
  [theme.breakpoints.down("md")]: {
    fontSize: "1rem",
    paddingBottom: "5rem",
  },
}));

const MovingCircle = styled("div")({
  position: "absolute",
  width: "15rem",
  height: "15rem",
  borderRadius: "100%",
  background: "linear-gradient(360deg, #31BAFF 0%, #1684FF 100%)",
  filter: "blur(40px)",
  zIndex: 0,
  transform: "translate(-50%, -50%)",
});

const ImgWrapper = styled("div")({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
});

const MobileBg = styled("div")({
  position: "absolute",
  opacity: 0.5,
  top: 0,
  left: 0,
  width: "200%",
  bottom: 0,
  right: 0,
  backgroundSize: "cover",
  backgroundPosition: "5rem 3rem",
});

export {
  Root,
  Hero,
  HeroWrapper,
  HeroSubTitle,
  HeroBottomBg,
  mainContainer,
  Main,
  mainContent,
  MainTitle,
  MainTitleTopLine,
  MainDescription,
  MovingCircle,
  ImgWrapper,
  MobileBg,
};