import ImgAlejandro from "../../assets/images/about/Alejandro.png";
import ImgEkaterina from "../../assets/images/about/Ekaterina.png";
import ImgErnesto from "../../assets/images/about/Ernesto.png";
import ImgFreddyG from "../../assets/images/about/FreddyG.png";
import ImgFreddyM from "../../assets/images/about/FreddyM.png";
import ImgGeorge from "../../assets/images/about/George.svg";
import ImgJan from "../../assets/images/about/Jan.png";
import ImgJeff from "../../assets/images/about/Jeff.png";
import ImgLeslie from "../../assets/images/about/Leslie.svg";
import ImgMihail from "../../assets/images/about/Mihail.jpg";
import ImgPaul from "../../assets/images/about/Paul.svg";
import ImgRod from "../../assets/images/about/rod.png";
import ImgSabrina from "../../assets/images/about/Sabrina.png";
import ImgSanket from "../../assets/images/about/Sanket.jpg";
import ImgThomas from "../../assets/images/about/Thomas.jpg";
import ImgVictor from "../../assets/images/about/Victor.svg";

export const TEAM_DATA = [
  {
    id: 0,
    name: "Victor Hugo Gomez",
    position: "Chief Executive Officer",
    shortPosition: "CEO",
    image: ImgVictor,
    twitter: "",
    linkedin: "",
    description:
      "Victor is our company's co-founder and visionary leader, with a distinguished background in Fintech, including his role as the former Senior Vice President of Ventures for Deutsche Borse Group. His strategic foresight drives our success and growth.",
    direction: "left",
  },
  {
    id: 1,
    name: "Paul Herrera",
    position: "Chief Enterprise Architect",
    shortPosition: "CEA",
    image: ImgPaul,
    twitter: "",
    linkedin: "",
    description:
      "As a co-founder and architect behind BITA's disruptive technology, Paul stands at the forefront of innovation in the intersection of finance, tech and organizational agility. As we continue to push the boundaries of what's possible in index technology, Paul's vision and polymathic knowledge ensure that BITA remains delivering solutions that exceed the expectations of the financial sector.",
    direction: "center",
  },
  {
    id: 2,
    name: "George Kuznetsov",
    position: "Chief Operating Officer",
    shortPosition: "COO",
    image: ImgGeorge,
    twitter: "",
    linkedin: "",
    description:
      "George's rich experience spanning data analytics, financial services, and strategic consulting fortifies his role as our Chief Operations Officer. His dynamic, customer-centric leadership is central to our operational excellence.",
    direction: "right",
  },
  {
    id: 3,
    name: "Leslie Nicholson",
    position: "Chief Product Officer",
    shortPosition: "CPO",
    image: ImgLeslie,
    twitter: "",
    linkedin: "",
    description:
      "With a 20-year career in the investment industry, Leslie is a seasoned strategist. Her experience as Head of Indexes and ESG Data Products at TMX Group underscores her significant contribution as our Chief Product Officer.",
    direction: "left",
  },
  {
    id: 4,
    name: "Alejandro Moreau",
    position: "Director - Information Technology",
    shortPosition: "ITD",
    image: ImgAlejandro,
    twitter: "",
    linkedin: "",
    description:
      "With extensive experience in software consulting, development, and architecture, a knack for critical systems, and a passion for perfection, Alejandro is a seasoned technology leader. His innovative approach, coupled with his project leadership and mentoring, sets him apart as a remarkable technology professional.",
    direction: "center",
  },
  {
    id: 5,
    name: "Ekaterina Eshieva",
    position: "SVP - Compliance Lead",
    shortPosition: "SVP-CL",
    image: ImgEkaterina,
    twitter: "",
    linkedin: "",
    description:
      "Ekaterina is a dedicated professional who serves as the Senior Vice President (SVP)-Compliance Lead at BITA. She is a Russian-qualified lawyer with a Master’s degree in Finance and Law (Goethe University) and more than five years of legal experience. As one of the founding team members, Ekaterina has been an integral part of the company's growth and success.",
    direction: "right",
  },
  {
    id: 6,
    name: "Thomas Lee",
    position: "Head of Business Development - Americas",
    shortPosition: "HBD-A",
    image: ImgThomas,
    twitter: "",
    linkedin: "",
    description:
      "Thomas, our Global Head of Client Solutions & Business Development, brings a client-centric approach and a solutions-driven mindset to our team. He joined us in 2022 following a successful nine-year tenure at JPMorgan, where he held management roles in sales and product leadership. Prior to that, he built his career at New York Life Investments.",
    direction: "left",
  },
  {
    id: 7,
    name: "Rod Jones",
    position: "Global Head of Buy-Side Sales",
    shortPosition: "TL",
    image: ImgRod,
    twitter: "",
    linkedin: "",
    description:`Rod has spent his career working at the forefront of innovation at some of the 
    leading consulting, analytics and indexing firms in the investment industry.  His role 
    at BITA is a continuation of that journey.  He brings a deep understanding of how 
    analytics, algorithms and structured data can be used to transform investment 
    ideas to indices that are optimal for ETFs, structured products, and custom 
    solutions.  Rod is committed to helping our clients succeed.`,
    direction: "right",
  },  
  {
    id: 8,
    name: "Mihail Minchev",
    position: "Head of Product Development",
    shortPosition: "TL",
    image: ImgMihail,
    twitter: "",
    linkedin: "",
    description:"With over a decade of experience in the indexing industry, Mihail has held roles at Stoxx, IHS Markt and S&P DowJones Indices, and contributed to every aspect of the indexing value chain. His comprehensive business and technical expertise covers all asset classes and spreads over Research, Development and Operational Management of indices and calculation infrastructure. Passionate about advancing the field of indexing, Mihail combines an analytical mindset and a dedication to quality with a strong problem-solving orientation.",
    direction: "right",
  },  
  {
    id: 9,
    name: "Sanket Wajge",
    position: "Product Manager - BITACore",
    shortPosition: "PM",
    image: ImgSanket,
    twitter: "",
    linkedin: "",
    description:
      "Sanket has extensive expertise in Index Development and Management, with a career spanning over a decade. He has made significant contributions to developing and improving numerous index platforms. As a product manager, he plays a vital role in enhancing and efficiently managing our state-of-the-art index platform, BITACore, along with other innovative index solutions.",
    direction: "center",
  },
  {
    id: 10,
    name: "Jeff Caldwell",
    position: "Manager - Index Product Development",
    shortPosition: "IPDM",
    image: ImgJeff,
    twitter: "",
    linkedin: "",
    description:
      "Jeff combines his technical and product-first mindset from his background in engineering with his MBA and experience in managing investment accounts to create new, innovative indices for BITA and its clients.",
    direction: "center",
  },
  {
    id: 11,
    name: "Freddy González",
    position: "Manager - Index Operations",
    shortPosition: "M-IO",
    image: ImgFreddyG,
    twitter: "",
    linkedin: "",
    description:
      "Economist with postgraduate studies in economic sciences, business management, and entrepreneurship. He has worked in research and financial planning, risk management of investment portfolios, and operations management consulting in different economic sectors. Member of BITA since 2019, he helped create BITA’s Thematic R&D Team. Currently, he is the Manager of Index Operations. His vision always prioritizes the learning and creation of high-performance work teams, which generate added value that positively impacts the organization.",
    direction: "left",
},
  {
    id: 12,
    name: "Freddy Manrique",
    position: "IT Infrastructure Lead",
    shortPosition: "ITIL",
    image: ImgFreddyM,
    twitter: "",
    linkedin: "",
    description:
      "Freddy's passion for computer engineering has driven him to excel in his field over the last 7 years. He has developed several applications based on microservices and implemented state-of-the-art methodologies for software development. Freddy is a pro at developing, maintaining and deploying mission-critical systems on Amazon Web Services. His enthusiasm for the DevOps and SRE culture drives BITA's Cloud Infrastructure Team.",
    direction: "right",
  },
  {
    id: 13,
    name: "Sabrina Rojas",
    position: "ESG Lead",
    shortPosition: "ESGL",
    image: ImgSabrina,
    twitter: "",
    linkedin: "",
    description:
      "Sabrina is an economist who serves as the ESG Lead in BITA, bringing over three years of experience in the field. Her responsibilities include creating data and ESG-based thematic universes and other related products. Sabrina is highly passionate about sustainability and renewable energy, and she strives to deliver her work with precision, quality research, team management, and integration skills.",
    direction: "left",
  },
  {
    id: 14,
    name: "Ernesto Valera",
    position: "Thematics Lead",
    shortPosition: "TL",
    image: ImgErnesto,
    twitter: "",
    linkedin: "",
    description:
      "With more than ten years of experience in macroeconomic analysis, economic statistic compilation, and a master's degree in economic science, Ernesto leads the team behind the research and development of BITA's thematic database. His interest in economic integration, international trade, and new industries is essential for his role at BITA.",
    direction: "right",
  },  
];
