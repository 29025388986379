import { FC } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import {
  linkWrapper,
  noMarginComponent,
  LinkComponent,
  whiteComponent,
  LinkText,
  linkIcon,
} from "./styles";

interface Props {
  label: string;
  link: string;
  noMargin?: boolean;
  colorScheme?: "blue" | "white";
}

const ExternalLink: FC<Props> = ({
  label,
  link,
  noMargin = false,
  colorScheme = "blue",
}) => {
  return (
    <Box
      sx={{
        ...linkWrapper,
        noMargin: noMargin ? noMarginComponent : "",
        colorScheme: colorScheme === "white" ? { ...whiteComponent } : "",
      }}
    >
      <LinkComponent href={link}>
        <LinkText>{label}</LinkText>
        <Box
          sx={{
            ...linkIcon,
          }}
        >
          <KeyboardArrowRightIcon fontSize="small" />
        </Box>{" "}
      </LinkComponent>
    </Box>
  );
};
export default ExternalLink;

//! missing hover on learn more
