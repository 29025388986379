import { styled } from "@mui/system";
import theme from "../../theme";

const Container = styled("div")(({
  paddingX: "1rem",
  paddingBottom: "1rem",
  "& .MuiInput-underline": {
    paddingLeft: "1.25rem",
    paddingRight: "1.25rem",
  },
  "& .MuiSelect-icon": {
    right: "0.75rem",
  },
  [theme.breakpoints.down("sm")]: {
    paddingX: 0,
  },
}));

const formControlComponent ={
  width: "100%",
};


export { Container, formControlComponent };