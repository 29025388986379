import { styled } from "@mui/system";
import theme from "../../theme";

const Wrapper = styled("div")(({
  padding: "1.5rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  color: "#1B1B1B",
  borderRadius: "2px",
}));

const Title = styled("h2")(({
  fontSize: 30,
  lineHeight: 1.2,
  fontWeight: 600,
}));

const Description = styled("p")(({
  fontSize: 16,
  lineHeight: 1.2,
  paddingTop: "0.5rem",
}));

const ListWrapper = styled("div")(({
  paddingTop: "2rem",
  fontWeight: 600,
}));

const List = styled("ul")(({
  listStyle: "disc",
  paddingLeft: "1.5rem",
}));

const LearnMore = styled("div")(({
  paddingTop: "1.5rem",
  display: "flex",
  alignItems: "center",
  fontWeight: 600,
}));

export { Wrapper, Title, Description, ListWrapper, List, LearnMore };