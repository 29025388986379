import { styled } from "@mui/system";
import theme from "../../theme";

const Button = styled("div")(({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  background: "#fff",
  padding: "0.25rem 0.5rem",
  fontSize: 11,
  fontWeight: 600,
  color: theme.palette.primary.main,
  cursor: "pointer",
}));

const ButtonLabel = styled("span")({
  paddingLeft: "0.75rem",
  paddingRight: "0.75rem",
});

const menuItemComponent = {
  fontSize: 11,
  lineHeight: 1.2,
};

export { Button, ButtonLabel, menuItemComponent };