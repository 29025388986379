import { Box } from "@mui/material";
import { Container, Title, Icon, Label, Content } from "./styles";

interface Props {
  icon: React.ReactElement;
  title: string[];
  content: string;
  color?: string;
  background?: string;
}

const ThematicDataCard: React.FC<Props> = ({
  icon,
  title,
  content,
  color = "#fff",
  background = "#fff",
}) => {
  return (
    <Container
      sx={{
        borderColor: color,
      }}
    >
      <Title
        sx={{
          background,
          color,
          backgroundClip: "text",
        }}
      >
        <Icon>{icon}</Icon>
        <Label>
          {title.map((str, idx) => (
            <Box key={str + idx}>{str}</Box>
          ))}
        </Label>
      </Title>
      <Content>{content}</Content>
    </Container>
  );
};

export default ThematicDataCard;
