import { styled } from "@mui/system";
import theme from "../../theme";

const Header = styled("div")(({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),

  [theme.breakpoints.down("sm")]: {
    display: "block",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const Title = styled("h1")(({
  fontSize: 25,
  fontWeight: 600,
}));

const SearchInputComponent = styled("input")(({}));

const Item = styled("div")(({
  width: theme.spacing(9),
  height: theme.spacing(9),
  display: "flex",
  justifyContent: "center",
  margin: "auto",
  overflow: "hidden",
  cursor: "pointer",
  borderWidth: 2,
  borderStyle: "solid",
  borderRadius: "4rem",
  borderColor: "transparent",
}));

const Selected = styled("div")(({
  borderColor: "#fff",
}));

export { Header, Title, SearchInputComponent, Item, Selected };