import { styled } from "@mui/system";
import theme from "../../theme";

const ContainerComponent = styled("div")(({
  position: "relative",
  backgroundRepeat: "no-repeat",
  backgroundPositionX: "right",
  backgroundPositionY: "center",
  backgroundSize: "cover",
  marginTop: theme.spacing(2),

  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(0),
  },
}));

const Mask = styled("div")(({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: "30%",
  background: "linear-gradient(90deg, #2A2D31 67.23%, rgba(42, 45, 49, 0) 100%)",

  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const Main = styled("div")(({
  position: "relative",
  zIndex: 1,
  maxWidth: theme.spacing(50),
  paddingTop: theme.spacing(12),
  paddingBottom: theme.spacing(12),

  [theme.breakpoints.down("md")]: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
}));

const Title = styled("h1")(({
  fontSize: 50,
  fontWeight: 600,

  [theme.breakpoints.down("md")]: {
    fontSize: theme.spacing(5),
  },
}));

const Text = styled("p")(({
  fontSize: 25,
  marginTop: theme.spacing(2.5),

  [theme.breakpoints.down("md")]: {
    fontSize: theme.spacing(2.5),
  },
}));

export { ContainerComponent, Mask, Main, Title, Text };