import { FC, useMemo } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box } from "@mui/material";
import Slider from "react-slick";
import { Column } from "../../types/ui";
import Table from "../Table";
import { root } from "./styles";

interface Props {
  inMobile?: boolean;
  columns: Column[];
  data: any;
}

const ResponsiveTable: FC<Props> = ({ inMobile, columns, data }) => {
  const columnSets = useMemo(() => {
    const result: Column[][] = [];
    columns.forEach((col, index) => {
      if (index % 3 === 2 || index === columns.length - 1) {
        result.push(columns.slice(Math.floor(index / 3) * 3, index + 1));
      }
    });
    return result;
  }, [columns]);

  return (
    <Box sx={{ ...root }} pt={4} pb={6}>
      {inMobile ? (
        <Slider
          className="responsive-table"
          autoplay={false}
          infinite={false}
          dots
          prevArrow={<ChevronLeftIcon />}
          nextArrow={<ChevronRightIcon />}
        >
          {columnSets.map((columnSet) => (
            <Table
              headerHeight={60}
              columns={columnSet}
              data={[data]}
              dense
              hasPagination={false}
            />
          ))}
        </Slider>
      ) : (
        <Table columns={columns} data={[data]} dense hasPagination={false} />
      )}
    </Box>
  );
};

export default ResponsiveTable;
