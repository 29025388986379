import { FC } from "react";
import { Box } from "@mui/material";
import ImgArrows from "../../assets/images/products/arrows.svg";
import { Wrapper, ImgWrapper, Title } from "./styles";

interface Props {
  title: string;
  text: string;
}

const BitaCoreListItem: FC<Props> = ({ title, text }) => {
  return (
    <Wrapper
      sx={{
        paddingRight: { xs: 0, md: "4rem" },
        margin: { xs: "0.75rem 0", md: "1rem 0" },
      }}
    >
      <ImgWrapper>
        <img src={ImgArrows} alt="Arrows" />
      </ImgWrapper>
      <Title>{title}</Title>
      <Box>{text}</Box>
    </Wrapper>
  );
};

export default BitaCoreListItem;
