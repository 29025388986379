import { styled } from "@mui/system";
import theme from "../../theme";

const Main = styled("div")(({
  color: "#fff",
}));

const MainTitle = styled("h1")(({
  fontSize: 30,
  fontWeight: 600,
  textTransform: "uppercase",
}));

const MainTitleBorder = styled("div")(({
  width: theme.spacing(8),
  height: 2,
  backgroundColor: "#C2A633",
  margin: "0.5rem 0",
}));

const MainDescription = styled("p")(({
  fontSize: 20,
}));

const ChartComponent = styled("div")(({
  position: "relative",
  width: "100%",
  height: "100%",
  minHeight: theme.spacing(40),
  color: "#fff",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
}));

const ChartMain = styled("div")(({
  position: "absolute",
  inset: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: theme.spacing(3),
}));

const ChartTitle = styled("h2")(({
  fontSize: 20,
  fontWeight: 600,
}));

const ChartContainer = styled("div")(({
  width: "calc(100%+4rem)",
  marginLeft: theme.spacing(-4),
  marginRight: theme.spacing(-4),
}));

const ChartBottomSection = styled("div")(({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "space-between",
}));

const RealTimeContainer = styled("div")(({
  display: "flex",
  alignItems: "center",
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5),
  fontSize: 18,
  border: "1px solid #fff",
}));

const RealTimeLabel = styled("span")(({
  fontWeight: 600,
  marginRight: theme.spacing(2),
}));

const RealTimeValue = styled("span")(({}));

const SmallBoxes = styled("div")(({
  width: '30%',
}));

const SmallBox = styled("div")(({
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  marginTop: theme.spacing(1),
  fontSize: 14,
  border: "1px solid #fff",
  textAlign: "center",
}));

const SmallBoxLabel = styled("span")(({
  fontWeight: 600,
}));

export {
  Main,
  MainTitle,
  MainTitleBorder,
  MainDescription,
  ChartComponent,
  ChartMain,
  ChartTitle,
  ChartContainer,
  ChartBottomSection,
  RealTimeContainer,
  RealTimeLabel,
  RealTimeValue,
  SmallBoxes,
  SmallBox,
  SmallBoxLabel,
};