import { styled } from "@mui/system";
import theme from "../../theme";

const Section = styled("div")(({
  zIndex: 1,
  minHeight: "15rem",
  display: "flex",
  flexDirection: "column",
  padding: "2.5rem 3.75rem",
  margin: "2rem 0",
  backgroundColor: "#0d1018 !important",
  border: "1px solid",
  borderImage: "linear-gradient(281.98deg, #31BAFF 0%, rgba(35, 92, 255, 0) 47.06%) 1",
  background: "#1E1E1E",
  [theme.breakpoints.down("md")]: {
    minHeight: 0,
    padding: "2rem",
  },
}));

const SectionTitle = styled("h2")(({
  fontSize: 25,
  fontWeight: 600,
  color: "#31BAFF",
  [theme.breakpoints.down("md")]: {
    fontSize: 21,
  },
}));

const SectionTitleBottomLine = styled("div")(({
  width: "4rem",
  height: 1,
  background: "#31BAFF",
  marginTop: "0.25rem",
  marginBottom: "1.5rem",
}));

const SectionText = styled("p")(({
  flex: 1,
  fontSize: 18,
  [theme.breakpoints.down("md")]: {
    fontSize: 13,
  },
}));

const LinkWrapper = styled("div")(({
  marginTop: "0.5rem",
  marginRight: "2rem",
}));

const linkComponent = {
  display: "inline-flex",
  alignItems: "center",
  padding: "0.5rem 0.75rem",
  marginLeft: "-0.75rem",
  color: "#fff",
  fontWeight: 600,
  textDecoration: "none",
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: "transparent",
  "&:hover": {
    borderColor: "#235CFF",
    color: "#fff",
  },
  "&:active": {
    borderColor: "#235CFF",
    color: "#235CFF",
    backgroundColor: "#fff",
    "& svg": {
      background: "#fff",
      fill: "#235CFF",
    },
  },
};

const LinkIcon = styled("div")(({
  width: 20,
  height: 20,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#31BAFF",
  marginLeft: "0.5rem",
}));

const ComingSoon = styled("div")(({
  fontWeight: 600,
}));

export {
  Section,
  SectionTitle,
  SectionTitleBottomLine,
  SectionText,
  LinkWrapper,
  linkComponent,
  LinkIcon,
  ComingSoon,
};