import { styled } from "@mui/system";
import theme from "../../theme";

const LabelContainer = styled("div")(({
  alignItems: "center",
  cursor: "pointer",
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const labelComponent ={
  "&.MuiLink-root": {
    paddingLeft: 1,
    textDecoration: "none",
    fontWeight: 600,
    lineHeight: 1,
  },
};

const LinkContainer = styled("div")(({
  width: "100%",
  textAlign: "center",
  display: "none",
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

export { LabelContainer, labelComponent, LinkContainer };