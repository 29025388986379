const buttonComponent = {
  borderRadius: "0.5rem",
  fontSize: "1rem",
  fontWeight: 600,
  padding: "0.75rem 2rem",
  borderWidth: 1,
  borderColor: "#235CFF",
  borderStyle: "solid",
  "&:hover": {
    color: "#fff",
    backgroundColor: "transparent",
    borderColor: "#fff",
  },
  "&:active": {
    color: "#fff",
    backgroundColor: "#235CFF",
    borderColor: "#235CFF",
  }
};

export { buttonComponent };