import { Container, Head, Icon, Title, Content } from "./styles";

interface Props {
  icon: React.ReactElement;
  title: string;
  content: string;
}

const CryptoOfferingCard: React.FC<Props> = ({ icon, title, content }) => {
  return (
    <Container>
      <Head>
        <Icon>{icon}</Icon>
        <Title>{title}</Title>
      </Head>
      <Content>{content}</Content>
    </Container>
  );
};

export default CryptoOfferingCard;
