import { FC } from "react";
import { Box, Container } from "@mui/material";
import ImgBgBlue from "../../assets/images/backgrounds/blue.png";
import ImgBgDarkBlue from "../../assets/images/backgrounds/dark_blue.png";
import ImgBgGray from "../../assets/images/backgrounds/gray.png";
import ImgBgGreen from "../../assets/images/backgrounds/green.png";
import ImgBgLightBlue from "../../assets/images/backgrounds/light_blue.png";
import ImgBgLightGray from "../../assets/images/backgrounds/light_gray.png";
import ImgBgPurple from "../../assets/images/backgrounds/purple.png";
import { useIsMobileView } from "../../hooks/useWindowSize";
import { PageColorScheme, PageMainContentColorScheme } from "../../types/ui";
import {
  HeaderMask,
  HeaderMask2,
  responsiveHeadingComponent,
  headerStyles,
  headerContent,
  heading,
  subHeading,
} from "./styles";

const IMG_BG_MAP: { [key in PageColorScheme]: string } = {
  blue: ImgBgBlue,
  dark_blue: ImgBgDarkBlue,
  light_blue: ImgBgLightBlue,
  gray: ImgBgGray,
  light_gray: ImgBgLightGray,
  green: ImgBgGreen,
  purple: ImgBgPurple,
  home: "",
};

const MASK_COLOR_MAP: {
  [key in PageColorScheme]: { [key in PageMainContentColorScheme]: string[] };
} = {
  blue: { light: ["#4586F0", "#96BDFD"], dark: ["#122E7F", "#121B36"] },
  dark_blue: { light: ["#4C4AAD", "#7F7ED9"], dark: ["#1B1A4F", "#16162D"] },
  light_blue: { light: ["#5DB2FF", "#A3D3FF"], dark: ["#136BBB", "#0C3962"] },
  gray: { light: ["#4E5663", "#6C7788"], dark: ["#34383E", "#272A2F"] },
  light_gray: { light: [], dark: ["#34383E", "#272A2F"] }, // TODO: Update this
  green: { light: [], dark: ["#075643", "#08392E"] },
  purple: { light: [], dark: ["#432A5B", "#31243E"] },
  home: { light: [], dark: [] },
};

interface Props {
  colorScheme: PageColorScheme;
  title: string;
  subTitle?: string;
  mainContentColorScheme?: PageMainContentColorScheme;
  responsiveHeading?: boolean;
  extraElement?: React.ReactNode;
}

const PageHero: FC<Props> = ({
  colorScheme,
  title,
  subTitle,
  mainContentColorScheme = "light",
  responsiveHeading = false,
  extraElement = null,
}) => {
  const inMobile = useIsMobileView();

  return (
    <Box
      sx={{
        backgroundImage: `url(${IMG_BG_MAP[colorScheme]})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        ...headerStyles,
      }}
    >
      <HeaderMask
        sx={{
          backgroundColor:
            MASK_COLOR_MAP[colorScheme][mainContentColorScheme]?.[0],
        }}
      ></HeaderMask>
      <HeaderMask2
        sx={{
          backgroundColor:
            MASK_COLOR_MAP[colorScheme][mainContentColorScheme]?.[1],
        }}
      ></HeaderMask2>
      <Container sx={{ ...headerContent }}>
        <Box>
          <Box
            sx={{
              ...heading,
              ...(inMobile
                ? {}
                : responsiveHeading
                ? { ...responsiveHeadingComponent }
                : {}),
            }}
          >
            {title}
          </Box>
          {subTitle && <Box sx={{ ...subHeading }}>{subTitle}</Box>}
          {extraElement}
        </Box>
      </Container>
    </Box>
  );
};

export default PageHero;
