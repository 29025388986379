import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import GroupsIcon from "@mui/icons-material/Groups";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import ViewDayIcon from "@mui/icons-material/ViewDay";
import WorkIcon from "@mui/icons-material/Work";
import { Box, Container, Grid } from "@mui/material";
import ImgHero from "../../../assets/images/products/hero-thematic-data.png";
import PageHero from "../../../components/PageHero";
import ThematicDataCard from "../../../components/ThematicDataCard";
import ThematicDataCarousel from "../../../components/ThematicDataCarousel";
import ThematicIndexesCarousel from "../../../components/ThematicIndexesCarousel";
import {
  Root,
  Initial,
  Hero,
  Main,
  MainTopSection,
  HeroText,
  TopSectionHeading,
  sectionContainer,
  HeroSection,
  HeroInnerContainer,
  heroMainHeading,
  heroMainText,
  mainHeading,
  mainText,
  rootTheme,
} from "./styles";

const CARDS = [
  {
    id: 0,
    icon: <ViewDayIcon />,
    title: ["80+ themes"],
    content:
      "From exciting new technologies and emerging industries, all the way to the most relevant impact themes.",
  },
  {
    id: 1,
    icon: <WorkIcon />,
    title: ["400+ sub themes"],
    content:
      "Build tiered investment strategies and diversify across the landscape of each theme.",
  },
  {
    id: 2,
    icon: <SearchIcon />,
    title: ["2500+ Products, Services and Activities (PSAs)"],
    content:
      "From manufacturing of MRNA vaccines, to provision of cybersecurity consulting services, we calculate exposures at the most granular level.",
  },
  {
    id: 3,
    icon: <GroupsIcon />,
    title: ["7500+ companies"],
    content:
      "A bottom-up growing coverage of more than 7500 companies across all themes.",
  },
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INDEX_CARDS = [
  {
    id: 0,
    icon: <ApartmentOutlinedIcon fontSize="large" />,
    title: "Index Product Providers",
    content: "Expand thematic offerings",
  },
  {
    id: 1,
    icon: <PsychologyOutlinedIcon fontSize="large" />,
    title: "Active Managers",
    content: "Use thematic index universe for active strategies",
  },
  {
    id: 2,
    icon: <SettingsIcon fontSize="large" />,
    title: "Asset Owners",
    content: "Understand, measure and manage exposure to structural trends",
  },
];

const ThematicData = () => {
  return (
    <Root sx={{ ...rootTheme }}>
      <Initial>
        <PageHero
          colorScheme="purple"
          mainContentColorScheme="dark"
          title="Thematics"
          subTitle="Best-in-class range of Thematic solutions, all in one place"
        />

        <Container>
          <Hero>
            <Box sx={{ ...mainHeading }}>Thematic Data</Box>
            <Box sx={{ ...mainText }}>
              At BITA we leverage intense research and technology to deliver
              measurable, granular thematic solutions. Our range of 6
              megatrends, 80+ themes, hundreds of sub-themes, and thousands of
              activities allows clients to uncover and measure the true economic
              exposure of a company.
            </Box>
          </Hero>
        </Container>
      </Initial>

      <Main>
        <Container>
          <Box>
            <ThematicDataCarousel />
          </Box>
          <MainTopSection>
            <TopSectionHeading>
              The World’s Most Granular Thematic Database
            </TopSectionHeading>
            <Box sx={{ ...mainText }}>
              Our thematic database, available for licensing, spans across 4
              levels of granularity covering over 7500 securities, allowing
              clients to uncover and measure the true thematic and economic
              exposure of a company.
            </Box>
          </MainTopSection>
          <Grid sx={{ ...sectionContainer }} container spacing={3}>
            {CARDS.map((card) => (
              <Grid key={card.id} item xs={12} sm={6}>
                <ThematicDataCard {...card} />
              </Grid>
            ))}
          </Grid>
        </Container>

        <HeroSection>
          <Container>
            <HeroInnerContainer
              sx={{
                backgroundImage: { xs: "none", md: `url(${ImgHero})` },
              }}
            >
              <Box maxWidth={{ xs: "100%", md: "40%" }}>
                <Box sx={{ ...mainHeading, ...heroMainHeading }} mb={4}>
                  Human Research
                  <br />
                  Assisted by Technology
                </Box>
                <Box sx={{ ...HeroText, ...heroMainText }}>
                  Thematics are part of our DNA. We are skeptical of any
                  approach that claims to solve the fundamental research
                  conundrum via technology or AI alone.
                  <br />
                  <br />
                  At BITA, each theme is managed by a team of focused equity
                  analysts, ensuring that all noise is left behind.
                </Box>
              </Box>
            </HeroInnerContainer>
          </Container>
        </HeroSection>

        <Container>
          <Box>
            <Box sx={{ ...mainHeading }}>Thematic Indexes</Box>
            <Box sx={{ ...mainText }}>
              Discover our range of highly innovative, pure-play, thematic
              indexes. You don’t find an index that suits you?... let us build
              it for you.
            </Box>
          </Box>

          <Box pt={4} pb={8}>
            <ThematicIndexesCarousel />
          </Box>
        </Container>

        {/* <Box className={classes.sectionContainer}>
          <Box className={classes.mainHeading} mb={3}>
            BITA Thematic Landscape
          </Box>
          <Box py={8}>
            <img src={ImgGraph} alt="Graph" width="100%" />
          </Box>
        </Box> 

        <Box className={classes.sectionContainer}>
          <Box className={classes.mainHeading} mb={3}>
            BITA Thematic Indexes
          </Box>
          <Box className={classes.mainText}>
            BITA Thematic Indexes use a rule-based methodology that is
            systematic and customizable to directly capture megatrends or
            sub-themes.
          </Box>
          <Box pt={8}>
            <Grid container spacing={8}>
              {INDEX_CARDS.map((card) => (
                <Grid key={card.id} item xs={12} sm={4}>
                  <ThematicDataIndexCard {...card} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>*/}
      </Main>
    </Root>
  );
};

export default ThematicData;
