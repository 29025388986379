import { styled } from "@mui/system";
import { pageStyles } from "../../../theme";
import theme from "../../../theme";

const root ={
  ...pageStyles(theme).root,
};

const Divider = styled("div")(({
  width: "15rem",
  borderTop: "1px solid #fff",
  margin: "2rem 0",
}));

const Text = styled("p")(({
  maxWidth: theme.spacing(90),
  fontSize: 20,
  lineHeight: 1.2,
}));

const Main = styled("div")(({
  paddingTop: "6rem",
  paddingBottom: "4rem",
  backgroundColor: "#1b1b1b",
  [theme.breakpoints.down("sm")]: {
    paddingTop: "4rem",
  },
  "& .MuiTable-root": {
    "& .MuiTableCell-head": {
      backgroundColor: "#235CFF",
    },
    "& .MuiTableRow-root": {
      backgroundColor: "transparent",
    },
    "& .MuiTableCell-body": {
      color: "#fff",
      borderColor: "#235CFF",
    },
  },
  "& .MuiTablePagination-root": {
    color: "#fff",
  },
  "& .MuiTablePagination-actions .MuiButtonBase-root": {
    color: "#fff",
  },
}));

const MainHeading = styled("h1")(({
  ...pageStyles(theme),
  fontWeight: 700,
}));

const MainText = styled("p")(({
  ...pageStyles(theme),
  [theme.breakpoints.down("sm")]: {
    fontSize: 16,
    marginTop: "0.5rem",
    paddingBottom: "1.5rem",
  },
}));

const MainTopSection = styled("div")(({
  borderBottom: "2px solid #fff",
  paddingBottom: "3rem",
}));

const sectionContainer = {
  padding: "1rem 0rem 1rem",
  "& .MuiPaginationItem-root": {
    color: "#fff",
  },
};

const SelectsContainer = styled("div")(({
  paddingBottom: "1rem",
  display: "flex",
  gap: "1.25rem",
}));

const Highlighted = styled("div")(({
  position: "relative",
  paddingTop: "1.5rem",
  margin: "3rem 0",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
}));

const HighlightedText = styled("div")(({
  paddingLeft: "3rem",
  fontSize: "2.5rem",
  fontWeight: 600,
  [theme.breakpoints.down("sm")]: {
    paddingLeft: "1.5rem",
  },
}));

const HighlightedBox = styled("div")(({
  position: "relative",
  paddingTop: "4rem",
  bottom: 0,
  right: 0,
}));

const SectionFooter = styled("div")(({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const DisplayingInfo = styled("div")(({
  fontWeight: 600,
  fontSize: "1rem",
}));

const HeroInnerContainer = styled("div")(({
  paddingTop: "4.5rem",
  paddingBottom: "4.5rem",
  paddingLeft: "9.5rem",
  marginLeft: "-9.5rem",
  marginRight: "-9.5rem",
  width: "calc(100% + 20vw)",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    margin: 0,
    padding: 0,
    paddingTop: "1.5rem",
    paddingBottom: "1.5rem",
    backgroundImage: "none !important",
    borderTopWidth: 2,
    borderBottomWidth: 2,
    borderColor: "#fff",
  },
}));

const HeroHeading = styled("h2")(({
  maxWidth: "26rem",
  fontSize: "3rem",
  fontWeight: 700,
  lineHeight: 1.35,
}));

const HeroText = styled("p")(({
  padding: "2rem 0 1.5rem",
  fontSize: "1.5rem",
  maxWidth: "25rem",
}));

const CarouselSection = styled("div")(({
  padding: "3rem 0",
}));

const CarouselSectionHeading = styled("h2")(({
  fontSize: "3rem",
  fontWeight: 600,
  paddingBottom: "2.5rem",
}));

export {
  root,
  Divider,
  Text,
  Main,
  MainHeading,
  MainText,
  MainTopSection,
  sectionContainer,
  SelectsContainer,
  Highlighted,
  HighlightedText,
  HighlightedBox,
  SectionFooter,
  DisplayingInfo,
  HeroInnerContainer,
  HeroHeading,
  HeroText,
  CarouselSection,
  CarouselSectionHeading,
};
