import { useEffect, useState } from "react";
import { Box, Container, Grid, Link as MuiLink } from "@mui/material";
import { Link } from "react-router-dom";
import IconLogo from "../../assets/images/icons/Logo_simple.svg";
import {
  PageColorScheme,
  PageMainContentColorScheme,
  PageType,
} from "../../types/ui";
import ContactUs from "../ContactUs";
import SocialLinks from "../SocialLinks";
import { ITEMS } from "./menuitems";
import FooterMobileMenu from "./MobileMenu";
import {
  Root,
  LogoWrapper,
  Main,
  NavSection,
  NavList,
  Title,
  NavItem,
  Copyright,
  MobileRoot,
  MobileFollowUs,
} from "./styles";

interface Props {
  colorScheme: PageColorScheme;
  mainContentColorScheme: PageMainContentColorScheme;
  contactType?: PageType;
  noContactUs?: boolean;
}

const COLOR_MAP: {
  [key in PageColorScheme]: { [key in PageMainContentColorScheme]: string[] };
} = {
  blue: { light: ["#235CFF"], dark: ["#235CFF", "#25246D"] },
  dark_blue: { light: ["#25246D", "#4C4AAD"], dark: ["#25246D", "#1B1A4F"] },
  light_blue: { light: ["#1891FF", "#5DB2FF"], dark: ["#1995FF", "#136BBB"] },
  gray: { light: ["#3B3F44", "#4E5663"], dark: ["#3F4449", "#34383E"] },
  light_gray: { light: [""], dark: [""] },
  green: { light: [""], dark: ["#016C53", "#075643"] },
  purple: { light: [""], dark: ["#5E2B91", "#432A5B"] },
  home: { light: [], dark: ["#011948", "#11275B"] },
};

const Footer: React.FC<Props> = ({
  colorScheme,
  mainContentColorScheme,
  contactType = "contact",
  noContactUs = false,
}) => {
  const [currentYear, setCurrentYear] = useState<string>("2022");
  const colors = COLOR_MAP[colorScheme][mainContentColorScheme];

  useEffect(() => {
    const date = new Date();
    setCurrentYear(date.getFullYear().toString());
  }, []);

  return (
    <Box className="footer">
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <MobileRoot
          sx={{
            background: colors[0],
            "&::before": {
              background: colors[1],
            },
          }}
        >
          {!noContactUs && <ContactUs type={contactType} color={colors[0]} />}

          <Box py={{ xs: 3, md: 6 }}>
            <FooterMobileMenu items={ITEMS} />
          </Box>

          <Box>
            <MobileFollowUs>Follow Us!</MobileFollowUs>
            <SocialLinks inMobile />
          </Box>
          <Box pt={4} textAlign="center">
            <LogoWrapper>
              <img
                style={{ margin: "0 auto" }}
                src={IconLogo}
                alt="BITA Logo"
              />
            </LogoWrapper>
            <Copyright>Copyright © 2018-{currentYear} · BITA GmbH</Copyright>
          </Box>
        </MobileRoot>
      </Box>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <Root
          sx={{
            background: colors[0],
            "&::before": {
              background: colors[1],
            },
          }}
        >
          {!noContactUs && <ContactUs type={contactType} color={colors[0]} />}
          <Container>
            <Grid
              style={{
                color: "#fff",
                padding: "2.5rem 0",
                borderTop: "1px solid #fff",
                borderBottom: "1px solid #fff",
              }}
              container
            >
              <Grid item xs={12} sm={2}>
                <LogoWrapper>
                  <img src={IconLogo} alt="BITA Logo" />
                </LogoWrapper>
              </Grid>

              <Grid item xs={12} sm={10}>
                <Main>
                  {ITEMS.map(({ id, label, items, hidden }) =>
                    !hidden ? (
                      <NavSection>
                        <Box>
                          <Title>{label}</Title>
                        </Box>
                        <NavList>
                          {items?.map(
                            ({ id, label, href, hidden, target = "_self" }) =>
                              !hidden && href ? (
                                <NavItem>
                                  {href.startsWith("https://") ||
                                  href.startsWith("http://") ? (
                                    <MuiLink href={href}>{label}</MuiLink>
                                  ) : (
                                    <Link to={href} target={target}>
                                      {label}
                                    </Link>
                                  )}
                                </NavItem>
                              ) : null
                          )}
                        </NavList>
                      </NavSection>
                    ) : null
                  )}
                </Main>
              </Grid>
            </Grid>

            <Box pt={2.5}>
              <SocialLinks />
            </Box>

            <Copyright>Copyright © 2018-{currentYear} · BITA GmbH</Copyright>
            {/* TODO remove after testing purposes */}
            <Box style={{color: "transparent" }}>This is just testing the SW</Box>
          </Container>
        </Root>
      </Box>
    </Box>
  );
};

export default Footer;
