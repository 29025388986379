import { styled } from "@mui/system";
import theme from "../../theme";

const LoadingContainer = styled("div")(({
  width: "100%",
  height: "100%",
  textAlign: "center",
  padding: "20rem 0",
  [theme.breakpoints.down("md")]: {
    padding: "10rem 0",
  },
}));

export { LoadingContainer };