import { styled } from "@mui/system";

const CardContainer = styled("div")(({
  position: "relative",
  margin: "0 14px",
  paddingBottom: "8rem",
  borderRadius: "1rem",
  overflow: "hidden",
}));

const CardTextMain = styled("div")(({
  position: "absolute",
  width: "100%",
  minHeight: "12rem",
  bottom: 0,
  display: "flex",
  flexDirection: "column",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  padding: "1.5rem 1.5rem 0.5rem",
  zIndex: 1,
  transition: "padding 1s, margin 5s, height 1s",
}));

const CardTitle = styled("h2")(({
  fontSize: 25,
  lineHeight: 1.2,
  fontWeight: 600,
  paddingBottom: "0.75rem",
  maxWidth: "60%",
}));

const CardTextNotFlipped = styled("p")(({
  flex: 1,
  fontSize: 15,
  fontWeight: 500,
}));

const CardTextFlipped = styled("p")(({
  flex: 1,
  fontSize: 15,
  fontWeight: 500,
  paddingTop: "0.5rem",
  paddingLeft: "1rem",
  paddingBottom: "1rem",
}));

const List = styled("ul")(({
  listStyle: "disc",
}));

const CardAction = styled("div")(({
  width: "max-content",
  display: "inline-flex",
  alignItems: "center",
  cursor: "pointer",
  border: "1px solid",
  marginBottom: 0,
}));

const IconWrapper = styled("div")(({
  width: 20,
  height: 20,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginLeft: "0.5rem",
}));

const FooterActionLabel = styled("span")(({
  fontWeight: 600,
}));

export {
  CardContainer,
  CardTextMain,
  CardTitle,
  CardTextNotFlipped,
  CardTextFlipped,
  List,
  CardAction,
  IconWrapper,
  FooterActionLabel,
};