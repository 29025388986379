import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { Box, Container, Grid, Pagination } from "@mui/material";
import ImgHero from "../../../assets/images/products/index-lab/hero.svg";
import ExternalLink from "../../../components/ExternalLink";
import IndexLabCard from "../../../components/IndexLabCard";
import IndexLabCardCarousel from "../../../components/IndexLabCardCarousel";
import IndexLabDownloadLink from "../../../components/IndexLabDownloadLink";
import PageHero from "../../../components/PageHero";
import SearchInput from "../../../components/SearchInput";
import Table from "../../../components/Table";
import { useAppDispatch, useAppSelector } from "../../../hooks/useStore";
import { useIsMobileView } from "../../../hooks/useWindowSize";
import { fetchIndexLabs, selectIndexLab } from "../../../redux/slices/indexes";
import { IndexLab } from "../../../types/indexes";
import { Column } from "../../../types/ui";
import { getDownloadLinksFromIndexLab } from "../../../utils";
import {
  root,
  Main,
  MainHeading,
  MainText,
  sectionContainer,
  SelectsContainer,
  Highlighted,
  HighlightedText,
  HighlightedBox,
  SectionFooter,
  DisplayingInfo,
  HeroInnerContainer,
  HeroHeading,
  HeroText,
  CarouselSection,
  CarouselSectionHeading,
} from "./styles";

const PAGE_SIZE = 6;

const IndexLabPage = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(selectIndexLab);

  const prototype = data.find((item) => item.index_prototype);

  const [query, setQuery] = useState("");
  const [page, setPage] = useState<number>(1);

  const visibleItems = useMemo(() => {
    return data.filter(
      (item) =>
        item.status === "A" &&
        (query.length < 3 ||
          item.index_name.toLowerCase().includes(query.toLowerCase()))
    );
  }, [data, query]);

  const pageStart = (page - 1) * PAGE_SIZE;
  const pageEnd = Math.min(page * PAGE_SIZE, visibleItems.length);

  const itemsInPage = useMemo(() => {
    return visibleItems.slice(pageStart, pageEnd);
  }, [pageEnd, pageStart, visibleItems]);

  const paginationCount = Math.floor(visibleItems.length / PAGE_SIZE) + 1;
  const inMobile = useIsMobileView();

  const TableColumns: Column[] = [
    {
      key: "index_name",
      label: "Headline",
      sortable: false,
    },
    {
      key: "download",
      label: "Download",
      sortable: false,
      width: "10%",
      renderCell: (data) => {
        return (
          <IndexLabDownloadLink
            downloadLinks={getDownloadLinksFromIndexLab(data as IndexLab)}
          />
        );
      },
    },
  ];

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const handleChangeQuery = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  useEffect(() => {
    dispatch(fetchIndexLabs());
  }, [dispatch]);

  return (
    <Box sx={{ ...root }}>
      <PageHero
        colorScheme="blue"
        mainContentColorScheme="dark"
        title="The Index Lab"
        subTitle="Access to the latest innovative indexes in the martket! "
      />

      <Main>
        <Container>
          <MainHeading>Browse Innovative Prototypes</MainHeading>
          <MainText>
            At BITA we are one step ahead with the offering of innovative
            indexes that follow the latest trends in the market, become a
            pioneer at this and start licensing the next generation of
            best-in-class products.
          </MainText>
          <SelectsContainer>
            <SearchInput
              label=""
              placeholder="Search by..."
              onChange={handleChangeQuery}
              fullWidth={inMobile}
              colorScheme="white"
            />
          </SelectsContainer>
          {inMobile ? (
            <Table columns={TableColumns} data={data} dense />
          ) : (
            <Grid sx={{ ...sectionContainer }} container spacing={4}>
              {itemsInPage.map((item) => (
                <Grid item xs={12} sm={4}>
                  <IndexLabCard
                    title={item.index_name}
                    downloadLinks={getDownloadLinksFromIndexLab(item)}
                    bgImg={item.background_image_url}
                  />
                </Grid>
              ))}
              <Grid item xs={12}>
                <SectionFooter>
                  <DisplayingInfo>
                    Displaying {pageStart + 1} -&nbsp;
                    {pageEnd}&nbsp; of&nbsp;
                    {visibleItems.length} results
                  </DisplayingInfo>
                  <Pagination
                    count={paginationCount}
                    page={page}
                    onChange={handleChangePage}
                  />
                </SectionFooter>
              </Grid>
            </Grid>
          )}

          {!!prototype ? (
            <Highlighted
              sx={{
                backgroundImage: `url(${encodeURI(
                  prototype.background_image_url
                )})`,
              }}
            >
              <HighlightedText>Prototype of the month</HighlightedText>
              <HighlightedBox>
                <IndexLabCard
                  title={prototype.index_name}
                  description={prototype.description}
                  size="lg"
                  downloadLinks={getDownloadLinksFromIndexLab(prototype)}
                  bgImg={prototype.background_image_url}
                />
              </HighlightedBox>
            </Highlighted>
          ) : null}

          <Box>
            <HeroInnerContainer sx={{ backgroundImage: `url(${ImgHero})` }}>
              <HeroHeading>Do you want to launch your own index?</HeroHeading>
              <HeroText>
                Create your own indexes and explore its capabilities before
                release them into market.{" "}
              </HeroText>
              <Box>
                <ExternalLink
                  label="Contact Us"
                  link="/contact-us"
                  colorScheme={inMobile ? "blue" : "white"}
                  noMargin
                />
              </Box>
            </HeroInnerContainer>
          </Box>

          <CarouselSection>
            <CarouselSectionHeading>Latest Research</CarouselSectionHeading>
            <Box>
              <IndexLabCardCarousel />
            </Box>
          </CarouselSection>
        </Container>
      </Main>
    </Box>
  );
};

export default IndexLabPage;
