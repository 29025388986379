import { Box, Container, Grid } from "@mui/material";
import ImgAnalytics from "../../../assets/images/products/esg/analytics.svg";
import ImgDatabase from "../../../assets/images/products/esg/database.svg";
import ImgReport from "../../../assets/images/products/esg/report.svg";
import ImgSetting from "../../../assets/images/products/esg/setting.svg";
import ImgHero1 from "../../../assets/images/products/hero-esg-impact-data-1.svg";
import ESGCards from "../../../components/ESGCards";
import PageHero from "../../../components/PageHero";
import ThematicDataCard from "../../../components/ThematicDataCard";
import { useIsMobileView } from "../../../hooks/useWindowSize";
import {
  root,
  Main,
  MainTopSection,
  mainText,
  sectionContainer,
  HeroSection,
  heroSectionContainer,
  mainHeading,
} from "./styles";

const CARDS2 = [
  {
    id: 0,
    icon: <img src={ImgDatabase} alt="Database" />,
    title: ["Licenseable datasets"],
    content:
      "We license our data with lower restrictions compared to traditional vendors",
  },
  {
    id: 1,
    icon: <img src={ImgSetting} alt="Setting" />,
    title: ["Custom Indexes"],
    content:
      "We provide highly customized indexes and investment exposures, targeting a variety of client objectives.",
  },
  {
    id: 2,
    icon: <img src={ImgAnalytics} alt="Analytics" />,
    title: ["Analytics Software and APIs"],
    content:
      "Cloud-based software and APIs to efficiently decompose and analyze a portfolio’s environmental and impact exposure in both absolute and relative terms.",
  },
  {
    id: 3,
    icon: <img src={ImgReport} alt="Report" />,
    title: ["Reporting"],
    content:
      "Highly-customizable, automated factsheet and reporting solutions to comply with a large variety of regulatory frameworks.",
  },
];

const ESGImpactData = () => {
  const inMobile = useIsMobileView();

  return (
    <Box sx={{ ...root }}>
      <PageHero
        colorScheme="green"
        mainContentColorScheme="dark"
        title="ESG & Impact Data"
        subTitle="Best-in-class ESG & Impact datasets and products"
      />

      <Main>
        <Container>
          <MainTopSection>
            <Box sx={{ ...mainHeading }}>ESG &amp; Impact Data Coverage</Box>
            <Box sx={{ ...mainText }} mt={3}>
              BITA has developed a series of proprietary datasets and analytics
              by combining the best of technology and human research. BITA’s
              proprietary metric-level ESG and Impact data can empower multiple
              applications, from the development of innovative indexes and
              in-depth analysis of portfolios’ exposures, to regulator
              reporting.
            </Box>
            <Box sx={{ ...mainText }} mt={3}>
              We do not believe in ratings…
            </Box>
            <Box sx={{ ...mainText }} mt={3}>
              Some of our offering and coverage includes:
            </Box>
          </MainTopSection>
          <ESGCards />
        </Container>

        <HeroSection
          sx={{
            backgroundImage: inMobile ? "none" : `url(${ImgHero1})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <Container>
            <Box
              sx={{ ...heroSectionContainer }}
              maxWidth={{ xs: "100%", md: "50%" }}
            >
              {inMobile ? (
                <Box sx={{ ...mainHeading }} mb={3}>
                  Hard, no-ratings, data and analytics
                </Box>
              ) : (
                <Box sx={{ ...mainHeading }} mb={3}>
                  Hard, no-ratings,
                  <br />
                  data and analytics
                </Box>
              )}
              <Box sx={{ ...mainText }}>
                At BITA we do not believe in subjective, inconsistent ratings.
                We focus our efforts in the collection of fact-driven, reported,
                verifiable data. We provide our clients with the data and the
                tools to run their own judgements, and customized their
                assessments of a company’s performance.
              </Box>
            </Box>
          </Container>
        </HeroSection>

        <Container>
          <MainTopSection>
            <Box sx={{ ...mainHeading }}>Our Solutions</Box>
            <Box sx={{ ...mainText }} mt={3}>
              We offer a wide range of solutions around Impact and ESG
              investment and reporting
            </Box>
          </MainTopSection>
          <Grid sx={{ ...sectionContainer }} container spacing={2}>
            {CARDS2.map((card) => (
              <Grid key={card.id} item xs={12} sm={6}>
                <ThematicDataCard color="#fff" background="#fff" {...card} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Main>
    </Box>
  );
};

export default ESGImpactData;
