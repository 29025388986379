import { padding, styled } from "@mui/system";
import { pageStyles } from "../../theme";
import theme from "../../theme";

const root ={
  ...pageStyles(theme).root,
};

const Divider = styled("div")(({
  width: "15rem",
  borderTop: "1px solid #fff",
  margin: "2rem 0",
}));

const Text = styled("p")(({
  maxWidth: theme.spacing(90),
  fontSize: 20,
  lineHeight: 1.2,
}));

const main ={
  padding: "3rem 6rem",
};

const mainHeading = {
  ...pageStyles(theme).mainHeading,
};

const TableContainer = styled("div")(({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const SelectorsRow = styled("div")(({
  display: "flex",
  flexWrap: "wrap-reverse",
}));

const SelectorsContainer = styled("div")(({
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  paddingBottom: theme.spacing(3),
}));

const SelectContainer = styled("div")(({
  paddingRight: theme.spacing(3),
  [theme.breakpoints.down("md")]: {
    width: "100%",
    paddingRight: 0,
  },
}));

const SearchContainer = styled("div")(({
  marginLeft: "auto",
  [theme.breakpoints.down("md")]: {
    marginLeft: 0,
    marginBottom: theme.spacing(2),
    width: "100%",
  },
}));

const ResetBtnContainer = styled("div")(({
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
}));

const LegalContainer = styled("div")(({
  paddingTop: theme.spacing(2.5),
  paddingBottom: theme.spacing(2.5),
}));

const WithBorderBottom = styled("div")(({
  borderBottomWidth: 2,
  borderStyle: "solid",
  borderColor: theme.palette.primary.main,
}));

const AnnouncementsContainer = styled("div")(({
  paddingBottom: "3rem"
}));

const resetButton = {
  padding: ".8rem 2.2rem"
}

export {
  root,
  Divider,
  Text,
  main,
  mainHeading,
  TableContainer,
  SelectorsRow,
  SelectorsContainer,
  SelectContainer,
  SearchContainer,
  ResetBtnContainer,
  LegalContainer,
  WithBorderBottom,
  AnnouncementsContainer,
  resetButton
};