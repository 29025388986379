import { FC, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IMenuItem } from "../../../types/ui";
import MobileDropdown from "../../MobileDropdown";
import Transition from "../../Transition";
import { Root, Close } from "./styles";

interface Props {
  open: boolean;
  items: IMenuItem[];
  onClose: () => void;
}

const MobileMenu: FC<Props> = ({ open, items, onClose }) => {
  const navigate = useNavigate();

  const [selected, setSelected] = useState<string>();

  const handleCloseItem = () => setSelected(undefined);

  const handleCloseMenu = () => {
    handleCloseItem();
    onClose();
  };

  const handleClickItem = (href?: string) => {
    onClose();
    handleCloseItem();

    if (!href) return;

    if (href.includes("://")) {
      window.open(href, "_blank");
    } else {
      navigate(href);
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <Root>
        <Close onClick={handleCloseMenu}>
          <CloseIcon fontSize="medium" htmlColor="#25246D" />
        </Close>
        <Box>
          {items.map((item) => (
            <MobileDropdown
              {...item}
              key={item.id}
              open={item.id === selected}
              onOpen={setSelected}
              onClose={handleCloseItem}
              onClickItem={handleClickItem}
            />
          ))}
        </Box>
      </Root>
    </Dialog>
  );
};
export default MobileMenu;
