import { Box, Container } from "@mui/material";
import PageHero from "../../components/PageHero";
import {
  root,
  mainContainer,
  Main,
  Text,
  Section,
  SectionHeading,
} from "./styles";

const Imprint = () => {
  return (
    <Box sx={{ ...root }}>
      <PageHero colorScheme="light_blue" title="Imprint" />
      <Container sx={{ ...mainContainer }}>
        <Main>
          <SectionHeading sx={{ textAlign: "center" }}>Imprint</SectionHeading>

          <Section>
            <Text>BITA GmbH</Text>
            <Text>Mainzer Landstrasse 51</Text>
            <Text>60329</Text>
            <Text>Frankfurt am Main</Text>
            <Text>Germany</Text>
            <Text>Register court: AG Frankfurt/Main</Text>
            <Text>Register number: HRB 111519</Text>
          </Section>
          <Section>
            <Text>
              E-Mail: <a href="mailto:info@bita.io">info@bita.io</a>
            </Text>
          </Section>
        </Main>
      </Container>
    </Box>
  );
};

export default Imprint;
