import { FC } from "react";
import { Link } from "@mui/material";
import {
  linkComponent,
  Container,
  TypeWrapper,
  TypeText,
  Title,
  LearnMore,
} from "./styles";

interface Props {
  image: any;
  type: string;
  title: string;
  link?: string;
}

const ThematicIndexesCarouselCard: FC<Props> = ({
  image,
  type,
  title,
  link = "#",
}) => {
  return (
    <Link sx={{ ...linkComponent }} href={link} target="_blank">
      <Container
        sx={{
          backgroundImage: `url(${image})`,
        }}
      >
        <TypeWrapper>
          <span style={{ ...TypeText }}>{type}</span>
        </TypeWrapper>
        <Title>{title}</Title>
        <LearnMore>Learn More &gt;</LearnMore>
      </Container>
    </Link>
  );
};

export default ThematicIndexesCarouselCard;
