import { Box, Container, Grid } from "@mui/material";
import ImgFooter from "../../assets/images/about/footer.svg";
import ImgHero from "../../assets/images/about/hero.jpeg";
import ImgLogoLg from "../../assets/images/about/logo_lg.png";
import PageHero from "../../components/PageHero";
import Team from "../../components/Team";
import { useIsMobileView } from "../../hooks/useWindowSize";
import { STATS, STORY_LINES } from "./data";
import {
  root,
  HeroTitle,
  Section,
  SectionTitle,
  SectionSubTitle,
  sectionText,
  SectionDivider,
  Hero,
  HeroMain,
  HeroBg,
  HeroBgGradient,
  Intro,
  IntroMain,
  IntroBg,
  StatsSection,
  StatsItem,
  StatsLabel,
  StatsValue,
  Footer,
  FooterImg,
  TeamContainer,
} from "./styles";

const AboutUs = () => {
  const inMobile = useIsMobileView();

  return (
    <Box sx={{ ...root }}>
      <PageHero
        colorScheme="dark_blue"
        title="About BITA"
        subTitle="Building the rails and roads for the next generation of investing."
      />

      <Container>
        <Hero>
          <HeroBg sx={{ backgroundImage: `url(${ImgHero})` }}>
            <HeroBgGradient />
          </HeroBg>
          <HeroMain>
            <HeroTitle>The Vision</HeroTitle>
            <Box sx={{ ...sectionText }} pr={4}>
              <Box pb={4}>
                Customization of investments without limits: all the
                infrastructure, unlimited scale, in minutes, and without
                integration. A full range of APIs, tools, systems, and managed
                services. Powerful yet simple to use.
              </Box>
              <Box>
                We envision a future where the management of investments is
                tailored to any institution and individual without compromising
                efficiency. Where infrastructure becomes invisible yet
                indispensable, streamlining transparency, objectivity, and
                customization.
              </Box>
            </Box>
          </HeroMain>
        </Hero>
      </Container>

      <StatsSection>
        <Container>
          <Grid container spacing={6}>
            {STATS.map(({ id, value, label }) => (
              <Grid item xs={12} sm={12} lg={3} key={id}>
                <StatsItem>
                  <StatsValue>{value}</StatsValue>
                  <StatsLabel>{label}</StatsLabel>
                </StatsItem>
              </Grid>
            ))}
          </Grid>
        </Container>
      </StatsSection>

      <Box position="relative">
        <Container>
          <Intro>
            <IntroMain>
              {STORY_LINES.map(({ id, title, text }) => (
                <Box key={id}>
                  <SectionSubTitle>{title}</SectionSubTitle>
                  <Box sx={{ ...sectionText }}>{text}</Box>
                </Box>
              ))}
            </IntroMain>
            <Box flex={{ xs: 0, md: 1 }}></Box>
          </Intro>
        </Container>
        <IntroBg sx={{ backgroundImage: `url(${ImgLogoLg})` }} />
        <SectionDivider />
      </Box>

      <Container>
        <Section>
          <SectionTitle>Our Team</SectionTitle>
          <TeamContainer>
            <Team inMobile={inMobile} />
          </TeamContainer>
        </Section>
        <Footer>
          <FooterImg>
            <img src={ImgFooter} alt="Footer thumb" />
          </FooterImg>
        </Footer>
      </Container>
    </Box>
  );
};

export default AboutUs;
