import { useEffect } from "react";
import { Box, Container } from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";
import IndexTable from "../../components/IndexTable";
import PageHero from "../../components/PageHero";
import { useQuery } from "../../hooks/useQuery";
import { useAppDispatch, useAppSelector } from "../../hooks/useStore";
import {
  fetchFamiliesIndex,
  fetchIndexAssetClassOptions,
  fetchIndexesEOD,
  fetchIndexesWithWeight,
  fetchIndexList,
  fetchIndexTypes,
} from "../../redux/slices/indexes";
import theme from "../../theme";
import { Column } from "../../types/ui";
import { formatPercentage } from "../../utils/numeric";
import { DATE_FORMAT } from "../../utils/string";
import {
  ContainerWrapper,
  main,
  mainHeading,
  legalContainer,
  withBorderBottom,
  root,
  MainText,
} from "./styles";

export const TableColumns: Column[] = [
  {
    key: "name",
    label: "Index name",
    sortable: true,
    width: "32%",
    colSpan: 3,
    renderCell: (data) => (
      <Box sx={{ color: "primary.main", fontWeight: "bold" }}>
        <Link to={`/index/${data.index_id}`}>{data.name}</Link>
      </Box>
    ),
  },
  {
    key: "index_id",
    label: "Ticker",
    sortable: true,
    width: "12%",
    colSpan: 1,
  },
  // Hide for now (do not remove): https://app.clickup.com/t/4610131/IT-16193
  // {
  //   key: "figi",
  //   label: "FIGI Identifier",
  //   sortable: true,
  //   width: "14%",
  //   colSpan: 1,
  //   renderCell: (data) => <Box>{data.figi ?? "-"}</Box>,
  // },
  {
    key: "daily_close",
    label: "Daily Close",
    sortable: true,
    width: "14%",
    colSpan: 1,
    renderCell: (data) => (
      <Box>
        {data.daily_close ? `${data.daily_close} ${data.currency}` : "-"}
      </Box>
    ),
  },
  {
    key: "ytd",
    label: "YTD-Return",
    sortable: true,
    width: "14%",
    colSpan: 1,
    renderCell: (data) => <Box>{formatPercentage(data.ytd)}</Box>,
  },
  {
    key: "volatility",
    label: "1YR-Volatility",
    sortable: true,
    width: "15%",
    colSpan: 1,
    renderCell: (data) => <Box>{formatPercentage(data.volatility)}</Box>,
  },
];

const MOBILE_COLUMNS = ["name", "daily_close"];

const IndexPage = () => {
  const dispatch = useAppDispatch();
  const query = useQuery();
  const { indexes } = useAppSelector((state) => state.indexes);

  const queryAssetClass = query.get("asset_class");
  const queryIndexType = query.get("index_type");

  useEffect(() => {
    dispatch(fetchFamiliesIndex());
    dispatch(fetchIndexesWithWeight());
    dispatch(fetchIndexList());
    dispatch(fetchIndexAssetClassOptions());
    dispatch(fetchIndexTypes());
  }, [dispatch]);

  useEffect(() => {
    const ids = indexes?.map((index) => index.index_id) ?? [];
    const today = moment();
    const formattedToday = today.format(DATE_FORMAT);
    const day = today.day();
    const diff = day === 0 || day === 1 ? day + 2 : 1; // returns last business day, e.g. if today is Monday, last business day is last Friday
    const lastBusinessDay = today.add(-diff, "days").format(DATE_FORMAT);

    dispatch(
      fetchIndexesEOD({
        indexes: ids,
        startDate: lastBusinessDay,
        endDate: formattedToday,
      })
    );
  }, [dispatch, indexes]);

  return (
    <Box sx={{ ...root }}>
      <PageHero
        colorScheme="dark_blue"
        title="BITA Indexes"
        subTitle="Innovative calculation products, covering a large variety of exposures and themes"
      />

      <ContainerWrapper>
        <Container sx={{ ...main }}>
          <Box sx={{ ...mainHeading, color: theme.palette.primary.main }}>
            Our Indexes
          </Box>
          <Box
            sx={{ ...MainText, ...withBorderBottom, color: "text.secondary" }}
          >
            At BITA we provide independent, methodologically-sound indexes that
            are investable and replicable by customers and stakeholders. Our
            team of quantitative engineers has extensive experience working at
            some of the largest index providers and quantitative asset managers.
            Our methodologies and processes are completely transparent and
            publicly available.
          </Box>

          <IndexTable
            columns={TableColumns}
            mobileColumnIds={MOBILE_COLUMNS}
            queryParams={{
              assetClass: queryAssetClass,
              indexType: queryIndexType,
            }}
          />

          <Box sx={{ ...legalContainer, ...withBorderBottom }}>
            <b>Legal Disclaimer: </b>
            <br />
            This Page Is Informational, Impersonal and Not a Solicitation
            <br />
            <br />
            No information on this page constitutes a promotion, recommendation,
            inducement, offer, or solicitation to (i) purchase or sell any
            securities or assets, (ii) transact any other business, or (iii)
            enter into any other legal transactions. All information provided in
            this document is informational, impersonal, and not customized to
            the specific needs of any entity, person, or group of persons. The
            Index Administrator does not endorse, manage, promote, sell, or
            sponsor any investment fund or other vehicle that is offered by
            third parties and that seeks to provide an investment return linked
            to or based on the returns of any administered index.
          </Box>
        </Container>
      </ContainerWrapper>
    </Box>
  );
};

export default IndexPage;
