import { styled } from "@mui/system";
import theme from "../../theme";

const Container = styled("div")(({
  width: "100%",
  minHeight: "200px",
  display: "flex",
  flexDirection: "column",
  backgroundSize: "cover",
  padding: theme.spacing(2),
  marginBottom: theme.spacing(4),
}));

const Main = styled("div")(({
  flex: 1,
}));

const City = styled("h1")(({
  fontWeight: 600,
  fontSize: 30,
  lineHeight: 1.2,
  color: "#fff",
}));

const Country = styled("p")(({
  paddingTop: 1,
  fontSize: 20,
  lineHeight: 1.2,
  color: "#fff",
}));

const Address = styled("p")(({
  fontWeight: 600,
  color: "#fff",
}));

export { Container, Main, City, Country, Address };