import { FC, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import ImgPolygon from "../../assets/images/home/polygon.svg";
import ExternalLink from "../ExternalLink";
import {
  section,
  sectionContent,
  SectionContentInner,
  SectionSubTitle,
  SectionTitle,
  SectionText,
  sectionImage,
  Divider,
} from "./styles";

const leftVariants = {
  visible: {
    x: 0,
    opacity: 1,
    transition: { ease: "easeOut", duration: 0.6 },
  },
  hidden: {
    x: "-40vw",
    opacity: 0,
    transition: { ease: "easeOut", duration: 0.6 },
  },
};

const rightVariants = {
  visible: {
    x: 0,
    opacity: 1,
    transition: { ease: "easeOut", duration: 0.6 },
  },
  hidden: {
    x: "40vw",
    opacity: 0,
    transition: { ease: "easeOut", duration: 0.6 },
  },
};

interface Props {
  subTitle: string;
  title: string;
  text: string[];
  image: any;
  link: string;
  direction?: "reverse" | "standard";
}

const HomeSection: FC<Props> = ({
  subTitle,
  title,
  text,
  image,
  link,
  direction = "standard",
}) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.04 });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <Grid
      container
      sx={{ ...section }}
      className={direction}
      spacing={4}
      flexWrap="wrap-reverse"
    >
      <Grid item xs={12} sm={12} md={6} sx={{ ...sectionContent }}>
        <motion.div
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={direction === "standard" ? leftVariants : rightVariants}
          className="home-section"
          style={{ backgroundImage: `url(${ImgPolygon})` }}
        >
          <SectionContentInner>
            <SectionSubTitle>{subTitle}</SectionSubTitle>
            <Divider></Divider>
            <SectionTitle>{title}</SectionTitle>
            <SectionText>
              {text.map((str, idx) => (
                <Box key={str + idx} pb={2}>
                  {str}
                </Box>
              ))}
            </SectionText>
            <ExternalLink label="Learn More" link={link} />
          </SectionContentInner>
        </motion.div>
      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        <motion.div
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={direction === "standard" ? rightVariants : leftVariants}
          className="home-section"
        >
          <img src={image} alt={title} style={{ ...sectionImage }} />
        </motion.div>
      </Grid>
    </Grid>
  );
};

export default HomeSection;
