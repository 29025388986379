import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import {
  Wrapper,
  Title,
  Description,
  ListWrapper,
  List,
  LearnMore,
} from "./styles";

interface Props {
  title: string;
  description: string;
  items?: string[];
  bgColor: string;
}

const BitaCoreIndexCard: React.FC<Props> = ({
  title,
  description,
  items,
  bgColor,
}) => {
  return (
    <Wrapper
      sx={{
        height: { xs: "auto", md: "100%" },
        margin: { xs: "0.75rem 0", md: 0 },
        backgroundColor: bgColor,
      }}
    >
      <Box>
        <Title>{title}</Title>
        <Description>{description}</Description>
        {items && (
          <ListWrapper>
            <List>
              {items.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </List>
          </ListWrapper>
        )}
      </Box>
      <LearnMore>
        <ChevronRightIcon fontSize="small" />
        <Link to="/contact-us" target="_blank">
          Learn More
        </Link>
      </LearnMore>
    </Wrapper>
  );
};

export default BitaCoreIndexCard;
