import { styled } from "@mui/system";
import { pageStyles } from "../../../theme";
import theme from "../../../theme";

const Root = styled("div")(({
  ...pageStyles(theme),
  paddingBottom: theme.spacing(10),
}));

const Divider = styled("div")(({
  width: "15rem",
  borderTop: "1px solid #fff",
  margin: "2rem 0",
}));

const Text = styled("p")(({
  maxWidth: theme.spacing(90),
  fontSize: 20,
  lineHeight: 1.2,
}));

const main = {
  padding: "5rem 6rem 0",
  [theme.breakpoints.down("md")]: {
    padding: "2rem 3rem 0",
  },
};

const MainHeading = styled("h1")({
  fontSize: 40,
  fontWeight: 600,
});

const MainSubHeading = styled("h2")(({
  fontSize: 25,
  fontWeight: 500,
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const mainText ={
  fontSize: 25,
};

const sectionContainer = {
  padding: "2rem 0",
};

const SectionBorder = {
  [theme.breakpoints.down("md")]: {
    paddingTop: theme.spacing(4),
    borderBottom: "1px solid #fff",
  },
};

const ImgContainer = styled("div")({
  padding: "3rem 0",
  textAlign: "center",
  "& img": {
    margin: "0 auto",
  },
});

const BottomSectionInner = styled("div")({
  padding: "1.5rem 2rem",
  marginTop: "6rem",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
});

const BottomSectionText = styled("p")({
  marginTop: "1rem",
  fontSize: 18,
  lineHeight: 1.2,
});

const BottomSectionLink = styled("a")({
  marginTop: "3rem",
  fontSize: 16,
  lineHeight: 1.2,
  fontWeight: 600,
  color: "#fff",
});

const HiddenInMobile = styled("div")(({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

export {
  Root,
  Divider,
  Text,
  main,
  MainHeading,
  MainSubHeading,
  mainText,
  sectionContainer,
  SectionBorder,
  ImgContainer,
  BottomSectionInner,
  BottomSectionText,
  BottomSectionLink,
  HiddenInMobile,
};
