import { styled } from "@mui/system";
import { pageStyles } from "../../theme";
import theme from "../../theme";

const HeaderMask = styled("div")(({
  background: "#5EB2FF",
  position: "absolute",
  width: "100%",
  height: 26,
  left: 0,
  bottom: 12,
}));

const HeaderMask2 = styled("div")(({
  background: "#A3D3FF",
  position: "absolute",
  width: "100%",
  height: 12,
  left: 0,
  bottom: 0,
}));

const Divider = styled("div")(({
  width: "15rem",
  borderTop: "1px solid #fff",
  margin: "2rem 0",
}));

const ContainerWrapper = styled("div")({});

const Text = styled("p")(({
  maxWidth: theme.spacing(90),
  fontSize: 20,
  lineHeight: 1.2,
}));

const main = {
  padding: "3rem 0",
};

const InnerContainer = styled("div")(({
  margin: "0 -9.5rem",
  [theme.breakpoints.down("xl")]: {
    margin: "0 auto",
  },
}));

const mainHeading ={
  ...pageStyles(theme).mainHeading,
};

const legalContainer = {
  paddingTop: theme.spacing(2.5),
  paddingBottom: theme.spacing(2.5),
  [theme.breakpoints.down("md")]: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
};

const withBorderBottom = {
  borderBottomWidth: 2,
  borderStyle: "solid",
  borderColor: theme.palette.primary.main,
};

const root ={
  ...pageStyles(theme).root,
};

const MainText = {...pageStyles(theme).mainText}


export {
  HeaderMask,
  HeaderMask2,
  Divider,
  ContainerWrapper,
  Text,
  main,
  InnerContainer,
  mainHeading,
  legalContainer,
  withBorderBottom,
  root,
  MainText
};