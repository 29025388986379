import { FC } from "react";
import { Link } from "@mui/material";
import { Root, Type, Title, Date } from "./styles";

interface Props {
  type: string;
  title: string;
  date: string;
  bgImage: string;
  link: string;
}

const HomeCard: FC<Props> = ({ type, title, date, link, bgImage }) => {
  return (
    <Link href={link} target="_blank">
      <Root
        sx={{
          backgroundImage: `url(${bgImage})`,
        }}
      >
        <Type>{type}</Type>
        <Title>{title}</Title>
        <Date>{date}</Date>
      </Root>
    </Link>
  );
};

export default HomeCard;
