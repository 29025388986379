import { Container, Box } from "@mui/material";
import PageHero from "../../components/PageHero";
import {
  Root,
  Main,
  mainHeading,
  Text,
  Section,
  SectionHeading,
} from "./styles";

const Legal = () => {
  return (
    <Root>
      <PageHero colorScheme="light_blue" title="Terms & Conditions" />
      <Container sx={{ ...Main }}>
        <Box sx={{ ...mainHeading }}>Terms of Use, Legal and Disclaimer</Box>
        <Section>
          <Text>
            Please read the following legal notice / terms and conditions of use
            (“Terms and Conditions of Use”) carefully before accessing BITA’s
            website. By accessing the website of BITA GmbH, you confirm that you
            have read and understood the Terms and Conditions of Use and agree
            to all of the terms set out herein. If you do not understand, or do
            not agree to any of the terms contained in the Terms and Conditions
            of Use, please exit BITA’s website.
          </Text>
        </Section>

        <Section>
          <SectionHeading>
            This Website Is Informational, Impersonal and Not a Solicitation
          </SectionHeading>
          <Text>
            No information on this website constitutes a promotion,
            recommendation, inducement, offer, or solicitation to (i) purchase
            or sell any securities or assets, (ii) transact any other business,
            or (iii) enter into any other legal transactions. All information
            provided by BITA in this website is informational, impersonal and
            not customized to the specific needs of any entity, person, or group
            of persons. BITA does not endorse, manage, promote, sell, or sponsor
            any investment fund or other vehicle that is offered by third
            parties and that seeks to provide an investment return linked to or
            based on the returns of any BITA index.
          </Text>
        </Section>

        <Section>
          <SectionHeading>Restricted Access</SectionHeading>
          <Text>
            BITA GmbH’s website shall not be accessed by any person subject to a
            jurisdiction (e.g., due to that person’s nationality and/or place of
            residence) in which publication of the content of the website of
            BITA or access to it is prohibited for any reason. Persons to whom
            these restrictions apply are prohibited from accessing this website.
          </Text>
        </Section>

        <Section>
          <SectionHeading>Limitation of Liability</SectionHeading>
          <Text>
            BITA shall not be liable for any claims or losses of any nature in
            connection with the use or misuse of the information in this
            website, including but not limited to, lost profits or punitive or
            consequential damages, even if BITA has been advised of the
            possibility of same.
          </Text>
        </Section>

        <Section>
          <Text>
            You agree to defend, indemnify and hold harmless BITA and its
            affiliates, agents, contractors and licensors and the respective
            directors, officers, employees, agents and independent contractors
            from and against all damages, claims and expenses, including
            reasonable attorneys' fees, arising out of or in connection with
            your misuse of the Website, i.e. any use of the Website which
            violates these Conditions of Use or any applicable law or
            regulation.
          </Text>
        </Section>

        <Section>
          <SectionHeading>Research May Not Be Current</SectionHeading>
          <Text>
            This website has been prepared solely for informational purposes
            based on information generally available to the public from sources
            believed to be reliable. BITA makes no representation as to the
            accuracy or completeness of this website, the content of which may
            change without notice. BITA expressly disclaims any obligation to
            update the contents of this website to reflect developments in the
            digital asset sector. The methodology involves rebalancings and
            maintenance of indexes that are made periodically throughout the
            year and may not, therefore, reflect real-time information.
          </Text>
        </Section>

        <Section>
          <SectionHeading>Linked Products</SectionHeading>
          <Text>
            BITA is compensated in retribution for licensing its indexes to
            third parties for the creation of investment funds or other
            vehicles. BITA is not responsible for the information on these
            websites or for anything that they provide.
          </Text>
        </Section>

        <Section>
          <SectionHeading>
            Intellectual Property, Copyright, and Trademark Rights
          </SectionHeading>
          <Text>
            The web site and the Content are the property of BITA, its
            affiliates, or their third party licensors, and are protected by
            copyright and other intellectual property laws. All trade names,
            trademarks, service marks and other product and service names and
            logos on the web site and within the Content are proprietary to
            their respective owners and are protected by applicable trademark
            and copyright laws.
          </Text>
        </Section>

        <Section>
          <Text>
            Any of the trademarks, service marks or logos (collectively, the
            “Marks”) displayed on the web site may be registered or unregistered
            marks of BITA, its affiliates, or their third party licensors.
            Nothing contained on this web site should be construed as granting
            any license or right to use any of the Marks displayed on the web
            site without the express written permission of BITA or the third
            party owner of such Marks.
          </Text>
        </Section>

        <Section>
          <Text>
            All rights reserved. This website, in whole or in part, may not be
            redistributed, reproduced, and/or photocopied without prior written
            permission. You agree that all the information provided on or
            accessed through this website is for your information and personal
            use only. For you or any third party to use or exploit the
            information, BITA data, and/or BITA indexes available on the website
            for the purpose of creating an investment fund or other vehicle that
            seeks to match the performance of or whose capital and/or income
            value is related to an BITA index, or generally use or exploit the
            BITA data and/or BITA indexes in a way that benefits you or a third
            party, you must enter a license agreement with BITA.
          </Text>
        </Section>

        <Section>
          <SectionHeading>Anti-Hacking Provision</SectionHeading>
          <Text>
            You expressly agree not to: -use or attempt to use any “deep-link,”
            “scraper,” “robot,” “bot,” “spider,” “data-mining,” “computer code”
            or any other automated device, program, tool, algorithm, process, or
            methodology or manual process having similar processes or
            functionality, to access, acquire, copy, or monitor any portion of
            the web site, or Content found on or accessed through the web site
            or any Other Content without the prior express written consent of
            BITA;
          </Text>
        </Section>

        <Section>
          <Text>
            -obtain or attempt to obtain through any means any materials or
            information on the web site that have not been intentionally made
            publicly available either by their public display on the web site or
            through their accessibility by a visible link on the web site;
            -violate the restrictions in any robot inclusion headers on this web
            site or in any way bypass or circumvent any other measure employed
            to limit or prevent access to the web site or its Content; - violate
            the security of the web site or attempt to gain unauthorized access
            to the web site, its Content and Other Content, or the computer
            systems, networks or servers of BITA, through hacking, password
            mining or any other means; -interfere or attempt to interfere with
            the proper working of the web site or any activities conducted on or
            through the web site, including accessing any data, content or other
            information prior to the time that it is intended to be available to
            the public on the web site; and/or -take or attempt any action that,
            in the sole discretion of BITA, imposes or may impose an
            unreasonable or disproportionately large load or burden on the web
            site or BITA’ infrastructure.
          </Text>
        </Section>

        <Section>
          <SectionHeading>External Links</SectionHeading>
          <Text>
            This website includes links to external websites. Links to these
            websites are included to provide you with access to information and
            services that you may deem to be of use or interest. BITA is not
            responsible for the information on these websites or for anything
            that they provide.
          </Text>
        </Section>

        <Section>
          <SectionHeading>Applicable Law and Jurisdiction</SectionHeading>
          <Text>
            These Conditions of Use are governed by the substantive laws of
            Germany. Any dispute arising out of or in connection with these
            Conditions of Use and your use of the Website shall be submitted to
            the exclusive jurisdiction of the courts of Frankfurt, Hessen,
            Germany, provided however that (i) jurisdiction clauses in
            applicable License Agreements shall prevail, and that (ii) nothing
            herein shall prevent us from bringing any action against you at your
            seat or domicile. Notwithstanding anything in these Conditions of
            Use, we shall have the right to request injunctive relief (incl.
            preliminary measures) regarding our use of any Data at the court(s)
            where you use Data or where our rights shall be enforced.
          </Text>
        </Section>

        <Section>
          <SectionHeading>Contact Us</SectionHeading>
          <Text>
            If you have any comments, questions or if you believe that Content
            or Other Content published on this web site is infringing, please
            email us at <a href="mailto:info@bitadata.com">info@bitadata.com</a>
            . We will respond expeditiously.
          </Text>
        </Section>
      </Container>
    </Root>
  );
};

export default Legal;
