import { FC } from "react";
import { Grid } from "@mui/material";
import ExternalLink from "../ExternalLink";
import {
  section,
  sectionContent,
  SectionSubTitle,
  SectionSubTitleBottom,
  SectionTitle,
  SectionText,
  sectionImage,
  Divider,
} from "./styles";

interface Props {
  subTitle: string;
  title: string;
  text: string[];
  image: any;
  direction?: "reverse" | "standard";
}

const IndexServiceSection: FC<Props> = ({
  subTitle,
  title,
  text,
  image,
  direction = "standard",
}) => {
  return (
    <Grid container sx={{ ...section }} className={direction}>
      <Divider />
      <Grid item xs={12} sm={12} md={7} sx={{ ...sectionImage }}>
        <img src={image} alt={title} />
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={5}
        className={direction}
        sx={{ ...sectionContent }}
      >
        <SectionSubTitle>{subTitle}</SectionSubTitle>
        <SectionSubTitleBottom />
        <SectionTitle>{title}</SectionTitle>

        {text.map((t, idx) => (
          <SectionText key={t + idx}>{t}</SectionText>
        ))}

        <ExternalLink label="Contact Us" link="/contact-us" />
      </Grid>
    </Grid>
  );
};

export default IndexServiceSection;
