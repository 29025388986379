import { styled } from "@mui/system";

const Root = styled("div")(({
  position: "fixed",
  width: "100%",
  height: "12.5rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "6rem 1.5rem 3rem",
  background: "transparent",
  zIndex: 1,
  transition: "all 0.3s",
  "&.scrolled": {
    height: "auto",
    paddingTop: "0.75rem",
    paddingBottom: "0.75rem",
    background: "rgba(0, 0, 0, 0.92)",
  },
}));

const scrolledComponent = {
  height: "auto",
  paddingTop: "0.75rem",
  paddingBottom: "0.75rem",
  background: "rgba(0, 0, 0, 0.92)",
}

const LogoWrapper = styled("div")(({
  "& img": {
    maxWidth: "120%",
    width: "120%",
  },
}));

const NavContainer = styled("div")(({
  flex: 1,
  "&.MuiContainer-root": {
    marginLeft: 0,
    marginRight: 0,
  },
}));

const NavList = styled("ul")(({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}));

const NavItem = styled("li")(({
  paddingLeft: "1rem",
  paddingRight: "1rem",
  color: "#fff",
  fontSize: "1rem",
  lineHeight: "18px",
}));

const menuButton = {
  color: "#fff",
  fontSize: 15,
  fontWeight: 400,
  textTransform: "none",
};

const menuComponent = {
  "& .MuiPaper-root": {
    background:
      "radial-gradient(100% 100% at 49.76% 100%, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%), radial-gradient(100% 197.64% at 2.09% 0%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.05) 100%)",
    backdropFilter: "blur(12.5px)",
    borderRadius: 2,
    borderWidth: "1px",
    borderStyle: "solid",
    borderImageSource:
      "radial-gradient(80.45% 162.13% at 0% 0%, #FFFFFF 0%, rgba(155, 181, 255, 0.5) 32.53%, rgba(155, 181, 255, 0.2) 64.91%, rgba(255, 255, 255, 0) 100%)",
    boxShadow: "5px 5px 34px -10px rgba(0, 0, 0, 0.25)",
  },
};

const menuItemComponent = {
  color: "#fff",
  fontSize: "15px",
  fontWeight: 400,
  textTransform: "none",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

const mobileAppBar = {
  backgroundColor: "transparent",
  boxShadow: "none",
  transition: "all 0.3s",
  "&.scrolled": {
    backgroundColor: "rgba(0, 0, 0, 0.92)",
  },
};

const mobileScrolled = {
  "&.MuiAppBar-root": {
    backgroundColor: "rgba(0, 0, 0, 0.92)",
    height: 'auto',
    background:' rgba(0, 0, 0, 0.92)',
    paddingTop: '0.75rem',
    paddingBottom: '0.75rem'
  }
  
};

const MobileMenuContainer = styled("div")(({
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "2rem 1.5rem",
}));

const MobileMenuComponent = styled("div")(({
  width: "100vw",
}));

const MobileMenuItem = styled("div")(({
  width: "100%",
}));

const MobileMenuSubItem = styled("div")(({
  paddingLeft: "2rem",
}));

export {
  Root,
  LogoWrapper,
  NavContainer,
  NavList,
  NavItem,
  menuButton,
  menuComponent,
  menuItemComponent,
  mobileAppBar,
  MobileMenuContainer,
  MobileMenuComponent,
  MobileMenuItem,
  MobileMenuSubItem,
  mobileScrolled,
  scrolledComponent
};