import { styled } from "@mui/system";
import theme from "../../theme";

const Root = styled("div")(({
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
}));

const SelectsContainer = styled("div")(({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingBottom: theme.spacing(6),

  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

const ListContainer = styled("div")(() => ({}));

const ListFooter = styled("div")(({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

const FooterInfo = styled("div")(({
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const PaginationComponent = styled("div")(() => ({}));

export { Root, SelectsContainer, ListContainer, ListFooter, FooterInfo, PaginationComponent };