import { styled } from "@mui/system";
import { pageStyles } from "../../theme";
import theme from "../../theme";

const root = {
  ...pageStyles(theme).root,
};

const mainContainer = {
  textAlign: "center",
};

const Main = styled("div")( ({
  display: "inline-block",
  padding: "5rem 4rem",
  margin: "0 auto",
  textAlign: "left",
}));

const Text = styled("p")(({
  fontSize: "1.25rem",
  lineHeight: 1.2,
  textAlign: "center",
}));

const Section = styled("div")(({
  padding: "1.25rem 0",
  color: "#4B5158",
  "& a": {
    color: "#1684FF",
    fontWeight: 600,
    textDecoration: "underline",
  }
}));

const SectionHeading = styled("h2")( ({
  fontSize: "1.5rem",
  lineHeight: 1.2,
  fontWeight: 600,
  textAlign: "center",
}));

export {
  root,
  mainContainer,
  Main,
  Text,
  Section,
  SectionHeading,
};