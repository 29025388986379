import { useLayoutEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "../theme";

export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return size;
}

export function useIsMobileView() {
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return matches;
}
