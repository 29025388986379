import { styled } from "@mui/system";

const Root = styled("div")(({
  position: "relative",
  padding: "2rem 6rem",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: 14,
  },
}));

const ContainerComponent = styled("div")(({
  color: "#fff",
  padding: "2.5rem 0",
  borderTop: "1px solid #fff",
  borderBottom: "1px solid #fff",
}));

const LogoWrapper = styled("div")(({}));

const Main = styled("div")(({
  display: "flex",
  justifyContent: "space-between",
}));

const NavSection = styled("div")(({
  paddingRight: "2rem",
}));

const NavList = styled("ul")(({
  paddingTop: "0.1rem",
}));

const Title = styled("h2")(({
  fontWeight: 600,
  fontSize: 15,
  lineHeight: "1.25rem",
}));

const NavItem = styled("li")(({
  color: "#fff",
  fontSize: 15,
  lineHeight: "18px",
  "& a": {
    color: "#fff",
    textDecoration: "none",
  },
}));

const Copyright = styled("div")(({
  paddingTop: "1.5rem",
  fontWeight: 600,
  fontSize: 15,
  lineHeight: "1.25rem",
  textAlign: "center",
  color: "#fff",
}));

const LinkComponent = styled("a")(({}));

// Mobile styles
const MobileRoot = styled("div")(({
  position: "relative",
  padding: "3rem",
  color: "#fff",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: 24,
  },
}));

const MobileFollowUs = styled("div")(({
  fontSize: "1.25rem",
  fontWeight: 700,
  textAlign: "center",
  paddingBottom: "1.5rem",
}));

const MobileLogo = styled("div")(({
  margin: "0 auto",
}));

const MobileAccordion = styled("div")(({
  "&.MuiAccordion-root": {
    backgroundColor: "transparent",
    boxShadow: "none",
    "&::before": {
      backgroundColor: "#fff",
      opacity: 1,
    },
    "&.Mui-expanded:before": {
      opacity: 1,
    },
  },
}));

const MobileAccordionSummary = styled("div")(({
  "&.MuiAccordionSummary-root": {
    color: "#fff",
    fontSize: "1.25rem",
    fontWeight: 700,
    padding: "0.5rem 0",
  },
}));

const MobileAccordionDetails = styled("div")(({
  "&.MuiAccordionDetails-root": {
    padding: 0,
  },
}));

const MobileNavItem = styled("div")(({
  padding: "0.5rem",
}));

export {
  Root,
  ContainerComponent,
  LogoWrapper,
  Main,
  NavSection,
  NavList,
  Title,
  NavItem,
  Copyright,
  LinkComponent,
  MobileRoot,
  MobileFollowUs,
  MobileLogo,
  MobileAccordion,
  MobileAccordionSummary,
  MobileAccordionDetails,
  MobileNavItem,
};