import { useEffect, useMemo } from "react";
import { Box, Container, Link } from "@mui/material";
import IndexTable from "../../components/IndexTable";
import PageHero from "../../components/PageHero";
import PdfDownloadCell from "../../components/PdfDownloadCell";
import Table from "../../components/Table";
import { useAppDispatch } from "../../hooks/useStore";
import { useIsMobileView } from "../../hooks/useWindowSize";
import {
  fetchFamiliesIndex,
  fetchIndexesWithWeight,
  fetchIndexList,
} from "../../redux/slices/indexes";
import theme from "../../theme";
import { IndexFile, IndexTableRow } from "../../types/indexes";
import { Column } from "../../types/ui";
import { getMobileColumns } from "../../utils/table";
import { PoliciesTableColumns, PoliciesTableData } from "./data";
import {
  root,
  main,
  mainHeading,
  TableContainer,
  withBorderBottom,
  SectionHeading,
  subHeading,
} from "./styles";

const MOBILE_COLUMNS = ["name", "index_id"];

const GovernancePage = () => {
  const dispatch = useAppDispatch();
  const inMobile = useIsMobileView();

  const columns = useMemo(
    () =>
      inMobile ? getMobileColumns(PoliciesTableColumns) : PoliciesTableColumns,
    [inMobile]
  );

  const TableColumns: Column[] = [
    {
      key: "name",
      label: "Index name",
      sortable: true,
      width: "40%",
    },
    {
      key: "index_id",
      label: "Ticker",
      sortable: true,
      width: "10%",
      colSpan: 1,
    },
    {
      key: "methodology_guidebook",
      label: "METHODOLOGY Guidebook",
      sortable: false,
      width: "25%",
      renderCell: (data) => {
        const file = data.files.find(
          (f: IndexFile) => f.type === "methodology_guidebook"
        );
        return file ? (
          <PdfDownloadCell label={file.key} href={file.url} />
        ) : (
          <Box>-</Box>
        );
      },
    },
    {
      key: "benchmark_statement",
      label: "BENCHMARK STATEMENT",
      sortable: false,
      width: "25%",
      renderCell: (data) => {
        const file = data.files.find(
          (f: IndexFile) => f.type === "benchmark_statement"
        );
        return file ? (
          <PdfDownloadCell label={file.key} href={file.url} />
        ) : (
          <Box>-</Box>
        );
      },
    },
  ];

  const filterFunc = (row: IndexTableRow) => {
    const file = row.files.find(
      ({ type }: IndexFile) =>
        type === "methodology_guidebook" || type === "benchmark_statement"
    );
    return !!file;
  };

  useEffect(() => {
    dispatch(fetchFamiliesIndex());
    dispatch(fetchIndexesWithWeight());
    dispatch(fetchIndexList());
  }, [dispatch]);

  return (
    <Box sx={{ ...root }}>
      <PageHero colorScheme="dark_blue" title="Governance" />
      <Container sx={{ ...main }}>
        <Box sx={{ ...mainHeading, color: theme.palette.primary.main }}>
          Governance
        </Box>
        <Box pb={6} sx={{ ...withBorderBottom, ...subHeading }}>
          Since its foundation, BITA has been actively committed to the
          provision of high-quality, reliable, and transparent technology and
          index solutions. As such, our company has implemented a series of
          processes and policies to ensure complete compliance with the
          principles of financial benchmarking as well as the EU Benchmark
          Regulation. The EU Benchmark Regulation is in application since 1
          January 2018 and affects both EU and non-EU entities that administer
          indices that are used in the EU as a benchmark in financial
          instruments and financial contracts or to measure the performance of
          investment funds.
          <br />
          <br />
          Below we have made available all relevant regulatory and compliance
          policies, as well as all guides documentation related to the indexes
          currently offered by BITA. The German Federal Financial Supervisory
          Authority (BaFin) has registered BITA as a benchmark administrator
          under Art 34 of the EU Benchmark Regulation (BMR). BITA and the
          indices administered by BITA have been included in the ESMA benchmark
          register of administrators in accordance with Art 36 of the EU
          Benchmark Regulation.
          <br />
          <br />
          Should you have any specific questions, please contact us at&nbsp;
          <Link
            href="mailto:regulation@bitadata.com"
            sx={{
              fontWeight: 700,
              textDecoration: "none",
            }}
          >
            regulation@bitadata.com
          </Link>
        </Box>

        <Box sx={{ ...withBorderBottom }}>
          <TableContainer>
            <SectionHeading
              sx={{
                paddingBottom: 3,
              }}
            >
              Policies
            </SectionHeading>
            <Table columns={columns} data={PoliciesTableData} />
          </TableContainer>
        </Box>

        <TableContainer>
          <SectionHeading>Index Resources</SectionHeading>
          <IndexTable
            columns={TableColumns}
            mobileColumnIds={MOBILE_COLUMNS}
            dataFilterFunc={filterFunc}
          />
        </TableContainer>
      </Container>
    </Box>
  );
};

export default GovernancePage;
