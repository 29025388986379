import { styled } from "@mui/system";
import { pageStyles } from "../../theme";
import theme from "../../theme";

const Root = styled("div")(({
  ...pageStyles(theme),
}));

const Divider = styled("div")( ({
  width: "15rem",
  borderTop: "1px solid #fff",
  margin: "2rem 0",
}));

const BackBtn = styled("div")({
  paddingTop: "2.5rem",
});

const main = {
  padding: "3rem 6rem",
};

const mainText ={
  ...pageStyles(theme).mainText
};


const InnerContainer = styled("div")( ({
  margin: "0 -9.5rem",
  [theme.breakpoints.down("xl")]: {
    margin: "0 auto",
  },
}));

const mainHeading = {
  ...pageStyles(theme).mainHeading,
};

const SectionHeading = styled("h2")( ({
  fontSize: 30,
  lineHeight: 1.2,
  fontWeight: 600,
  color: theme.palette.primary.main,
}));

const TableContainer = styled("div")( ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const SelectorsContainer = styled("div")( ({
  display: "flex",
  paddingBottom: theme.spacing(3),
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    paddingTop: theme.spacing(3),
  },
}));

const SelectContainer = styled("div")( ({
  paddingRight: theme.spacing(3),
}));

const DatePickersContainer = styled("div")( ({
  marginLeft: "auto",
  display: "flex",
}));

const LegalContainer = styled("div")( ({
  paddingTop: theme.spacing(2.5),
  paddingBottom: theme.spacing(2.5),
}));

const withBorderBottom = {
  borderBottomWidth: 2,
  borderStyle: "solid",
  borderColor: theme.palette.primary.main,
};

const AnnouncementsContainer = styled("div")({
  padding: "3rem 0",
});

// todo, look up this component
/* const mobileDatePicker = {
  [theme.breakpoints.down("lg")]: {
    marginRight: theme.spacing(3),
  },
}; */

export {
  Root,
  Divider,
  BackBtn,
  main,
  InnerContainer,
  mainHeading,
  SectionHeading,
  TableContainer,
  SelectorsContainer,
  SelectContainer,
  DatePickersContainer,
  LegalContainer,
  withBorderBottom,
  AnnouncementsContainer,
  mainText
};