import { FC } from "react";
import GifSpinner from "../../assets/images/icons/spinner-2.gif";
import { Main } from "./styles";

interface Props {
  size?: number;
}

const LoadingSpinner: FC<Props> = ({ size = 120 }) => {
  return (
    <Main>
      <img src={GifSpinner} alt="Loading..." width={size} height={size} />
    </Main>
  );
};

export default LoadingSpinner;
