import { styled } from "@mui/system";
import theme from "../../theme";

const Root = styled("div")(({
  position: "relative",
}));

const Content = styled("div")(({
  maxWidth: "60rem",
  margin: "0 auto",
  padding: "2.5rem 2rem",
  textAlign: "center",
  color: "#fff",

  [theme.breakpoints.down("md")]: {
    padding: "1.5rem 0",
  },
}));

const Title = styled("h1")(({
  fontSize: "3.125rem",
  lineHeight: 1.2,
  fontWeight: 600,
}));

const Body = styled("div")(({
  paddingTop: "1.5rem",
  paddingBottom: "4.5rem",
  fontSize: 25,
  lineHeight: 1.4,
}));

const Button = styled("button")(({
  fontSize: "1rem",
  fontWeight: 400,
}));

export { Root, Content, Title, Body, Button };