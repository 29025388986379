import { FC } from "react";
import { Container, ImgWrapper, Text } from "./styles";

interface Props {
  id: number;
  img: any;
  text: string;
}

const ClientCard: FC<Props> = ({ id, img, text }) => {
  return (
    <Container>
      <ImgWrapper>
        <img src={img} alt={`Thumb for ${id}`} />
      </ImgWrapper>
      <Text>{text}</Text>
    </Container>
  );
};

export default ClientCard;
