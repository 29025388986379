import { styled } from "@mui/system";
import { pageStyles } from "../../theme";
import theme from "../../theme";

const Root = styled("div")(({
  ...pageStyles(theme),
}));

const main = {
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(10),
};

const mainHeading = {
  ...pageStyles(theme).mainHeading,
};

const MainText = styled("p")(({
  fontSize: 25,
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(6),
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
}));

const Text = styled("p")(({
  fontSize: 20,
  lineHeight: 1.2,
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
}));

const SectionBorder = styled("div")(({
  width: "100%",
  height: "2px",
  backgroundColor: theme.palette.primary.main,
}));

export {
  Root,
  main,
  mainHeading,
  MainText,
  Text,
  SectionBorder,
};