import { styled } from "@mui/system";
import theme from "../../theme";

const Container = styled("div")(({
  borderBottom: `1px solid ${theme.palette.primary.main}`,
}));

const Title = styled("div")(({
  padding: "1rem",
  background: theme.palette.primary.main,
  color: "#fff",
  textTransform: "uppercase",
  fontSize: "0.875rem",
  fontWeight: 600,
}));

export { Container, Title };