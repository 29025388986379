const root ={
  "& .responsive-table": {
    "& .slick-prev, & .slick-next": {
      width: "1rem",
      height: "1rem",
      fill: "#1d1d1d",
      top: "auto",
      bottom: "-1.9rem",
      zIndex: 1,
    },
    "& .slick-prev": {
      left: "calc(50% - 5rem)",
    },
    "& .slick-next": {
      right: "calc(50% - 5rem)",
    },
    "& .slick-dots li button:before": {
      color: "#25246D",
      opacity: 1,
      fontSize: 10,
    },
    "& .slick-dots li.slick-active button:before": {
      color: "#235CFF",
      opacity: 1,
    },
  },
};

export { root };