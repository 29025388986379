import { FC } from "react";
import { Container } from "@mui/material";
import ImgCoin from "../../assets/images/products/crypto-offering/coin.png";
import { ContainerComponent, Mask, Main, Title, Text } from "./styles";

const CryptoOfferingData: FC<{}> = () => {
  return (
    <ContainerComponent
      sx={{
        backgroundImage: { md: `url(${ImgCoin})` },
      }}
    >
      <Mask></Mask>
      <Container>
        <Main>
          <Title>Crypto Data</Title>
          <Text>
            BITA's Cryptocurrency Data will be available for the most widely and
            actively traded cryptocurrencies in the market and exchanges around
            the globe.
          </Text>
        </Main>
      </Container>
    </ContainerComponent>
  );
};

export default CryptoOfferingData;
