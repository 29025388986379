import { red } from "@mui/material/colors";
import { createTheme, Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";

export type StyleKey =
  | "root"
  | "headerContent"
  | "header"
  | "heading"
  | "subHeading"
  | "mainHeading"
  | "mainText"
  | "mainSubHeading"
  | "heroTitle"
  | "heroDescription"
  | "heroSubTitle"
  | "heroSubDescription";

export const pageStyles = (
  theme: Theme
): { [key in StyleKey]: SxProps } => ({
  root: {},
  headerContent: {
    position: "relative",
  },
  header: {
    position: "relative",
    height: "34rem",
    paddingTop: "16rem",
    color: "#fff",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundColor: "#1b1b1b",

    [theme.breakpoints.down("md")]: {
      height: "100vh",
      paddingTop: 0,
      display: "flex",
      alignItems: "center",
    },
  },
  heading: {
    fontSize: 63,
    lineHeight: 1.2,
    fontWeight: 700,

    [theme.breakpoints.down("md")]: {
      fontSize: 45,
    },
  },
  subHeading: {
    fontSize: 24,
    lineHeight: 1.5,
    paddingTop: "1rem",

    [theme.breakpoints.down("md")]: {
      fontSize: 17,
    },
  },
  mainHeading: {
    fontSize: 50,
    lineHeight: 1.2,
    fontWeight: 600,

    [theme.breakpoints.down("md")]: {
      fontSize: 40,
    },
  },
  mainSubHeading: {
    fontSize: 25,
    lineHeight: 1.2,
    fontWeight: 600,
    padding: "1.5rem 0",
  },
  mainText: {
    marginTop: "1.5rem",
    fontSize: 23,
    lineHeight: 1.2,
    paddingBottom: "3rem",
  },
  heroTitle: {
    fontSize: 65,
    lineHeight: 1.2,
    fontWeight: 700,

    [theme.breakpoints.down("md")]: {
      fontSize: 45,
      letterSpacing: 2,
    },
  },
  heroDescription: {
    paddingTop: "1.5rem",
    fontSize: "1.5rem",
    fontWeight: 300,

    [theme.breakpoints.down("md")]: {
      fontSize: 17,
      fontWeight: 600,
    },
  },
  heroSubTitle: {
    fontSize: 50,
    fontWeight: 600,
    textAlign: "center",

    [theme.breakpoints.down("md")]: {
      fontSize: 36,
      whiteSpace: "nowrap",
    },
  },
  heroSubDescription: {
    paddingTop: "1rem",
    fontSize: 25,
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 15,
      lineHeight: "25px",
    },
  },
});

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#25246D",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
      paper: "#f2f2f2",
    },
    text: {
      primary: "#000",
      secondary: "#4B5158",
    },
  },
  typography: {
    fontFamily: [
      '"Neue Haas Grotesk Display Pro"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.down("md")]: {
            paddingLeft: "2.5rem",
            paddingRight: "2.5rem",
          },
        }),
      },
    },
  },
});

export default theme;
