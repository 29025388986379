import { styled } from '@mui/system';

const Wrapper = styled('div')(({
  position: 'relative',
  paddingLeft: '2.5rem',
  fontSize: 18,
  lineHeight: '30px',
}));

const ImgWrapper = styled('div')(({
  position: 'absolute',
  top: '0.25rem',
  left: '0.5rem',
}));

const Title = styled('div')(({
  fontWeight: 700,
}));

export {Wrapper, ImgWrapper, Title}