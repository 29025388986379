import { styled } from "@mui/system";

const Container = styled("div")(({
  maxWidth: "46.5rem",
  margin: "0 auto",
}));

const rowComponent = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  "&.secondRow": {
    paddingTop: "2rem",
  },
};

const item = {
  paddingLeft: "1.5rem",
  paddingRight: "1.5rem",
  height: "2.5rem",
  "& img": {
    height: "100%",
  },
};

const imageComponent = {
  margin: "auto",
};

const MobileContainer = styled("div")(({
  padding: "2.5rem 1.5rem",
  borderTop: "1px solid #235CFF",
  borderBottom: "1px solid #235CFF",
}));

export { Container, rowComponent, item, imageComponent, MobileContainer };