import { styled } from "@mui/system";
import { pageStyles } from "../../../theme";
import theme from "../../../theme";

const root = {
  ...pageStyles(theme).root,
};

const mainHeading = {
  ...pageStyles(theme).mainHeading,
}

const Divider = styled("div")(({
  width: "15rem",
  borderTop: "1px solid #fff",
  margin: "2rem 0",
}));

const Text = styled("p")(({
  maxWidth: theme.spacing(90),
  fontSize: 20,
  lineHeight: 1.2,
}));

const Main = styled("div")(({
  padding: "6rem 0",
  backgroundColor: "#1B1B1B",
}));

const MainTopSection = styled("div")(({
  paddingBottom: "2rem",
}));

const mainText = {
  fontSize: 20,
  lineHeight: 1.2,
};

const sectionContainer = {
  padding: "1rem 0rem 1rem",
};

const HeroSection = styled("div")(({
  width: "100%",
  padding: "7.5rem 12rem",
  margin: "6rem 0",
  color: "#000",
  [theme.breakpoints.down("md")]: {
    color: "#fff",
    padding: 0,
    margin: "3rem 0",
  },
}));

const heroSectionContainer = {
  [theme.breakpoints.down("md")]: {
    padding: "3rem 0",
    borderTop: "1px solid #fff",
    borderBottom: "1px solid #fff",
  },
};

export {
  root,
  Divider,
  Text,
  Main,
  MainTopSection,
  mainText,
  sectionContainer,
  HeroSection,
  heroSectionContainer,
  mainHeading
};