import { FC, MouseEvent } from "react";
import { Download } from "@mui/icons-material";
import { News } from "../../types/news";
import { Container, Header, Body, Footer, DownloadComponent } from "./styles";

interface Props {
  data: News;
}

const CaseStudyCard: FC<Props> = ({ data }) => {
  const { link_media, download_file, categorization, preview_image, title } =
    data;

  const handleClick = () => {
    if (!link_media) return;
    window.open(link_media, "_blank");
  };

  const handleDownload = (e: MouseEvent) => {
    e.stopPropagation();
    if (!download_file) return;
    window.open(download_file, "_blank");
  };

  return (
    <Container
      onClick={handleClick}
      sx={{
        backgroundImage: `url(${preview_image})`,
        cursor: link_media ? "pointer" : "auto",
      }}
    >
      <Header>{categorization?.name ?? "Unknown Type"}</Header>
      <Body>{title}</Body>
      <Footer>
        {download_file && (
          <DownloadComponent onClick={handleDownload}>
            Download <Download />
          </DownloadComponent>
        )}
      </Footer>
    </Container>
  );
};

export default CaseStudyCard;
