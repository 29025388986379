import { ChangeEventHandler, FC, useEffect, useState } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
  FormControl,
  FormControlProps,
  Input,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import { white, mobileMargin } from "./styles";

interface Props extends FormControlProps {
  label: string;
  placeholder?: string;
  variant?: "standard" | "outlined" | "filled";
  onChange: ChangeEventHandler<HTMLInputElement>;
  colorScheme?: "black" | "white";
}

const SearchInput: FC<Props> = ({
  label,
  variant = "standard",
  placeholder,
  onChange,
  colorScheme = "black",
  ...restProps
}) => {
  const [coloShemeState, setColoShemeState] = useState({});

  useEffect(() => {
    colorScheme === "white"
      ? setColoShemeState({ ...white })
      : setColoShemeState({});
  }, []);

  return (
    <FormControl
      sx={{ ...coloShemeState, minWidth: 240, ...mobileMargin }}
      variant={variant}
      {...restProps}
    >
      <InputLabel>{label}</InputLabel>
      <Input
        startAdornment={
          <InputAdornment position="start">
            <SearchOutlinedIcon />
          </InputAdornment>
        }
        placeholder={placeholder}
        onChange={onChange}
      />
    </FormControl>
  );
};

export default SearchInput;
