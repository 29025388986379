import { styled } from "@mui/system";
import theme from "../../theme";
import { Background } from "../Material/styles";

const Wrapper = styled("div")(({
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  paddingBottom: 0,
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    paddingBottom: "1.5rem",
  },
}));

const buttonComponent = {
  color: "#fff",
  minWidth: "6rem",
  marginLeft: "1px",
  marginRight: "1px",
  marginBottom: 0,
  paddingTop: "0.75rem",
  paddingBottom: "0.75rem",
  borderRadius: 0,
  lineHeight: 1,
  fontWeight: 600,
  boxShadow: "none",
  [theme.breakpoints.down("md")]: {
    marginBottom: "1rem",
  },
  "&:hover": {
    backgroundColor: "#181B4B"
  },
  "&:first-child": {
    borderTopLeftRadius: "0.5rem",
    borderBottomLeftRadius: "0.5rem",
  },
  "&:last-child": {
    borderTopRightRadius: "0.5rem",
    borderBottomRightRadius: "0.5rem",
  },
};

export { Wrapper, buttonComponent };