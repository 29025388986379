import { styled } from "@mui/system";

const Main = styled("div")(({
  padding: "0.75rem 2rem !important",
  fontSize: "18px",
}));

const Label = styled("div")(({
  display: "flex",
  alignItems: "center",
  wordBreak: "keep-all",
  textTransform: "none",
  lineHeight: "22px",
}));

export { Main, Label };