import { styled } from "@mui/system";

const Root = styled("div")(({
  position: "relative",
  width: "100%",
  height: "100%",
  backgroundColor: "#25246D",
  padding: "5rem 3rem 3rem",
  "& .item": {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "transparent",
    padding: "0.75rem 0.5rem",
    marginTop: "0.5rem",
    color: "#fff",
    borderBottom: "1px solid #fff",
  },
  "& .label": {
    fontSize: 25,
    fontWeight: 600,
    lineHeight: 1.2,
  },
  "& .activeItem": {
    backgroundColor: "#235CFF",
  },
  "& .subItem": {
    marginTop: "0.5rem",
    padding: "0.75rem 0.5rem 0",
    color: "#fff",
  },
  "& .activeSubItem": {
    paddingBottom: "0.75rem",
    backgroundColor: "rgba(193, 207, 249, 0.5)",
  },
  "& .subLabel": {
    fontSize: 25,
    lineHeight: 1.2,
  },
}));

const Close = styled("div")(({
  position: "absolute",
  right: "2rem",
  top: "2rem",
  width: "2rem",
  height: "2rem",
  overflow: "hidden",
  borderRadius: "0.5rem",
  backgroundColor: "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export { Root, Close };