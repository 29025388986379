import theme from "../../theme";

const white ={
  "& .MuiInputBase-root": {
    color: "#fff",
    "& .MuiInputAdornment-root svg": {
      fill: "#fff",
    },
    "&:after": {
      borderColor: "#fff",
    },
    "&:before": {
      borderColor: "rgba(255, 255, 255, 0.42)",
    },
    "&:hover:before": {
      borderBottom: "2px solid #fff !important",
    },
  },
};

const mobileMargin= {
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}

export { white, mobileMargin };