import { Box } from "@mui/material";
import { Container, Main, City, Country, Address } from "./styles";

interface Props {
  city: string;
  country: string;
  image: any;
  address: string;
}

const HeadquarterCard: React.FC<Props> = ({
  country,
  city,
  image,
  address,
}) => {
  return (
    <Container
      sx={{
        backgroundImage: `url(${image})`,
      }}
    >
      <Main>
        <City>{city}</City>
        <Country>{country}</Country>
      </Main>
      <Address>{address}</Address>
    </Container>
  );
};

export default HeadquarterCard;
