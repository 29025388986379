import { Box } from "@mui/material";
import { DownloadLink } from "../../types/ui";
import ExternalLink from "../ExternalLink";
import IndexLabDownloadLink from "../IndexLabDownloadLink";
import { Root, Label, Description, Footer } from "./styles";

interface Props {
  title: string;
  description?: string;
  downloadLinks: DownloadLink[];
  size?: "md" | "lg";
  bgImg: string;
}

const IndexLabCard: React.FC<Props> = ({
  title,
  description,
  downloadLinks,
  bgImg,
  size = "md",
}) => {
  return (
    <Box
      className={`${Root} ${
        size === "lg" ? "classes.lg TO DEFINE" : "classes.md  TO DEFINE"
      }`}
      sx={{
        backgroundImage: `url(${encodeURI(bgImg)})`,
      }}
    >
      <Label>{title}</Label>
      <Description>{description}</Description>
      <Footer>
        <IndexLabDownloadLink downloadLinks={downloadLinks} />
        <ExternalLink label="Contact Sales" link="/contact-us" noMargin />
      </Footer>
    </Box>
  );
};

export default IndexLabCard;
