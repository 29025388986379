import { styled } from "@mui/system";
import theme from "../../theme";

const section = {
  padding: "2.5rem 0",
  "&.reverse": {
    flexDirection: "row-reverse",
  },
};

const sectionContent = {
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  "& .home-section": {
    height: "100%",
    display: "flex",
    alignItems: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
    padding: "2rem",
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: "5rem",
      borderBottom: "1px solid #235CFF",
    },
    "&.standard": {
      paddingRight: "3rem",
      [theme.breakpoints.down("md")]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
};

const SectionContentInner = styled("div")(({}));

const SectionSubTitle = styled("h2")(({
  color: "#96AAF0",
  fontSize: 15,
  fontWeight: 700,
}));

const SectionTitle = styled("h1")(({
  fontSize: 30,
  fontWeight: 600,
  paddingBottom: "1.5rem",
}));

const SectionText = styled("p")(({
  fontSize: "1rem",
}));

const sectionImage = {
    margin: "0 auto",
};

const Divider = styled("div")(({
  width: "4rem",
  height: "2px",
  backgroundColor: "#698BEC",
  margin: "0.5rem 0",
}));

export {
  section,
  sectionContent,
  SectionContentInner,
  SectionSubTitle,
  SectionTitle,
  SectionText,
  sectionImage,
  Divider,
};