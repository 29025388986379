import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Container, Grid } from "@mui/material";
import moment from "moment";
import { Navigate, useParams } from "react-router-dom";
import useWebSocket, { ReadyState } from "react-use-websocket";
import BitaBreadcrumbs from "../../components/Breadcrumbs";
import Chart from "../../components/Chart";
import DateTimePicker from "../../components/DateTimePicker";
import IndexNestedTables from "../../components/IndexNestedTables";
import Material from "../../components/Material";
import PageHero from "../../components/PageHero";
import RadioButtons from "../../components/RadioButtons";
import ResponsiveTable from "../../components/ResponsiveTable";
import Table from "../../components/Table";
import { useAppDispatch, useAppSelector } from "../../hooks/useStore";
import { useIsMobileView } from "../../hooks/useWindowSize";
import { getAnalyzerToken } from "../../redux/slices/auth";
import {
  fetchIndexAssetClassOptions,
  fetchIndexDetailEOD,
  fetchIndexesWithWeight,
  fetchIndexHistory,
  fetchIndexList,
  fetchIndexItemData,
  fetchIndexTypes,
  fetchIndexValues,
  fetchIndexValuesFirstSecond,
  selectAssetClassOptions,
  selectIndexDetailEod,
  selectIndexes,
  selectIndexHistory,
  selectIndexList,
  selectIndexLoading,
  selectIndexTypeOptions,
  selectIndexValues,
  selectIndexItemData,
} from "../../redux/slices/indexes";
import { socketUrl, getV2IndexFile } from "../../services";
import theme from "../../theme";
import {
  IndexFileType,
  IndexEodItem,
  IndexFile,
  IndexHistoryItem,
} from "../../types/indexes";
import { BreadcrumbsItem, IRadioItem } from "../../types/ui";
import { formatAsTwoDecimals } from "../../utils/numeric";
import { DATE_FORMAT } from "../../utils/string";
import {
  ACTIVE_STATUS,
  INACTIVE_STATUS,
  CUSTOM_BUTTON_TYPE,
  CONSTITUENTS_FILE_TYPE,
} from "./config";
import {
  Root,
  main,
  mainText,
  mainHeading,
  SectionHeading,
  TableContainer,
  SelectorsContainer,
  DatePickersContainer,
  withBorderBottom,
} from "./styles";
import {
  indexTableCols,
  ORDERED_INDEX_FILE_TYPES,
  RadioButtonItems,
  top10ConstituentsCols,
  PERIOD_IN_YEARS,
} from "./table";

const formatIndexes = (indexes: Array<string>) =>
  indexes.map((index) => index.toUpperCase());

const IndexDetailsPage = () => {
  const params = useParams();
  const indexId = params.id?.toUpperCase();
  const dispatch = useAppDispatch();

  const [indexFiles, setIndexFiles] = useState<IndexFile[]>([]);
  const indexes = useAppSelector(selectIndexes);
  const eod = useAppSelector(selectIndexDetailEod);
  const indexHistory = useAppSelector(selectIndexHistory);
  const assetClassOptions = useAppSelector(selectAssetClassOptions);
  const indexTypeOptions = useAppSelector(selectIndexTypeOptions);
  const indexValues = useAppSelector(selectIndexValues);
  const indexList = useAppSelector(selectIndexList);
  const indexItemConfig = useAppSelector(selectIndexItemData);
  const isLoading = useAppSelector(selectIndexLoading);

  const indexValuesFirstSecondAll = useAppSelector(
    (state) => state.indexes.indexValuesFirstSecond
  );
  const [period, setPeriod] = useState<string | number>(PERIOD_IN_YEARS);
  const [from, setFrom] = useState<string | null>(null);
  const [to, setTo] = useState<string | null>(null);

  const [filteredEod, setFilteredEod] = useState<IndexEodItem[]>([]);
  const [filteredHistory, setFilteredHistory] = useState<IndexHistoryItem[]>(
    []
  );

  const inMobile = useIsMobileView();

  const { lastMessage, readyState, sendJsonMessage } = useWebSocket(socketUrl);

  // const connectionStatus = {
  //   [ReadyState.CONNECTING]: "Connecting",
  //   [ReadyState.OPEN]: "Open",
  //   [ReadyState.CLOSING]: "Closing",
  //   [ReadyState.CLOSED]: "Closed",
  //   [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  // }[readyState];

  const constituentsListDisabledIndex = ["BCAUEE", "BIF1US"];

  const indexListItem = useMemo(
    () => indexList?.find((item) => item.ticker === indexId),
    [indexList, indexId]
  );

  const websocketId = indexListItem?.internal_ticker || indexId;

  const index = useMemo(() => {
    if (!indexes?.length) return undefined;
    return indexes.find((i) => i.index_id === indexId);
  }, [indexes, indexId]);

  const indexValue = useMemo(() => {
    if (!indexValues?.length) return undefined;
    return indexValues.find((i) => i.index_id === indexId);
  }, [indexValues, indexId]);

  const indexValueFirstSecond = useMemo(() => {
    if (!indexValuesFirstSecondAll?.length) return undefined;
    return indexValuesFirstSecondAll.find((v) => v.symbol === indexId);
  }, [indexValuesFirstSecondAll, indexId]);

  const indexHistoryItem = useMemo(() => {
    return indexHistory?.find(({ index_id }) => index_id === indexId);
  }, [indexHistory, indexId]);

  const lastIndexValue = useMemo(() => {
    try {
      const data = JSON.parse(lastMessage?.data ?? "{}");

      // Pick only correct data
      if (
        data.channel === "live" &&
        data.event === "update" &&
        data.id === websocketId &&
        (data?.data?.index_value || data?.data?.price)
      ) {
        return data?.data?.index_value || data.data.price;
      }
      return indexValue?.value ?? 0;
    } catch (error) {
      return 0;
    }
  }, [indexValue?.value, lastMessage?.data, websocketId]);

  const filesToShow = useMemo(() => {
    let clonedIndexFiles: IndexFile[] = [...indexFiles];
    const addFile = (fileData: IndexFile) => {
      // Add file to the clonedIndexFiles
      clonedIndexFiles.push(fileData);
    };
    const removeFile = (typeToRemove: IndexFileType) => {
      // Filter to remove files given its type to remove
      clonedIndexFiles = clonedIndexFiles.filter(
        (file) => file.type !== typeToRemove
      );
    };

    const hasConstituentsFile = clonedIndexFiles.some(
      (file) => file.type === CONSTITUENTS_FILE_TYPE
    ); // Checks if at least one file has the CONSTITUENTS_FILE_TYPE
    const shouldAddCustomButton =
      indexItemConfig.custom_button_status === ACTIVE_STATUS; // Checks if need to add a custom button if config is set to active

    if (
      !hasConstituentsFile &&
      index &&
      indexItemConfig.constituents_file_status === ACTIVE_STATUS
    ) {
      // There is no constituents file but need to add file due config is set to active
      const constituentsFile: IndexFile = {
        createdate: "",
        file: "",
        id_m_index: -1,
        id_m_index_file: -1,
        key: `Constituent_List${index.index_id}.xlsx`,
        status: indexItemConfig.constituents_file_status,
        type: CONSTITUENTS_FILE_TYPE,
      };
      addFile(constituentsFile);
    } else if (
      hasConstituentsFile &&
      indexItemConfig.constituents_file_status === INACTIVE_STATUS
    ) {
      // There is at least a constituents file but need to remove file due config is set to inactive
      removeFile(CONSTITUENTS_FILE_TYPE);
    }

    if (shouldAddCustomButton) {
      const customButtonFile: IndexFile = {
        createdate: "",
        file: indexItemConfig.custom_button_url,
        id_m_index: -2,
        id_m_index_file: -2,
        key: indexItemConfig.custom_button_name,
        status: indexItemConfig.custom_button_status,
        type: CUSTOM_BUTTON_TYPE,
      };
      addFile(customButtonFile);
    }

    return clonedIndexFiles.sort(
      (a, b) =>
        ORDERED_INDEX_FILE_TYPES.findIndex((type) => type === a.type) -
        ORDERED_INDEX_FILE_TYPES.findIndex((type) => type === b.type)
    );
  }, [
    index,
    indexFiles,
    indexItemConfig.constituents_file_status,
    indexItemConfig.custom_button_name,
    indexItemConfig.custom_button_status,
    indexItemConfig.custom_button_url,
  ]);

  const indexData = useMemo(() => {
    const index_id: string = index?.index_id || "";
    const last_eod_value: number =
      eod[index_id]?.[eod[index_id]?.length - 1]?.value || 0;
    const penultimate_eod_value: number =
      eod[index_id]?.[eod[index_id]?.length - 2]?.value || 0;
    const index_value = indexValueFirstSecond?.index_value;

    const calculateValueDiff = (): number => {
      let valueDiff = 0;
      if (lastIndexValue && index_value) {
        valueDiff = formatAsTwoDecimals(lastIndexValue - index_value);
      }
      if (valueDiff === 0) {
        valueDiff = last_eod_value - penultimate_eod_value;
      }
      return valueDiff;
    };

    const calculateLastDayReturn = (): number => {
      let lastDayReturn = 0;
      if (lastIndexValue && index_value) {
        lastDayReturn = formatAsTwoDecimals(
          ((lastIndexValue - index_value) / lastIndexValue) * 100
        );
      }
      if (lastDayReturn === 0 && index_value) {
        lastDayReturn =
          ((last_eod_value - penultimate_eod_value) / index_value) * 100;
      }
      return lastDayReturn;
    };

    const valueDiff = calculateValueDiff();
    const lastDayReturn = calculateLastDayReturn();

    return {
      ...index,
      value: (lastIndexValue || 0).toFixed(2),
      last_day_return: `${valueDiff.toFixed(2)} (${lastDayReturn.toFixed(2)}%)`,
      valueDiff,
    };
  }, [index, indexValueFirstSecond?.index_value, lastIndexValue, eod]);

  const indexColumns = useMemo(
    () =>
      // Hide any empty columns out
      indexTableCols.filter(
        (col) =>
          !!(indexData as any)[col.key] || (indexData as any)[col.key] === 0
      ),
    [indexData]
  );

  const radioItems: IRadioItem[] = useMemo(() => {
    if (!indexId || !eod?.[indexId]) return RadioButtonItems;

    return RadioButtonItems.map((item, idx) => {
      if (idx === 0 || idx === RadioButtonItems.length - 1) {
        return item;
      }

      // Mark periods that don't have relevant data as "disabled"
      return {
        ...item,
        disabled:
          moment()
            .add(item.value, "years")
            .diff(moment(eod[indexId ?? ""][0]?.timestamp)) < 0,
      };
    });
  }, [eod, indexId]);

  const disabledItemIds = useMemo(
    () => radioItems.filter((item) => !!item.disabled).map((item) => item.key),
    [radioItems]
  );

  const chartData = useMemo(() => {
    // Try to get chart data from EOD first
    if (filteredEod.length) {
      return filteredEod.map((item) => {
        const { timestamp, value } = item;
        return [moment(timestamp).valueOf(), value]; // Need to convert date string to timestamp: required by Highcharts
      });
    }

    // Some indexes have history instead of EOD
    if (filteredHistory.length) {
      return filteredHistory.map((item) => {
        const { timestamp_real, close } = item;
        return [moment(timestamp_real).valueOf(), close]; // Need to convert date string to timestamp: required by Highcharts
      });
    }

    return [];
  }, [filteredEod, filteredHistory]);

  const assetClass = useMemo(
    () =>
      assetClassOptions.find((aco) => aco.id === indexListItem?.asset_class),
    [assetClassOptions, indexListItem?.asset_class]
  );

  const indexType = useMemo(
    () => indexTypeOptions.find((ito) => ito.id === indexListItem?.index_type),
    [indexListItem?.index_type, indexTypeOptions]
  );

  const breadcrumbsItems: BreadcrumbsItem[] = useMemo(() => {
    if (!assetClass || !indexType || !index?.index_id) return [];
    return [
      {
        key: 0,
        label: "BITA Indexes",
        to: "/index",
      },
      {
        key: 0,
        label: assetClass.name,
        to: `/index?asset_class=${assetClass.id}`,
      },
      {
        key: 0,
        label: indexType.name,
        to: `/index?asset_class=${assetClass.id}&index_type=${indexType.id}`,
      },
      {
        key: 0,
        label: index.index_id,
        to: `/index/${index.index_id}`,
      },
    ];
  }, [assetClass, index?.index_id, indexType]);

  const fetchIndexFiles = useCallback(async () => {
    try {
      if (indexListItem?.index_id) {
        const { data } = await getV2IndexFile(indexListItem.index_id);
        if (data) {
          setIndexFiles(data);
        }
      }
    } catch (error) {}
  }, [indexListItem?.index_id]);

  useEffect(() => {
    if (readyState === ReadyState.OPEN && websocketId) {
      // Subscribe to index channel
      sendJsonMessage({
        event: "subscribe",
        id: websocketId,
        channel: "live",
      });
    }
  }, [readyState, sendJsonMessage, websocketId]);

  useEffect(() => {
    if (!index) {
      dispatch(fetchIndexesWithWeight());
    }
  }, [dispatch, index]);

  useEffect(() => {
    dispatch(fetchIndexValuesFirstSecond());
  }, [dispatch]);

  useEffect(() => {
    if (!indexId) return;
    const ids = formatIndexes([indexId]);
    const startDate = moment()
      .add(PERIOD_IN_YEARS, "years")
      .format(DATE_FORMAT);
    const endDate = moment().format(DATE_FORMAT); // Today

    dispatch(
      fetchIndexDetailEOD({
        indexes: ids,
        startDate,
        endDate,
      })
    );
    dispatch(
      fetchIndexValues({
        indexes: ids,
      })
    );
  }, [dispatch, indexId]);

  useEffect(() => {
    // Only if there are no EOD items, try to fetch index history items
    if (indexId && eod?.[indexId] && eod[indexId].length === 0) {
      const startDate = moment()
        .add(PERIOD_IN_YEARS, "years")
        .format(DATE_FORMAT);
      const endDate = moment().format(DATE_FORMAT); // Today

      dispatch(
        fetchIndexHistory({
          indexId: indexId,
          startDate,
          endDate,
          frequency: "1d",
        })
      );
    }
  }, [dispatch, eod, indexId]);

  // Filter items by years selector
  useEffect(() => {
    if (!indexId || !eod || !eod[indexId]) {
      setFilteredEod([]);
      return;
    }

    const minDate =
      period === "YTD"
        ? moment().startOf("year")
        : moment().add(period, "years");

    setFilteredEod(
      eod[indexId].filter((item) => {
        return moment(item.timestamp).diff(minDate, "days") >= 0;
      })
    );
  }, [eod, indexId, period]);

  useEffect(() => {
    if (!indexId || !indexHistoryItem || !indexHistoryItem.data?.length) {
      setFilteredHistory([]);
      return;
    }

    const minDate =
      period === "YTD"
        ? moment().startOf("year")
        : moment().add(period, "years");

    setFilteredHistory(
      indexHistoryItem.data.filter(
        (item) => moment(item.timestamp_real).diff(minDate, "days") >= 0
      )
    );
  }, [indexHistoryItem, indexId, period]);

  // Filter items by date range selected
  useEffect(() => {
    if (!from || !to) return;
    if (!indexId || !eod || !eod[indexId]) {
      setFilteredEod([]);
      return;
    }

    setFilteredEod(
      eod[indexId].filter((item) => {
        return (
          moment(item.timestamp).diff(moment(from), "days") >= 0 &&
          moment(item.timestamp).diff(moment(to), "days") <= 0
        );
      })
    );
  }, [eod, from, indexId, to]);

  useEffect(() => {
    if (!from || !to) return;

    if (!indexId || !indexHistoryItem || !indexHistoryItem.data?.length) {
      setFilteredHistory([]);
      return;
    }

    setFilteredHistory(
      indexHistoryItem.data.filter(
        (item) =>
          moment(item.timestamp_real).diff(moment(from), "days") >= 0 &&
          moment(item.timestamp_real).diff(moment(to), "days") <= 0
      )
    );
  }, [from, indexHistoryItem, indexId, to]);

  useEffect(() => {
    dispatch(fetchIndexList());
    dispatch(fetchIndexAssetClassOptions());
    dispatch(fetchIndexTypes());
    index?.index_id && dispatch(fetchIndexItemData(index?.index_id));
  }, [dispatch, index?.index_id]);

  useEffect(() => {
    fetchIndexFiles();
  }, [fetchIndexFiles]);

  useEffect(() => {
    // Get analyzer api token, which is required in future api requests
    dispatch(
      getAnalyzerToken({
        username: process.env.REACT_APP_ANALYZER_API_USER ?? "",
        password: process.env.REACT_APP_ANALYZER_API_PWD ?? "",
      })
    );
  }, [dispatch]);

  if (!indexes?.length) return null;
  if (index?.web_status !== "A" && index?.web_status !== "H")
    return <Navigate to="/" />;

  return (
    <Root>
      <PageHero
        colorScheme="dark_blue"
        title={index.name}
        subTitle={index.index_id}
        responsiveHeading
      />

      <Container sx={{ ...main }}>
        <Box pb={4}>
          <BitaBreadcrumbs items={breadcrumbsItems} />
        </Box>
        <Box sx={{ ...mainHeading, color: theme.palette.primary.main }}>
          Index Description
        </Box>
        <Box sx={{ ...mainText, ...withBorderBottom, color: "text.secondary" }}>
          {index.description}
        </Box>
        <TableContainer>
          {indexId && eod?.[indexId] ? (
            <Box>
              <Box sx={{ ...mainHeading, color: theme.palette.primary.main }}>
                Performance
              </Box>
              <SelectorsContainer>
                <RadioButtons
                  items={radioItems}
                  currentValue={period}
                  onChange={setPeriod}
                />
                <DatePickersContainer>
                  <DateTimePicker
                    showTime={false}
                    label="From"
                    currentValue={from}
                    onChange={(val) => setFrom(val)}
                  />
                  <DateTimePicker
                    showTime={false}
                    label="To"
                    currentValue={to}
                    onChange={(val) => setTo(val)}
                  />
                </DatePickersContainer>
              </SelectorsContainer>
              <Box>
                <Chart
                  loading={isLoading && !chartData?.length}
                  dataSeries={[
                    {
                      type: "area",
                      name: indexId,
                      data: chartData,
                      color: theme.palette.primary.dark,
                      lineColor: theme.palette.primary.main,
                    },
                  ]}
                />
              </Box>
            </Box>
          ) : null}

          <Box sx={{ ...withBorderBottom }}>
            <ResponsiveTable
              inMobile={inMobile}
              data={indexData}
              columns={indexColumns}
            />

            <IndexNestedTables
              id={indexId ?? ""}
              disabledItemIds={disabledItemIds}
            />
          </Box>

          <Box sx={{ ...withBorderBottom }} mt={4} pb={4}>
            <Grid container spacing={4}>
              {indexItemConfig.top_10_constituents_status === "A" &&
              index.components?.length &&
              !constituentsListDisabledIndex.includes(index.index_id) ? (
                <Grid item xs={12} sm={6}>
                  <SectionHeading sx={{ mb: "1rem" }}>
                    Top 10 Constituents
                  </SectionHeading>
                  <Table
                    columns={top10ConstituentsCols}
                    data={index.components}
                    dense
                    hasPagination={false}
                  />
                </Grid>
              ) : null}
              <Grid item xs={12} sm={6}>
                <SectionHeading>Download Materials</SectionHeading>
                <Grid container spacing={2} mt={0}>
                  {filesToShow.map((file) => (
                    <Grid key={file.id_m_index_file} item xs={12} sm={6}>
                      <Material
                        type={
                          file.type === CUSTOM_BUTTON_TYPE
                            ? indexItemConfig.custom_button_name
                            : file.type
                        }
                        isCustomButton={file.type === CUSTOM_BUTTON_TYPE}
                        url={file.file}
                        fileIndex={file.id_m_index_file}
                        indexId={index.index_id}
                        fileName={file.key}
                        background="linear-gradient(360deg, #005FEE 0%, #0031D9 100%)"
                        fileShouldBeDownloadable={
                          indexItemConfig.file_should_be_downloadable
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </TableContainer>
      </Container>
    </Root>
  );
};

export default IndexDetailsPage;
