import { styled } from "@mui/system";
import theme from "../../theme";

const tableContainer ={
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  [theme.breakpoints.down("md")]: {
    paddingBottom: theme.spacing(6),
  },
};

const SelectorsRow = styled("div")(({
  display: "flex",
  flexDirection: "row-reverse",
  justifyContent: "space-between",
  flexWrap: "wrap",
  paddingBottom: theme.spacing(3),
  [theme.breakpoints.down("md")]: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(6),
  },
}));

const SelectorsContainer = styled("div")(({
  display: "flex",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const SelectContainer = styled("div")(({
  paddingRight: theme.spacing(3),
  [theme.breakpoints.down("md")]: {
    width: "100%",
    paddingRight: 0,
  },
}));

const SearchContainer = styled("div")(({
  [theme.breakpoints.down("md")]: {
    width: "100%",
    paddingBottom: "2rem",
  },
}));

const withBorderBottom = {
  borderBottomWidth: 2,
  borderStyle: "solid",
  borderColor: theme.palette.primary.main,
};

export {
  tableContainer,
  SelectorsRow,
  SelectorsContainer,
  SelectContainer,
  SearchContainer,
  withBorderBottom,
};