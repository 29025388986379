import { styled } from "@mui/system";

const Root = styled("div")(({
  margin: "0 1rem",
  padding: "1.25rem",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  color: "#fff",
}));

const Type = styled("span")(({
  display: "inline-block",
  borderBottom: "1px solid #fff",
  fontSize: 11,
  fontWeight: 500,
}));

const Title = styled("div")(({
  minHeight: "10rem",
  maxWidth: "80%",
  display: "flex",
  alignItems: "flex-end",
  fontSize: 15,
  fontWeight: 600,
}));

const Date = styled("div")(({
  paddingTop: "2rem",
  fontSize: 13,
}));

export { Root, Type, Title, Date };