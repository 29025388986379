import { styled } from "@mui/system";
import theme from "../../theme";

const Container = styled("div")(({
  padding: "0 1.5rem 2rem",
  borderTopWidth: 1,
  borderTopStyle: "solid",
  [theme.breakpoints.down("sm")]: {
    padding: "0 1.5rem",
  },
}));

const Title = styled("div")(({
  display: "flex",
  alignItems: "center",
  paddingTop: "1.5rem",
  paddingBottom: "0.5rem",
  backgroundClip: "text",
  WebkitTextFillColor: "transparent",
}));

const Icon = styled("div")(({
  marginRight: "1rem",
  display: "block",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const Label = styled("div")(({
  fontSize: 25,
  lineHeight: 1.2,
  fontWeight: 600,
}));

const Content = styled("div")(({
  fontSize: 18,
  lineHeight: 1.2,
  paddingRight: 6,
}));

export { Container, Title, Icon, Label, Content };