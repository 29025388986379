import { useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { Box, CircularProgress } from "@mui/material";
import ImgBg from "../../assets/images/indexes/bg-material.svg";
import { getConstituentsFile, getPdfFile } from "../../services";
import { downloadFileInBrowser } from "../../utils";
import { Container, Background, Title, Content, Type } from "./styles";

interface Props {
  type: string;
  url: string;
  background: string;
  fileIndex: number;
  fileName: string;
  indexId: string;
  fileShouldBeDownloadable: string;
  isCustomButton: boolean;
}

const BCAUEE_CUSTOM_URL =
  "https://cedarportfolio.com/main/wp-content/uploads/2021/12/The-Cedar-Austrian-Economics-ESG-Index-Investment-Strategy-Latest.pdf"; // TODO: It is hardcoded for now due to high priority, in a next iteration it will be removed and certain features will be made @andrea_da_silva @paul_herrera [IT-14218]
const BCAUEE_INDEX = "BCAUEE";

const Material: React.FC<Props> = ({
  type,
  url,
  background,
  fileIndex,
  fileName,
  indexId,
  fileShouldBeDownloadable,
  isCustomButton,
}) => {
  const [downloading, setDownloading] = useState<boolean>(false);

  const downloadPDF = async (url: string, filename: string) => {
    try {
      setDownloading(true);
      const file = await getPdfFile(url);
      downloadFileInBrowser(file, filename, false, "pdf");
      setDownloading(false);
    } catch (err) {
      setDownloading(false);
      console.info("error fetching the pdf document to download", err);
    }
  };

  const downloadOrLinkSwitcher = async (url: string, fileName: string) => {
    isCustomButton && fileShouldBeDownloadable === "A"
      ? await downloadPDF(url, fileName)
      : window.open(url, "_blank");
  };

  const handleOpenPdf = async () => {
    if (indexId === BCAUEE_INDEX) {
      // TODO: It is hardcoded for now due to high priority, in a next iteration it will be removed and certain features will be made @andrea_da_silva @paul_herrera [IT-14218]
      await downloadOrLinkSwitcher(BCAUEE_CUSTOM_URL, fileName);
    } else if (url) {
      await downloadOrLinkSwitcher(url, fileName);
    }
  };

  const handleDownloadConstituentsFile = async () => {
    try {
      if (fileIndex && !downloading) {
        setDownloading(true);
        const file = await getConstituentsFile(indexId);
        downloadFileInBrowser(file, fileName, false, "xlsx");        
        setDownloading(false);
      }
    } catch (error) {
      setDownloading(false);
    }
  };

  const handleClick = () => {
    if (type === "constituents_list") {
      handleDownloadConstituentsFile();
    } else {
      handleOpenPdf();
    }
  };

  return (
    <Container
      sx={{
        background,
      }}
      onClick={handleClick}
    >
      <Background sx={{ backgroundImage: `url(${ImgBg})` }}></Background>
      <Title>INDEX RESOURCES</Title>
      <Content>
        <Type>{type?.split("_").join(" ")}</Type>
        <Box>
          {downloading ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <DownloadIcon />
          )}
        </Box>
      </Content>
    </Container>
  );
};

export default Material;
