import { FC } from "react";
import { Button } from "@mui/material";
import theme from "../../theme";
import { RadioButtonProps } from "../../types/ui";
import { Wrapper, buttonComponent } from "./styles";

interface Props {
  items: RadioButtonProps[];
  currentValue: string | number;
  onChange: (value: string | number) => void;
}

const RadioButtons: FC<Props> = ({ items, currentValue, onChange }) => {
  return (
    <Wrapper>
      {items.map(({ label, value, disabled }) => (
        <Button
          key={value}
          disabled={!!disabled}
          onClick={() => onChange(value)}
          sx={{
            backgroundColor:
              currentValue === value ? theme.palette.primary.main : "#C1CFF9",
            ...buttonComponent,
          }}
        >
          {label}
        </Button>
      ))}
    </Wrapper>
  );
};

export default RadioButtons;
