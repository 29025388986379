import { FC } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../Button";
import { buttonComponent } from "./styles";

interface Props {
  color: string;
}

const ContactUsButton: FC<Props> = ({ color }) => {
  const navigate = useNavigate();

  const onClick = () => navigate("/contact-us");

  return (
    <Button
      onClick={onClick}
      label="Contact Us"
      sx={{
        ...buttonComponent,
        color,
      }}
    />
  );
};

export default ContactUsButton;
