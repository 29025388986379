import { styled } from "@mui/system";

const Container = styled("div")(({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  minHeight: "7.5rem",
  padding: "1.25rem",
  color: "#fff",
  cursor: "pointer",
}));

const Background = styled("div")(({
  position: "absolute",
  top: 0,
  bottom: "-4rem",
  left: "calc(100% - 6rem)",
  right: "-6rem",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
}));

const Title = styled("div")(({
  fontSize: "0.75rem",
  borderBottom: "1px solid #fff",
  paddingBottom: 6,
}));

const Content = styled("div")(({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  zIndex: 1,
}));

const Type = styled("div")(({
  fontSize: "1rem",
  lineHeight: 1.2,
  fontWeight: 600,
  textTransform: "capitalize",
}));

export { Container, Background, Title, Content, Type };