import { Box, Container } from "@mui/material";
import ImgSection1 from "../../assets/images/products/index-services/pattern1.svg";
import ImgSection2 from "../../assets/images/products/index-services/pattern2.svg";
import ImgSection3 from "../../assets/images/products/index-services/pattern3.svg";
import ImgSection4 from "../../assets/images/products/index-services/pattern4.svg";
import IndexServiceSection from "../../components/IndexServiceSection";
import PageHero from "../../components/PageHero";
import { Root, Main, mainHeading, mainText } from "./styles";

const IndexServices = () => {
  return (
    <Root>
      <PageHero
        colorScheme="dark_blue"
        mainContentColorScheme="dark"
        title="Index Services"
        subTitle="Full range of technology-driven indexing services"
      />
      <Main>
        <Container>
          <Box>
            <Box sx={{ ...mainHeading }}>
              State-of-the-Art Indexing Services at your disposal
            </Box>
            <Box sx={{ ...mainText }} mt={3}>
              Thanks to our innovative platform, clients can outsource critical
              parts of their operations while retaining control of their
              intellectual property.
            </Box>
          </Box>

          <Box>
            <IndexServiceSection
              title="Custom Index Development and Calculation"
              subTitle="Unlimited Possibilities"
              text={[
                "From the research to the development and calculation of indexes, our highly scalable indexing infrastructure enables all levels of customization while ensuring complete accuracy and reliability.",
                "Whether our clients come to us with defined concepts or a high-level idea, our extremely flexible setup allows us to cover almost any type of indexing need.",
              ]}
              image={ImgSection1}
              direction="reverse"
            />

            <IndexServiceSection
              title="Global Index Administration"
              subTitle="Infrastructure, Operations & Regulation"
              text={[
                "Designed for index providers and users of benchmarks looking for regulatory compliance while saving time and resources, our index administration service offers complete governance and oversight along the indexing value chain, from collection of data, all the way to index design, calculation and maintenance.",
              ]}
              image={ImgSection2}
            />

            <IndexServiceSection
              title="Basket Index Calculation"
              subTitle="Indexing as a Service"
              text={[
                "A fully-managed solution designed to help clients save time, capital, and internal resources. Running on a modern and scalable infrastructure, the Basket Calculation Service can be adapted to a variety of implementation and ongoing management requirements.",
              ]}
              image={ImgSection3}
              direction="reverse"
            />

            <IndexServiceSection
              title="iNAV/iOPV Calculation"
              subTitle="Indexing as a Service"
              text={[
                "Seamless calculation of iNAV/iOPV (intraday net asset value) for price determination and investment decision-making. Coverage includes ETFs, investment funds, hedge funds, insurance portfolios and almost any custom portfolio.",
              ]}
              image={ImgSection4}
            />
          </Box>
        </Container>
      </Main>
    </Root>
  );
};

export default IndexServices;
